import { createAsyncThunk } from '@reduxjs/toolkit';
import { t } from 'i18next';
import iziToast from 'izitoast';

import type { IziToastSettings } from 'izitoast';
import type { ActionCreator, Dispatch } from 'redux';
import type { ThunkAction } from 'redux-thunk';

import FeatureFlags from '../api/FeatureFlags';
import InfoPagesApi from '../api/InfoPagesApi';
import { history } from '../reducers/browserHistory';
import { taskIdToNumericalId } from '../Utilities/ticketList';
import { startAjaxCall } from './ajaxStatusActions';
import { fetchFeatureFlags } from './configActions';
import { UPDATE_INFOPAGE_FAILURE, UPDATE_INFOPAGE_SUCCESS } from './index';
import { fetchInfoPagesError, fetchInfoPagesSuccess } from './infoPagesActionsRTK';
import { activateTab, closeTab } from './tabActionsRTK';
import { removeTicketFromDetailed } from './ticketsActionsRTK';
import { contentsVC } from 'src/api/VersionControl';
import { typeToPrefix } from 'src/types/ContentTypes';
import { StaticTabs } from 'src/types/TicketList';

import type { State } from 'src/types/initialState';
import type { Ticket } from 'src/types/Ticket';

export const updateInfoPageSuccess = (contentId: string) => {
  return { type: UPDATE_INFOPAGE_SUCCESS, contentId };
};

export const updateInfoPageFailure = (contentId: string) => {
  return { type: UPDATE_INFOPAGE_FAILURE, contentId };
};

export const fetchInfoPages = createAsyncThunk<
  void,
  { searchParams?: object; id: string; throughSearchTab: boolean },
  { rejectValue: Error }
>('fetchInfoPages', async ({ searchParams, id, throughSearchTab }, { dispatch }) => {
  if (!FeatureFlags.isFlagOn('ENABLE_KNOWLEDGE_BASE')) return;

  const showIziToast = (settings: IziToastSettings) => {
    setTimeout(() => {
      iziToast.show({
        displayMode: 2,
        id: 'searchToast',
        target: '.toastSearchTarget',
        ...settings
      });
    }, 500);
  };

  dispatch(startAjaxCall({ name: 'FETCH_INFOPAGES' }));
  try {
    const infoPages = await InfoPagesApi.getInfoPages({ searchterms: { ...searchParams } });
    if ((!infoPages || infoPages.length < 1) && throughSearchTab) {
      showIziToast({
        color: 'blue',
        message: t('SEARCH_RESULT_NO_INFO_PAGES')
      });
    } else if (infoPages && infoPages.length === 1 && infoPages[0].id === 'Error') {
      showIziToast({
        color: 'red',
        message: t('SEARCH_FAILED')
      });
    } else if (infoPages && infoPages.length >= 1 && throughSearchTab && infoPages[0].id !== 'Error') {
      showIziToast({
        color: 'green',
        message: t('SEARCH_RESULTS_INFO_PAGES_COUNT') + '' + infoPages.length + '' + t('SEARCH_INFO_PAGES_PREFIX')
      });
    }
    dispatch(fetchInfoPagesSuccess({ tickets: infoPages, id }));
  } catch (error) {
    console.error('Failed to load info pages', error);
    dispatch(fetchInfoPagesError(id));
  }
});

export const updateInfoPage: ActionCreator<ThunkAction<any, State, any, any>> = (
  id: string,
  content: Partial<Ticket>,
  closeAfterUpdate: boolean
) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(startAjaxCall({ name: 'UPDATE_INFOPAGE', id: id }));

    try {
      await InfoPagesApi.updateInfopage(id, content);
      contentsVC.clearEntry(taskIdToNumericalId(id));

      if (closeAfterUpdate) {
        const { ticketTabs } = getState();
        const tabs = ticketTabs.filter((tab) => {
          return tab.id !== id && tab.id.substring(0, 3) === typeToPrefix('infopage');
        });

        if (tabs.length === 0) {
          history.push('/infopage');
        } else if (tabs.length > 0) {
          const activeId = tabs[tabs.length - 1].id;
          history.push(`/infopage/${activeId}`);
          dispatch(activateTab(activeId));
        }

        dispatch(closeTab(id));
        dispatch(removeTicketFromDetailed(id));
      }

      dispatch(updateInfoPageSuccess(id));

      iziToast.success({
        title: t('OK'),
        icon: 'icon check',
        message: t('INFOPAGE_SAVE_SUCCESS'),
        timeout: 2500
      });
    } catch (error) {
      console.error('Error while updating infopage', error);
      iziToast.error({
        title: `${t('ERROR')}!`,
        icon: 'icon delete',
        message: t('INFOPAGE_SAVE_FAILURE'),
        timeout: 7500
      });
      dispatch(updateInfoPageFailure(id));
    }
  };
};

export const fetchFeatureFlagsAndInfoPages = createAsyncThunk<void, void, { rejectValue: Error }>(
  'fetchFeatureFlagsAndInfoPages',
  async (_, { dispatch }) => {
    await dispatch(fetchFeatureFlags());
    await dispatch(fetchInfoPages({ id: StaticTabs.MAIN_VIEW, throughSearchTab: false }));
  }
);
