import { Channels } from '@eeedo/types';
import { t as tr } from 'i18next';

import type { DropdownItemProps } from 'semantic-ui-react';

import type { ContentTypesFields } from '../types/Ticket';
import type { Template, TemplateContentJson } from 'src/types/Template';

export const nonHTMLChannels: Channels[] = [
  Channels.chat,
  Channels.facebook,
  Channels.giosg,
  Channels.instagram,
  Channels.sms,
  Channels.tampuuri,
  Channels.viestitytti,
  Channels.whatsapp
];

export const mapTemplate = (template: Template): Template => {
  if (typeof template.template === 'string') {
    try {
      return { ...template, template: JSON.parse(template.template) };
    } catch (err) {
      console.error(err);
    }
  }

  return template;
};

export const getContentTemplatesOptions = ({
  templates,
  contentType = 'tickets',
  disabled,
  onItemClick
}: {
  templates: Template<TemplateContentJson>[];
  contentType?: ContentTypesFields;
  disabled?: boolean;
  onItemClick: (template?: Template<TemplateContentJson>) => () => void;
}) => {
  const options: DropdownItemProps[] = templates
    .filter((template) => !template.template?.contentType || template.template.contentType === contentType)
    .map((template, index) => ({
      key: `add-new-template-${index}`,
      text: template.name,
      value: template.id,
      disabled,
      onClick: onItemClick(template)
    }));

  options.unshift({
    key: 'add-new-template-default',
    text: `${tr('GENERAL_DEFAULT_TEMPLATE')}`,
    value: undefined,
    selected: false,
    disabled,
    onClick: onItemClick()
  });

  return options;
};
