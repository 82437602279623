import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { FC } from 'react';

import Dropdown from './Dropdown/Dropdown';
import Input from './Input/Input';

import type { ComponentProp } from 'src/types/generic';

interface Props {
  value?: number | string;
  onChange: (value: number) => void;
}

const multiplierOptions: { text: string; value: number }[] = [
  { text: 'GENERAL_SECONDS', value: 1 },
  { text: 'GENERAL_MINUTES', value: 60 },
  { text: 'GENERAL_HOURS', value: 60 * 60 },
  { text: 'GENERAL_DAYS', value: 24 * 60 * 60 },
  { text: 'GENERAL_WEEKS', value: 7 * 24 * 60 * 60 },
  { text: 'GENERAL_MONTHS', value: 30 * 24 * 60 * 60 },
  { text: 'GENERAL_YEARS', value: 365 * 24 * 60 * 60 }
];

export const getMultiplierValues = (value: number) => {
  const option = multiplierOptions.toReversed().find((option) => value % option.value === 0);

  return {
    value: option ? value / option.value : value,
    text: option ? option.text : '',
    option
  };
};

const TimeInputWithDropdown: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation();

  const [seconds, setSeconds] = useState(0);
  const [multiplier, setMultiplier] = useState(60 * 60);

  const multiplierDropdownOptions = multiplierOptions.map(({ text, value }) => ({ text: t(text), value }));

  useEffect(() => {
    if (!value) {
      setSeconds(0);
      setMultiplier(60 * 60);

      return;
    }

    const multiplierValues = getMultiplierValues(+value);

    if (multiplierValues.option) {
      setMultiplier(multiplierValues.option.value);
      setSeconds(multiplierValues.value);
    }
  }, [value]);

  const onInputChange = useCallback<ComponentProp<typeof Input, 'onChange'>>(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSeconds(+event.target.value);
      onChange(+event.target.value * multiplier);
    },
    [onChange, setSeconds]
  );
  const onDropdownChange = useCallback<ComponentProp<typeof Dropdown, 'onChange'>>(
    (_event, data) => {
      const value = data.value as number;
      setMultiplier(value);
      onChange(seconds * value);
    },
    [onChange, setSeconds]
  );

  return (
    <Input
      type="number"
      labelPosition="right"
      value={seconds || ''}
      onChange={onInputChange}
      innerLabel={<Dropdown value={multiplier} options={multiplierDropdownOptions} onChange={onDropdownChange} />}
    />
  );
};

export default React.memo(TimeInputWithDropdown);
