import { UserStatusType } from '@eeedo/types';
import React from 'react';

import type { CurrentUserStatus, UserStatus } from '@eeedo/types';

import Label from '../generic/Label/Label';
import ChangeAvailabilityModal from './ChangeAvailabilityModal';
import { selectCurrentUserStatus } from './selectors';
import { useModal } from 'src/Components/Modal/ModalContext';
import { useAppSelector } from 'src/store';

const CurrentUserStatusLabel = () => {
  const [currentUserStatus, originalStatus] = useAppSelector(selectCurrentUserStatus) as [
    CurrentUserStatus,
    UserStatus
  ];

  const { openModal } = useModal();

  return (
    <Label
      className="currentUserStatusOption"
      color={originalStatus.type === UserStatusType.READY ? 'green' : 'red'}
      onClick={(event) => {
        event.stopPropagation();
        openModal(<ChangeAvailabilityModal />, 'small');
      }}
    >
      {currentUserStatus.description || originalStatus.text}
    </Label>
  );
};

export default CurrentUserStatusLabel;
