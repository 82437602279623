import { DndContext, DragOverlay, MeasuringStrategy, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import React, { useState } from 'react';

import type { DragEndEvent, DragOverEvent, DragStartEvent } from '@dnd-kit/core';
import type { FC } from 'react';

import type { IItem, IItemsCollection, IMultiDraggableDropdownContext } from './types';

export const MultiDraggableDropdownContext = React.createContext<IMultiDraggableDropdownContext | null>(null);

interface IProps {
  itemsCollection: IItemsCollection;
  setItemsCollection: (value: IItemsCollection) => void;
  children?: React.ReactNode;
}

const MultiDraggableDropdownProvider: FC<IProps> = ({ itemsCollection, setItemsCollection, children }) => {
  const [draggingItem, setDraggingItem] = useState<IItem | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [sourceArea, setSourceArea] = useState<string | null>(null);
  const [targetArea, setTargetArea] = useState<string | null>(null);

  // Our pointer sensor
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 4 }
    })
  );

  // On drag start, figure out which area the item came from
  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    const areaName = active.data.current?.sortable.containerId || '';
    // Find which dropdown array contains the item
    const foundItem = itemsCollection[areaName].find((i) => i.id === active.id);
    if (foundItem) {
      setDraggingItem(foundItem);
      setIsDragging(true);
      setSourceArea(areaName);
      setTargetArea(areaName);
    }
  };

  // On drag end, do reorder or cross-area move if relevant
  const handleDragEnd = (event: DragEndEvent) => {
    setIsDragging(false);
    const { active, over } = event;
    const item = draggingItem; // store locally for clarity

    if (!item || !sourceArea) {
      // no valid drag
      cleanup();
      return;
    }

    // If dropped exactly where we picked it up, do nothing
    if (active?.id && over?.id && active.id === over.id) {
      cleanup();
      return;
    }

    // Are we dropping in a different area or the same area?
    if (sourceArea && targetArea && sourceArea !== targetArea) {
      setItemsCollection({
        ...itemsCollection,
        [sourceArea]: (itemsCollection[sourceArea] || []).filter((i) => i.value !== item.value),
        [targetArea]: [...(itemsCollection[targetArea] || []), item]
      });
    } else if (sourceArea && targetArea && sourceArea === targetArea) {
      // reorder in the same area
      const oldIndex = (itemsCollection[sourceArea] || []).findIndex((i) => i.value === active?.id);
      const newIndex = (itemsCollection[sourceArea] || []).findIndex((i) => i.value === over?.id);
      if (oldIndex !== -1 && newIndex !== -1) {
        const newArr = arrayMove(itemsCollection[sourceArea], oldIndex, newIndex);
        setItemsCollection({
          ...itemsCollection,
          [sourceArea]: newArr
        });
      }
    }
    cleanup();
  };

  // Helper to reset dragging state
  const cleanup = () => {
    setDraggingItem(null);
    setSourceArea(null);
    setTargetArea(null);
  };

  const contextValue: IMultiDraggableDropdownContext = {
    draggingItem,
    isDragging,
    itemsCollection,
    sourceArea,
    targetArea,
    setDraggingItem,
    setIsDragging,
    setItemsCollection,
    setSourceArea,
    setTargetArea
  };

  const handleDragOver = (event: DragOverEvent) => {
    const { over } = event;
    const id = over?.id as string; // could be container name or item value

    if (!over) {
      setTargetArea(null);
      return;
    }

    // Check if it's the name of one of our containers
    if (Object.keys(itemsCollection).includes(id)) {
      setTargetArea(id);
    } else {
      // It's presumably an item ID. Find which container it's in
      const containerName = findAreaByItem(id);
      setTargetArea(containerName ?? null);
    }
  };

  const findAreaByItem = (itemValue: string): string | null => {
    for (const areaName of Object.keys(itemsCollection)) {
      if (itemsCollection[areaName].some((i) => i.value === itemValue)) {
        return areaName;
      }
    }
    return null;
  };

  return (
    <MultiDraggableDropdownContext.Provider value={contextValue}>
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
        measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
      >
        {children}

        <DragOverlay>
          {draggingItem ? (
            <div
              style={{
                padding: '4px 8px',
                background: draggingItem.color,
                border: '1px solid #b3d4fc',
                borderRadius: '4px'
              }}
            >
              {draggingItem.value}
            </div>
          ) : null}
        </DragOverlay>
      </DndContext>
    </MultiDraggableDropdownContext.Provider>
  );
};

export default MultiDraggableDropdownProvider;
