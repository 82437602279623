import type { Channel, ChannelAvailability } from '@eeedo/types';

import { apiClient } from 'src/Utilities/httpClients';

import type { ChannelsAvailabilityState } from 'src/reducers/channelsAvailabilityReducer';

export default class ChannelApi {
  static getChannelTypes = async (): Promise<Channel[]> => {
    return apiClient.get('/channelTypes').then((response) => response.data);
  };

  static getChannelsAvailability = async (): Promise<ChannelsAvailabilityState> => {
    return apiClient.get('/channels/availability').then((response) => response.data);
  };

  static patchChannel = async (payload: { id: number; data: Channel }): Promise<Channel> => {
    return apiClient.patch(`/channel/${payload.id}`, payload.data).then((response) => response.data);
  };

  static changeChannelsAvailability = async (channelsAvailability: ChannelAvailability[]) => {
    return apiClient.patch('/channels/availability', channelsAvailability).then((response) => response.data);
  };
}
