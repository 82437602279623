import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import activeTicketTabReducer from './activeTicketTabReducer';
import aiFunctionsReducer from './aiFunctionsReducer';
import ajaxStatusReducer from './ajaxStatusReducer';
import articlesReducer from './articlesReducer';
import attachmentsReducer from './attachmentsReducer';
import authReducer from './authReducer';
import { history } from './browserHistory';
import callRequestsReducer from './callRequestsReducer';
import campaignReducer from './campaignReducer';
import caseIFrameUrlReducer from './caseIFrameUrlReducer';
import categoriesReducer from './categoriesReducer';
import channelsAvailabilityReducer from './channelsAvailabilityReducer';
import channelsReducer from './channelsReducer';
import chatStatusReducer from './chatConfigsReducer';
import chatTypeStatusReducer from './chatTypeStatusReducer';
import commentFiltersReducer from './commentFiltersReducer';
import sortingReducer from './commentsMenuReducer';
import contentStatusOptionsReducer from './ContentStatusOptionsReducer';
import draftReducer from './draftsReducer';
import eIdentificationReducer from './eIdentificationReducer';
import eIdentificationRelationReducer from './eIdentificationRelationReducer';
import entitiesReducer from './entitiesReducer';
import entityTagsReducer from './entityTagsReducer';
import entityViewerReducer from './entityViewerReducer';
import filterPresetsReducer from './filterPresetsReducer';
import floatingWindowsReducer from './floatingWindowsReducer';
import infoPageListTabReducer from './infoPageListTabReducer';
import initialRequestReducer from './initialRequestsReducer';
import linkedTicketReducer from './linkedTicketReducer';
import linkListReducer from './linkListReducer';
import mobileReducer from './mobileReducer';
import notificationsReducer from './notificationsReducer';
import openAIReducer from './openAIReducer';
import phoneConfigurationsReducer from './phoneConfigurationsReducer';
import prioritiesReducer from './prioritiesReducer';
import rescheduledCallbackWidgetReducer from './rescheduledCallbackWidget';
import rolesReducer from './rolesReducer';
import settingsReducer from './settingsReducer';
import suggestionsReducer from './suggestionsReducer';
import tabsPersistReducer from './tabsPersistReducer';
import tabsReducer from './tabsReducer';
import tagsReducer from './tagListReducer';
import templatesReducer from './templatesReducer';
import ticketListTabReducer from './ticketListTabReducer';
import ticketListTabToolsReducer from './ticketListTabToolsReducer';
import ticketPrioritiesReducer from './ticketPrioritiesReducer';
import ticketReducer from './ticketReducer';
import transcriptionsReducer from './transcriptionsReducer';
import typesMetadataReducer from './typesMetadataReducer';
import typesReducer from './typesReducer';
import userDataReducer from './userDataReducer';
import usersListReducer from './usersListReducer';
import userStatusReducer from './userStatusReducer';
import webhooksReducer from './webhooksReducer';
import workStatusReducer from './workStatusReducer';
import integrationsReducer from 'src/reducers/integrationsReducer';
import onlineStatusReducer from 'src/reducers/onlineStatusReducer';
import singleLineViewReducer from 'src/reducers/singleLineViewReducer';
import userPreferencesReducer from 'src/reducers/userPreferencesReducer';

const router = connectRouter(history);
const ajaxCalls = ajaxStatusReducer;

export default combineReducers({
  activeTicketTab: activeTicketTabReducer,
  aiFunctions: aiFunctionsReducer,
  ajaxCallsInProgress: ajaxCalls,
  articles: articlesReducer,
  attachments: attachmentsReducer,
  auth: authReducer,
  callRequests: callRequestsReducer,
  campaign: campaignReducer,
  caseIFrameUrls: caseIFrameUrlReducer,
  categories: categoriesReducer,
  channels: channelsReducer,
  channelsAvailability: channelsAvailabilityReducer,
  chatConfigs: chatStatusReducer,
  chatTypeStatuses: chatTypeStatusReducer,
  commentFilters: commentFiltersReducer,
  commentsMenu: sortingReducer,
  detailedTickets: ticketReducer,
  drafts: draftReducer,
  eIdentifications: eIdentificationReducer,
  eIdentificationsRelation: eIdentificationRelationReducer,
  entities: entitiesReducer,
  entityTags: entityTagsReducer,
  entityViewer: entityViewerReducer,
  filterPresets: filterPresetsReducer,
  infoPageListTabs: infoPageListTabReducer,
  initialRequests: initialRequestReducer,
  integrations: integrationsReducer,
  linkLists: linkListReducer,
  linkedTickets: linkedTicketReducer,
  mobileReducer: mobileReducer,
  notifications: notificationsReducer,
  onlineStatus: onlineStatusReducer,
  openAI: openAIReducer,
  phoneConfigurations: phoneConfigurationsReducer,
  priorities: prioritiesReducer,
  rescheduleCallbackWidget: rescheduledCallbackWidgetReducer,
  roles: rolesReducer,
  router: router,
  settings: settingsReducer,
  singleLineView: singleLineViewReducer,
  suggestions: suggestionsReducer,
  tabsPersist: tabsPersistReducer,
  tags: tagsReducer,
  templates: templatesReducer,
  ticketListTabTools: ticketListTabToolsReducer,
  ticketListTabs: ticketListTabReducer,
  ticketPriorities: ticketPrioritiesReducer,
  ticketTabs: tabsReducer,
  ticketTypes: typesReducer,
  ticketTypesMetadata: typesMetadataReducer,
  transcriptions: transcriptionsReducer,
  userData: userDataReducer,
  userStatusesState: userStatusReducer,
  usersList: usersListReducer,
  webhooks: webhooksReducer,
  windows: floatingWindowsReducer,
  workStatus: workStatusReducer,
  userPreferences: userPreferencesReducer,
  contentStatusOptions: contentStatusOptionsReducer
});
