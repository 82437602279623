import React from 'react';
import { Accordion } from 'semantic-ui-react';

import type { PersonalData, PhoneConfiguration, TicketType } from '@eeedo/types';
import type { History } from 'history';

import FeatureFlags from 'src/api/FeatureFlags';
import ForeignIdApi from 'src/api/ForeignIdApi';
import TicketsApi from 'src/api/TicketsApi';
import {
  findPhoneConfiguration,
  getIntegrationData,
  parsePhoneConfiguration,
  shouldCreateAPhoneCallTicket
} from 'src/Components/PhoneServices/utils/phoneConfigurationUtils';
import { PhoneIntegrationType } from 'src/handlers/handlePhoneCall';
import { COUNTRY_ISO3, normalizePhoneNumber } from 'src/Utilities/normalizeNumber';
import { replaceWorkingOn, startWorkingOn } from 'src/Utilities/workStatusParser';

import type { IntegrationData, UpdateDetailsObject } from 'src/handlers/handlePhoneCall';
import type { WorkStatus } from 'src/types/RealTimeService';
import type { Comment } from 'src/types/Ticket';

interface MitelIntegrationProps {
  ticketTypes: TicketType[];
  url: string;
  history: History;
  userData: PersonalData;
  phoneConfigurations: PhoneConfiguration[];
  workStatus: WorkStatus;
  isOpen: boolean;
  noAccordion?: boolean;

  handleIncomingPhoneCall(callObject: IntegrationData, phoneIntegrationType: PhoneIntegrationType): Promise<void>;
  createNewComment(
    ticketId: number,
    payload: {
      content: string;
      direction: string;
      foreignId: string;
      foreignIdType: string;
    }
  ): Promise<Comment>;
  activateFoundTicket(ticketId: number): void;
  setIsOpen: (active: boolean) => void;
}

interface MitelEventData {
  methodName: 'NewCall' | 'EndCall';
  arguments: {
    id: string;
    primaryId: string;
    ani: string;
    dnis: string;
    direction: 'inbound' | 'outbound';
    queueName?: string;
  };
}

class MitelIntegration extends React.Component<MitelIntegrationProps> {
  constructor(props: MitelIntegrationProps) {
    super(props);

    this.state = {
      isAccordionOpen: false
    };
  }

  componentWillMount() {
    window.addEventListener('message', this.handleMitelEvents);

    window['__testMitel'] = (data: MitelEventData) => {
      if (!data.arguments.ani) {
        console.error('Please specify phoneNumber');
        return;
      }

      this.handleMitelEvents({ data } as MessageEvent);
    };
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMitelEvents);

    delete window['__testMitel'];
  }

  private handleMitelEvents = async (event: MessageEvent) => {
    let data: MitelEventData | string = event.data;

    if (typeof data === 'string') {
      try {
        data = JSON.parse(data) as MitelEventData;
      } catch (error) {
        console.error('[Mitel-Integration]: Failed to parse incoming message data ', error);
        return;
      }
    }

    switch (data.methodName) {
      case 'NewCall': {
        const {
          arguments: { id, primaryId, ani, dnis, direction, queueName }
        } = data;

        /**
         * Transfer a Call event condition
         */
        if (primaryId.length !== 0 && direction === 'outbound') {
          const searchResult = await ForeignIdApi.searchForeignId({
            foreignId: primaryId,
            foreignIdType: 'mitel',
            localIdType: 'comment'
          });
          const originalComment = searchResult[0];

          if (originalComment) {
            const originalTicketId = parseInt(originalComment.localId, 10);
            await this.props.createNewComment(originalTicketId, {
              content: `[send_transferring_from_to:${ani} ${dnis}]`,
              direction: 'in',
              foreignId: primaryId,
              foreignIdType: 'mitel'
            });

            // Doesn't carry any info to the client, needed only for Mitel chain logic to find a ticket
            return this.props.createNewComment(originalTicketId, {
              content: `Transfering Call ID ${id}`,
              direction: 'in',
              foreignId: id,
              foreignIdType: 'mitel'
            });
          }
          return console.error('[Mitel-Integration]: Failed to find original ticket during transfering call');
        }

        /**
         * Event with client's real phone number
         */
        if (primaryId.length !== 0 && direction === 'inbound' && !!queueName) {
          const searchResult = await ForeignIdApi.searchForeignId({
            foreignId: primaryId,
            foreignIdType: 'mitel',
            localIdType: 'comment'
          });
          const originalComment = searchResult[0];

          if (originalComment) {
            const originalTicketId = parseInt(originalComment.localId, 10);

            this.props.activateFoundTicket(originalTicketId);
            const currentStatus = this.props.workStatus.status.find(
              (status) => parseInt(status.ticketId, 10) === originalTicketId
            );
            if (currentStatus) {
              await TicketsApi.replaceWorkingOn(
                ...replaceWorkingOn(`TSK${originalTicketId}`, currentStatus.UID, this.props.userData.UID)
              );
            } else {
              await TicketsApi.startWorkingOn(...startWorkingOn(this.props.userData.UID, `TSK${originalTicketId}`));
            }

            return this.props.createNewComment(originalTicketId, {
              content: `[original_phonenumber:${ani}]`,
              direction: 'in',
              foreignId: id,
              foreignIdType: 'mitel'
            });
          } else {
            /**
             * New Call event condition
             */
            return this.composeNewCallMitelEvent({ id, ani, dnis });
          }
        }

        /**
         * Receive a Transfer Call event condition
         */
        if (primaryId.length !== 0 && direction === 'inbound' && !queueName) {
          const searchResult = await ForeignIdApi.searchForeignId({
            foreignId: primaryId,
            foreignIdType: 'mitel',
            localIdType: 'comment'
          });
          const originalComment = searchResult[0];

          if (originalComment) {
            const originalTicketId = parseInt(originalComment.localId, 10);

            this.props.activateFoundTicket(originalTicketId);
            const currentStatus = this.props.workStatus.status.find(
              (status) => parseInt(status.ticketId, 10) === originalTicketId
            );
            if (currentStatus) {
              await TicketsApi.replaceWorkingOn(
                ...replaceWorkingOn(`TSK${originalTicketId}`, currentStatus.UID, this.props.userData.UID)
              );
            } else {
              await TicketsApi.startWorkingOn(...startWorkingOn(this.props.userData.UID, `TSK${originalTicketId}`));
            }

            return this.props.createNewComment(originalTicketId, {
              content: `[receive_transferring_from_to:${ani} ${dnis}]`,
              direction: 'in',
              foreignId: id,
              foreignIdType: 'mitel'
            });
          }
          return console.error('[Mitel-Integration]: Failed to find original ticket during transfering call');
        }

        return;
      }
      case 'EndCall':
        return;
      default:
        return;
    }
  };

  private openTabIfFeatureFlagOn = () => {
    if (FeatureFlags.isFlagOn('OPEN_PHONE_TAB_ON_INCOMING_CALL')) {
      this.props.setIsOpen(true);
    }
  };

  private composeNewCallMitelEvent = async ({ id, ani, dnis }: { id: string; ani: string; dnis: string }) => {
    this.openTabIfFeatureFlagOn();

    const normalizedPhoneNumber = normalizePhoneNumber(ani, COUNTRY_ISO3.BEL);
    const direction = 'in';
    const configuration = findPhoneConfiguration(this.props.phoneConfigurations, dnis);
    const parsedConfiguration = parsePhoneConfiguration(configuration);

    const UID = this.props.userData.UID;
    const history = this.props.history;

    const detailsObjects: UpdateDetailsObject[] = [
      {
        updateKey: 'mitelId',
        updateValue: id,
        group: 'CaseDetails'
      }
    ];

    const callObject = getIntegrationData({
      UID,
      configuration,
      detailsObjects,
      direction,
      history,
      normalizedPhoneNumber,
      parsedConfiguration,
      serviceName: dnis,
      ticketTypes: this.props.ticketTypes,
      userDefaultTicketTypeId: this.props.userData.userPreferences.defaultTicketType
    });

    callObject.integrationPayload = {
      foreignId: id,
      foreignIdType: 'mitel'
    };

    if (shouldCreateAPhoneCallTicket(configuration, parsedConfiguration)) {
      // When no configuration is found, or when configuration does not prevent creation
      await this.props.handleIncomingPhoneCall(callObject, PhoneIntegrationType.MITEL);
    }
  };

  private toggleAccordion = () => {
    this.props.setIsOpen(!this.props.isOpen);
  };

  private renderIframe = () => {
    return (
      <iframe
        title="Mitel"
        id="MitelIntegration"
        scrolling="yes"
        style={{
          width: '100%',
          height: `${this.props.noAccordion ? 100 : 50}vh`,
          flex: '1 1 auto',
          display: 'flex'
        }}
        src={this.props.url}
        frameBorder={0}
        allowFullScreen={false}
      />
    );
  };

  render() {
    return this.props.noAccordion ? (
      this.renderIframe()
    ) : (
      <>
        <Accordion.Title className="ticketlist ticketlist_OC" active={this.props.isOpen} onClick={this.toggleAccordion}>
          <div style={{ fontSize: '1.2em' }}>Mitel</div>
        </Accordion.Title>

        <Accordion.Content active={this.props.isOpen}>
          <div>{this.renderIframe()}</div>
        </Accordion.Content>
      </>
    );
  }
}

export default MitelIntegration;
