import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { List } from 'semantic-ui-react';

import type { CSSProperties, FC } from 'react';

import Button from '../../generic/Button/Button';
import Popup from '../../generic/Popup/Popup';
import AttachmentMenuItem from './AttachmentMenuItem';

import type { AttachmentMenuItem as IAttachmentMenuItem } from 'src/types/Attachments';

const popupStyles: CSSProperties = { padding: '2px' };

interface AttachmentItemMenuProps {
  options: IAttachmentMenuItem[];
}

const AttachmentMenu: FC<AttachmentItemMenuProps> = ({ options }) => (
  <Popup
    on={'click'}
    trigger={<Button size="xs" borderless icon={<FontAwesomeIcon size="sm" icon={faEllipsis} />} />}
    position="bottom right"
    style={popupStyles}
    content={
      <>
        <List>
          {options.map((option) => (
            <AttachmentMenuItem option={option} />
          ))}
        </List>
      </>
    }
  />
);

export default React.memo(AttachmentMenu);
