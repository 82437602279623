import React from 'react';

import type { FC } from 'react';

import * as styles from './TicketList.style';
import Label from 'src/Components/generic/Label/Label';

interface Props {
  title: React.ReactNode;
  count: number;
}

const TicketListAccordionTitle: FC<Props> = ({ title, count }) => {
  return (
    <div style={styles.TicketListTitleContainer}>
      <span style={styles.TicketListTitle}>{title}</span>
      <span style={styles.TicketListTitleCount}>
        <Label content={count} />
      </span>
    </div>
  );
};

export default TicketListAccordionTitle;
