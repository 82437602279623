import { Direction, SortBy } from '@eeedo/types';
import { createReducer } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';

import { activateInfopagelistTab, addInfopagelistTab, setInfopagelistSearch } from '../actions/CaseListActions';
import {
  clearInfopagelistFilter,
  fetchInfoPagesError,
  fetchInfoPagesSuccess,
  removeInfopageFromInfopagelist,
  setInfoPageListAccordion,
  setInfopagelistFilter,
  updateInfoPageInList
} from '../actions/infoPagesActionsRTK';
import { closeInfopagelistTab } from '../actions/ticketListTabActionsRTK';
import { StaticTabs } from '../types/TicketList';
import { getURLFilterParams } from '../Utilities/helper';

import type { TicketListTab } from '../types/TicketList';

export type InfoPageListTabState = Map<string, TicketListTab>;

const initialState: InfoPageListTabState = new Map([
  [
    StaticTabs.MAIN_VIEW,
    {
      id: StaticTabs.MAIN_VIEW,
      title: StaticTabs.MAIN_VIEW,
      activeTab: true,
      hasError: false,
      filters: getURLFilterParams(window.location.search),
      tickets: [],
      searchCriteria: [],
      accordionIndex: 0,
      rowIndex: undefined,
      sorting: SortBy.dueDate,
      direction: Direction.ASC
    }
  ]
]);

enableMapSet();

const tabsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clearInfopagelistFilter, (draft, action) => {
      const tab = draft.get(action.payload);
      if (tab) {
        tab.filters = {};
      }
    })
    .addCase(setInfopagelistFilter, (draft, action) => {
      const tab = draft.get(action.payload.id);
      if (tab) {
        tab.filters = {
          ...tab.filters,
          [action.payload.parameter]: action.payload.value
        };
      }
    })
    .addCase(fetchInfoPagesError, (draft, action) => {
      const tab = draft.get(action.payload);
      if (tab) {
        tab.hasError = true;
      }
    })
    .addCase(fetchInfoPagesSuccess, (draft, action) => {
      const tab = draft.get(action.payload.id);
      if (tab) {
        tab.tickets = action.payload.tickets;
        tab.hasError = false;
      }
    })
    .addCase(updateInfoPageInList, (draft, action) => {
      const tab = draft.get(action.payload.tabId);
      if (tab) {
        const ticketIndex = tab.tickets.findIndex((t) => t.id === action.payload.data.id);
        if (ticketIndex !== -1) {
          // Update
          tab.tickets[ticketIndex] = action.payload.data;
        } else {
          // Add
          tab.tickets.push(action.payload.data);
        }
      }
    })
    .addCase(addInfopagelistTab, (draft, action) => {
      if (!draft.get(action.payload.id)) {
        draft.set(action.payload.id, action.payload);
      }
    })
    .addCase(closeInfopagelistTab, (draft, action) => {
      draft.delete(action.payload);
    })
    .addCase(activateInfopagelistTab, (draft, action) => {
      for (const tab of draft.values()) {
        tab.activeTab = tab.id === action.payload;
      }
    })
    .addCase(setInfopagelistSearch, (draft, action) => {
      const tab = draft.get(action.payload.id);
      if (tab) {
        tab.searchCriteria = action.payload.searchCriteria;
      }
    })
    .addCase(setInfoPageListAccordion, (draft, action) => {
      const tab = draft.get(action.payload.id as string);
      if (tab) {
        // FIXME: why there is null? is it valid usecase?
        tab.accordionIndex = action.payload.accordionIndex as number;
      }
    })
    .addCase(removeInfopageFromInfopagelist, (draft, action) => {
      const tab = draft.get(action.payload.tabId);
      if (tab) {
        tab.tickets = tab.tickets.filter((p) => p.id !== action.payload.infopageId);
      }
    });
});

export default tabsReducer;
