import { ContentNamingType, convertTicketId } from '@eeedo/utils';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';

import type { PersonalData, TicketType } from '@eeedo/types';
import type { FC } from 'react';

import { addRelatedTicketToTicket, createNewSubTicket } from 'src/actions/ticketsActions';
import AddRelatedTicketById from 'src/Components/Case/Widget/TicketRelationsWidget/AddRelatedTicketById';
import FloatingDropdown from 'src/Components/generic/FloatingDropdown/FloatingDropdown';
import Section from 'src/Components/generic/Section/Section';
import { selectAllowedContentTemplates } from 'src/selectors/rootStateSelectors';
import { useAppSelector, useAppThunkDispatch } from 'src/store';
import { contentTypeMap } from 'src/Utilities/constants';
import { getContentTemplatesOptions } from 'src/Utilities/templates';
import { getNewContentFromTemplate } from 'src/Utilities/ticket';

import type { ContentTypes } from 'src/types/ContentTypes';
import type { RelationOptions } from 'src/types/LinkedTickets';
import type { Template, TemplateContentJson } from 'src/types/Template';
import type { Ticket } from 'src/types/Ticket';

interface Props {
  attachPlaceholderText: string;
  createText: string;
  relationOptions: RelationOptions;
  task: Ticket;
  ticketTypes: TicketType[];
  type: ContentTypes;
  userData: PersonalData;
}

const TicketRelationsForm: FC<Props> = ({
  attachPlaceholderText,
  createText,
  relationOptions,
  task,
  ticketTypes,
  userData
}) => {
  const [relationType, setRelationType] = useState('parentToChild');
  const dispatch = useAppThunkDispatch();
  const templates = useAppSelector(selectAllowedContentTemplates);
  const contentType = contentTypeMap[task.type] ?? 'tickets';
  const defaultTaskType = ticketTypes.find(
    (ticketType) => ticketType.id === userData.userPreferences.defaultTicketType
  );

  const createNewSubContent = useCallback(
    (template?: Template<TemplateContentJson>) => () => {
      const newContent = getNewContentFromTemplate(contentType, defaultTaskType!.name, template);

      if (contentType && newContent) {
        dispatch(
          createNewSubTicket({
            type: contentType,
            ticket: newContent,
            manuallyCreated: true,
            parentTickedId: task.id,
            ticketRelationType: relationType
          })
        );
      }
    },
    [contentType, defaultTaskType, dispatch, relationType, task.id]
  );

  const options = useMemo(
    () =>
      getContentTemplatesOptions({
        templates,
        contentType,
        onItemClick: createNewSubContent
      }),
    [contentType, createNewSubContent, templates]
  );

  return (
    <Section direction="column" gap={8} margin="0 0 10px 0">
      <Dropdown
        clearable={false}
        data-test-id="ticketRelationsDropdown"
        disabled={!userData.permissions.includes('updateContent')}
        fluid={true}
        multiple={false}
        noResultsMessage={''}
        options={relationOptions}
        placeholder={''}
        scrolling={true}
        search={false}
        selectOnBlur={false}
        selection={true}
        size="small"
        value={relationType || relationOptions[0].value}
        onChange={(e: any, data: any) => {
          setRelationType(data.value);
        }}
      />

      <FloatingDropdown
        buttonGroupProps={{
          fluid: true
        }}
        buttonProps={{
          iconLeft: <FontAwesomeIcon icon={faPlus} />,
          content: createText,
          onClick: createNewSubContent(),
          type: 'primary',
          center: true,
          disabled: !userData.permissions.includes('updateContent')
        }}
        dropdownProps={{
          icon: 'chevron down',
          color: 'dark_violet',
          button: true,
          value: '',
          disabled: !userData.permissions.includes('updateContent')
        }}
        options={options}
      />

      <AddRelatedTicketById
        placeholder={attachPlaceholderText}
        addRelatedTicketToTicket={(targetId) => {
          dispatch(
            addRelatedTicketToTicket({
              id: task.id,
              targetId: convertTicketId(targetId, ContentNamingType.String),
              type: relationType
            })
          );
        }}
      />
    </Section>
  );
};

export default TicketRelationsForm;
