import { UserStatusType } from '@eeedo/types';
import React from 'react';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import { Image } from 'semantic-ui-react';

import type { UserWithProfile } from '@eeedo/types';
import type { CSSProperties, FC } from 'react';

import { selectCurrentReadinessStatus } from '../UserStatus/selectors';
import { getProfileName } from './utils';
import { useAppSelector } from 'src/store';
import { getUserImage } from 'src/Utilities/images';

export type UserAvatarSize = '22' | '24' | '26' | '28' | '32' | '35' | '40' | '50' | '70';

interface UserAvatarProps {
  UID?: string;
  className?: string;
  imgClassName?: string;
  round?: boolean;
  size?: UserAvatarSize;
  style?: CSSProperties;
  user?: UserWithProfile;
  showUserStatusType?: boolean;
}

const UserAvatar: FC<UserAvatarProps> = ({
  UID,
  round = true,
  size = '18',
  user: propUser,
  showUserStatusType = false,
  ...props
}) => {
  const { t } = useTranslation();
  const stateUser = useAppSelector((state) => state.usersList.usersList.find((user) => user.UID === UID));
  const user = propUser || stateUser;
  const userReadinessStatus = useAppSelector((state) => selectCurrentReadinessStatus(state, stateUser?.UID));

  if (!user || !userReadinessStatus) {
    return <span>{t('ERROR_HAPPENED')}</span>;
  }

  const name = getProfileName(user);
  const { profileImage, avatarColor } = user?.profile || {};
  const hasImage = profileImage && !profileImage.includes('blank');
  const largeImage = parseInt(size) > 40;

  return hasImage && !avatarColor ? (
    <Image alt={name} circular={round} height={size} src={getUserImage(profileImage, largeImage)} />
  ) : (
    <div className="avatarWrapper">
      <Avatar name={name} round={round && '50px'} size={size} color={avatarColor} textSizeRatio={2} {...props} />
      {showUserStatusType && (
        <span
          className="userStatusTypeIndication"
          style={{ backgroundColor: userReadinessStatus === UserStatusType.READY ? 'green' : 'red' }}
        />
      )}
    </div>
  );
};

export default UserAvatar;
