import { unwrapResult } from '@reduxjs/toolkit';

import type { History } from 'history';

import HolviWebsocketIntegration from '../Utilities/intergrations/HolviWebsocketIntegration';
import { addComment } from 'src/actions/commentsActions';
import {
  addEntityToCase,
  addTicket,
  createContent,
  openTicketTab,
  searchCustomerByDetail,
  updateTicket,
  updateTicketCaseDetails
} from 'src/actions/ticketsActions';
import EnvSettings from 'src/api/EnvSettings';
import FeatureFlags from 'src/api/FeatureFlags';
import TicketsApi from 'src/api/TicketsApi';
import openExternalLink from 'src/handlers/openExternalLink';
import { history } from 'src/reducers/browserHistory';
import { StaticTabs } from 'src/types/TicketList';
import { startWorkingOn } from 'src/Utilities/workStatusParser';

import type { State } from 'src/types/initialState';
import type { ThunkAppDispatch } from 'src/types/store';
import type { Entity, Ticket, TicketListTicket } from 'src/types/Ticket';

export interface UpdateDetailsObject {
  updateKey: string;
  updateValue: any;
  group: string;
}

export interface IntegrationData {
  normalizedPhoneNumber: string;
  ticketData: Ticket;
  UID: string;
  searchObject: object;
  detailsObjects?: UpdateDetailsObject[];
  history: History;
  direction?: 'in' | 'out';
  integrationPayload?: {
    foreignId: string;
    foreignIdType: string;
    QueueName?: string;
    QueueId?: string;
    CallId?: string;
  };
}

export enum PhoneIntegrationType {
  MITEL = 'Mitel',
  ELISA_RING = 'ElisaRing',
  ELISA_OC = 'ElisaOC',
  ENREACH_VOICE = 'EnreachVoice',
  GENERIC_LINK = 'GenericLink'
}

export const findExistingTicket = async ({
  direction,
  normalizedPhoneNumber,
  taskType,
  tickets
}: {
  direction?: 'in' | 'out';
  normalizedPhoneNumber: string;
  taskType: string;
  tickets: TicketListTicket[];
}) => {
  const { taskStatus, sameDirection, sameTicketType } = EnvSettings.getSettings().CALLS_TO_SAME_TICKET!.data;

  if (!FeatureFlags.isFlagOn('CALLS_TO_SAME_TICKET')) {
    return;
  }

  return tickets.find(
    (ticket) =>
      ticket.originalContact === normalizedPhoneNumber &&
      ticket.status &&
      (taskStatus ?? ['todo', 'doing']).includes(ticket.status) &&
      (sameDirection ? ticket.originalDirection === direction : true) &&
      (sameTicketType ? ticket.taskType === taskType : true)
  );
};

export const handleIncomingPhoneCall = (integrationData: IntegrationData) => {
  return async (dispatch: ThunkAppDispatch, getState: () => State): Promise<string> => {
    const { normalizedPhoneNumber, ticketData, searchObject, UID, direction, integrationPayload } = integrationData;
    ticketData.originalContact = normalizedPhoneNumber;
    const { userData, ticketTypes, ticketListTabs } = getState();
    const { tickets } = ticketListTabs[StaticTabs.MAIN_VIEW];

    let ticket = (await findExistingTicket({
      direction,
      normalizedPhoneNumber,
      taskType: ticketData.taskType,
      tickets
    })) as Ticket;
    const ticketAlreadyExists = !!ticket;

    if (ticket) {
      await dispatch(updateTicket({ id: ticket.id, ticket: { status: 'doing' } }));
    } else {
      const resultAction = await dispatch(createContent({ ticket: ticketData }));
      ticket = unwrapResult(resultAction);
    }

    if (ticket && ticket.id !== 'new_oc') {
      // now with ticket data create some tabs
      dispatch(addTicket(ticket));
      await dispatch(openTicketTab({ contentId: ticket.id, contentType: 'tickets' }));
      await TicketsApi.startWorkingOn(...startWorkingOn(UID, ticket.id));

      await dispatch(
        addComment(ticket.id, {
          content: `[original_phonenumber:${normalizedPhoneNumber}]`,
          direction: direction,
          ...(integrationPayload && {
            foreignId: integrationPayload.foreignId,
            foreignIdType: integrationPayload.foreignIdType
          })
        })
      );

      if (integrationData.detailsObjects) {
        const detailsObjects = integrationData.detailsObjects;
        dispatch(
          updateTicketCaseDetails(
            ticket.id,
            'CaseDetails',
            detailsObjects.map((detailsObject) => ({
              type: detailsObject.updateKey,
              value: detailsObject.updateValue
            }))
          )
        );
      }

      // search for entity
      const entityResults: Entity[] = await dispatch(
        searchCustomerByDetail({
          ...searchObject,
          taskType: ticketData.taskType
        })
      );

      if (!ticketAlreadyExists) {
        if (entityResults.length === 0) {
          // add entity based on originalNumber, ticketType etc.
          dispatch(
            addEntityToCase(ticket.id, {
              taskType: ticketData.taskType,
              ...searchObject
            })
          );
        } else {
          // add entity to case
          dispatch(
            addEntityToCase(ticket.id, {
              _id: entityResults[0]._id,
              taskType: ticket.taskType,
              _type: entityResults[0]._type
            })
          );
        }
      }

      if (entityResults.length > 1) {
        // add comment multiple customers
        dispatch(
          addComment(ticket.id, {
            content: '[had_multiple_entities]'
          })
        );
      }

      if (FeatureFlags.isFlagOn('OPEN_EXTERNAL_LINK_ON_PHONE_CALL')) {
        dispatch(
          openExternalLink({
            userData,
            ticketTypes,
            task: ticket,
            entity: entityResults[0] || searchObject,
            normalizedPhoneNumber
          })
        );
      }

      if (HolviWebsocketIntegration.isConnected) {
        HolviWebsocketIntegration.sendMessage({
          AgentId: UID,
          AgentName: `${userData.profile.firstName} ${userData.profile.lastName}`,
          CallerNumberUnRestricted: normalizedPhoneNumber,
          CallId: integrationPayload?.foreignId,
          QueueId: integrationPayload?.QueueId,
          QueueName: integrationPayload?.QueueName
        });
      }

      return ticket.id;
    } else {
      history.push('/');
      return Promise.reject('no ticket');
    }
  };
};
