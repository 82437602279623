import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';

import type { TabFilter } from '@eeedo/types';
import type { FC } from 'react';
import type { ConnectedProps } from 'react-redux';

import SingleLineTicketViewContent from './SingleLineTicketViewContent';
import { fetchInfoPages } from 'src/actions/infoPagesActions';
import { openSelectedTasks } from 'src/actions/singleLineViewActions';
import { StaticTabs } from 'src/types/TicketList';
import { orderInfoPageList } from 'src/Utilities/infoPageList';
import { filterTicketList } from 'src/Utilities/ticketList';

import type { AccordionTab } from './SingleLineTicketViewContent';
import type { State } from 'src/types/initialState';
import type { TicketListTicket } from 'src/types/Ticket';

import './SingleLineTicketView.css';
import { useAppSelector } from 'src/store';

type SingleLineTicketViewInfopagesProps = ConnectedProps<typeof connector>;

const SingleLineTicketViewInfopages: FC<SingleLineTicketViewInfopagesProps> = ({
  ticketListTab,
  categories,
  personalData,
  usersList
}) => {
  const contentType = 'infopages';

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filters = ticketListTab?.filters ?? ({} as TabFilter);
  const { sorting, direction } = ticketListTab || {};

  const orderedTickets = orderInfoPageList(ticketListTab?.tickets || ([] as any), sorting as any, direction as any);

  const { contentStatuses } = useAppSelector((state) => ({
    contentStatuses: state.contentStatusOptions.all.filter((option) => option.type === 'infopage' && !option.disabled)
  }));

  useEffect(() => {
    dispatch(fetchInfoPages({ id: StaticTabs.MAIN_VIEW, throughSearchTab: false }));
  }, []);

  const filterArgs = {
    categories,
    personalData,
    filters,
    usersList
  };

  const getTickets = (status: string): TicketListTicket[] => {
    const tickets = orderedTickets.filter((ticket) => ticket.status === status)

    return filterTicketList({
      ...filterArgs,
      status,
      tickets
    });
  }

  const accordionTabs: AccordionTab[] = useMemo(
    () => contentStatuses.map(({ name, value }, index) => ({
      index,
      title: t(name),
      status: value,
      tickets: getTickets(value),
    })),
    [contentStatuses, orderedTickets]
  );

  const onOpenInfopages = () => {
    dispatch(openSelectedTasks({ contentType }));
  };

  const dropdownOptions = useMemo(
    () => [
      {
        text: t('olt_actions.open'),
        value: 'open',
        onClick: onOpenInfopages
      },
      { text: 'Move to Waste', value: 'waste' },
      { text: 'Publish', value: 'publish' }
    ],
    [onOpenInfopages, t]
  );

  return (
    <SingleLineTicketViewContent
      accordionTabs={accordionTabs}
      contentType={contentType}
      dropdownOptions={dropdownOptions}
      orderedTickets={orderedTickets}
    />
  );
};

const connector = connect((state: State) => {
  const activeTab = Array.from(state.infoPageListTabs.values()).find((tab) => tab.activeTab);

  return {
    ticketListTab: state.infoPageListTabs.get(activeTab?.id ?? StaticTabs.MAIN_VIEW),
    usersList: state.usersList.usersList,
    categories: state.categories,
    personalData: state.userData,
    ticketTypes: state.ticketTypes,
    channels: state.channels,
    priorities: state.ticketPriorities
  };
});

export default connector(SingleLineTicketViewInfopages);
