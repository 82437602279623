import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { DropdownItem, Label } from 'semantic-ui-react';

import type { FC, ReactNode } from 'react';

import type { TicketSearch } from './types';
import type { CommandPrompt } from './useCommands';

interface Props {
  ticket?: TicketSearch;
  promptOption?: CommandPrompt;
  searchText?: string;
  icon?: ReactNode;
  label?: string;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const SearchOmnibarItem: FC<Props> = ({ icon, label, onClick, promptOption, searchText, ticket }) => {
  if (ticket) {
    return (
      <DropdownItem onClick={onClick}>
        <Label content={ticket.id} />
        {ticket.title}
      </DropdownItem>
    );
  }

  if (promptOption) {
    return (
      <DropdownItem onClick={onClick}>
        <Label content=">" />
        {promptOption.text}
        <div style={{ float: 'right' }}>
          {promptOption.shortcut && (
            <span style={{ float: 'right' }}>
              {promptOption.shortcut.split(',').map((k) => (
                <Label className="command-palette-list-label-border" content={k} key={k} />
              ))}
            </span>
          )}
        </div>
      </DropdownItem>
    );
  }

  if (searchText) {
    return (
      <DropdownItem onClick={onClick}>
        {icon ?? <FontAwesomeIcon icon={faMagnifyingGlass} className="icon" />}
        {label}: <strong>{searchText}</strong>
      </DropdownItem>
    );
  }

  return null;
};

export default SearchOmnibarItem;
