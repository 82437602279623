export const TicketListTitleContainer: React.CSSProperties = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start'
};

export const TicketListTitle: React.CSSProperties = {
  fontSize: '15px',
  fontWeight: '600',
  lineHeight: '24px'
};

export const TicketListTitleCount: React.CSSProperties = {
  marginLeft: '8px'
};
