import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import type { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import type { FC } from 'react';

import Button from 'src/Components/generic/Button/Button';
import Popup from 'src/Components/generic/Popup/Popup';

interface Props {
  active?: boolean;
  selected: boolean;
  disabled?: boolean;
  icon: IconDefinition;
  onClick: () => void;
  title: string;
}

const TicketListHeaderIcon: FC<Props> = ({ active, selected, disabled, icon, title, onClick }) => (
  <Popup
    content={title}
    delay={500}
    on="hover"
    position="bottom center"
    trigger={
      <Button
        active={selected}
        borderless
        disabled={disabled}
        icon={<FontAwesomeIcon icon={icon} color={active ? 'red' : undefined} />}
        onClick={onClick}
        style={{ marginLeft: 'auto' }}
      />
    }
  />
);

export default TicketListHeaderIcon;
