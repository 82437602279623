import {
  faArrowTurnUp,
  faCircleCheck,
  faCirclePlay,
  faCircleStop,
  faHourglassStart,
  faRightLeft
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { FC } from 'react';
import type { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';

import FeatureFlags from 'src/api/FeatureFlags';
import FloatingDropdown from 'src/Components/generic/FloatingDropdown/FloatingDropdown';
import { selectActiveTicketWorkStatus } from 'src/selectors/rootStateSelectors';
import { useAppSelector } from 'src/store';

import type { ButtonType } from 'src/Components/generic/Button/Button';
import type { DropdownColor } from 'src/Components/generic/Dropdown/Dropdown';
import type { StatusTypes } from 'src/types/Ticket';

import './TopBarWorkStatusButtons.css';

interface Props {
  isActiveCloseAs: (status: StatusTypes) => boolean;
  onCloseAndReturnAsDoing: (ticketId: string, UID: string) => void;
  onCloseAsDone: (UID: string) => void;
  onReplaceWorking: (oldUID: string, newUID: string) => void;
  onReturnAsNew: (ticketId: string, UID: string) => void;
  onStartWorking: (UID: string) => void;
  onStopWorking: (UID: string) => void;
  ticketId: string;
}

const TopBarWorkStatusButtons: FC<Props> = ({
  isActiveCloseAs,
  onCloseAndReturnAsDoing,
  onCloseAsDone,
  onReplaceWorking,
  onReturnAsNew,
  onStartWorking,
  onStopWorking,
  ticketId
}) => {
  const [t] = useTranslation();
  const { UID } = useAppSelector((state) => state.userData);
  const workStatus = useAppSelector(selectActiveTicketWorkStatus);
  const workStatusUserId = workStatus?.UID ? parseInt(workStatus.UID) : null;
  const userId = parseInt(UID.replace('USR', ''), 10);

  const onChangeDropdown = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const selectedOption = data.options?.find(({ value }) => value === data.value);
    if (selectedOption?.onClick) {
      (selectedOption.onClick as () => void)();
    }
  };

  const options = [
    {
      value: 'startWorking',
      icon: <FontAwesomeIcon icon={faCirclePlay} />,
      text: t('LIVE_ADD_TO_UNDER_WORKING'),
      isActive: !workStatusUserId,
      onClick: () => onStartWorking(UID),
      type: 'positive',
      color: 'green'
    },
    {
      value: 'replaceWorking',
      icon: <FontAwesomeIcon icon={faRightLeft} />,
      text: t('LIVE_REPLACE_FROM_UNDER_WORKING'),
      isActive: !!workStatusUserId && workStatusUserId !== userId,
      onClick: () => workStatus?.UID && onReplaceWorking(workStatus?.UID, UID)
    },
    {
      value: 'closeAsDone',
      icon: <FontAwesomeIcon icon={faCircleCheck} />,
      text: t('CLOSE_AS_DONE'),
      isActive: isActiveCloseAs('done'),
      onClick: () => onCloseAsDone(UID)
    },
    {
      value: 'stopWorking',
      icon: <FontAwesomeIcon icon={faCircleStop} />,
      text: t('LIVE_REMOVE_FROM_UNDER_WORKING'),
      isActive: !!workStatusUserId && workStatusUserId === userId,
      onClick: () => onStopWorking(UID)
    },
    {
      value: 'closeAndChangeToDoing',
      icon: <FontAwesomeIcon icon={faHourglassStart} />,
      isActive: FeatureFlags.isFlagOn('ENABLE_CHANGE_STATUS_TO_DOING') && isActiveCloseAs('doing'),
      text: t('CASE_STATUS_CLOSE_AND_CHANGE_TO_DOING'),
      onClick: () => onCloseAndReturnAsDoing(ticketId, UID)
    },
    {
      value: 'returnAsNew',
      icon: <FontAwesomeIcon icon={faArrowTurnUp} />,
      isActive: FeatureFlags.isFlagOn('ENABLE_RETURN_AS_NEW') && isActiveCloseAs('todo'),
      text: t('RETURN_AS_NEW'),
      onClick: () => onReturnAsNew(ticketId, UID)
    }
  ].filter(({ isActive }) => isActive);

  const buttonOption = options[0];
  const dropdownOptions = options.slice(1);

  return (
    <div className="topbarWorkStatusButtons">
      <FloatingDropdown
        buttonGroupProps={{
          fluid: true
        }}
        buttonProps={{
          iconLeft: buttonOption.icon,
          content: buttonOption.text,
          onClick: buttonOption.onClick,
          type: (buttonOption.type as ButtonType) ?? 'primary',
          center: true
        }}
        dropdownProps={{
          icon: 'chevron down',
          color: (buttonOption.color as DropdownColor) ?? 'dark_violet',
          button: true,
          onChange: onChangeDropdown,
          value: ''
        }}
        options={dropdownOptions}
      />
    </div>
  );
};

export default TopBarWorkStatusButtons;
