import type { CSSProperties } from 'react';

export const tableWrapper: CSSProperties = { overflowY: 'auto', flexGrow: 1, padding: '0 24px' };

export const headerCell: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  padding: '5px',
  color: 'rgba(112, 112, 112, 1)',
  fontWeight: 500,
  fontSize: '13px',
  lineHeight: '20px',
  textTransform: 'none'
};

export const columnCell: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  padding: '5px',
  height: '100%',
  color: 'rgba(65, 65, 65, 1)',
  fontWeight: 500,
  lineHeight: '20px',
  fontSize: '14px'
};

export const table: CSSProperties = { marginTop: 0, borderRadius: 0, height: '100%' };

export const channelIconWrapper: CSSProperties = { display: 'flex' };

export const priorityIconWrapper: CSSProperties = { display: 'flex' };
