import React, { useCallback } from 'react';

import type { ComponentProps, CSSProperties, FC } from 'react';
import { DEFAULT_KEY_PREFIX } from '@eeedo/types';

import { t } from 'i18next';

import Dropdown from 'src/Components/generic/Dropdown/Dropdown';
import { useAppSelector } from 'src/store';

import type { StatusTypes } from 'src/types/Ticket';
import { selectIsInfopage } from 'src/selectors/rootStateSelectors';

type OnChange = NonNullable<ComponentProps<typeof Dropdown>['onChange']>;

interface TicketStatusDropdownProps {
  value: string;
  loading?: boolean;
  disabled?: boolean;
  placeholder?: string;
  style?: CSSProperties;
  ticketType?: string;
  handleStatusChange: (value: string) => void;
}

const TicketStatusDropdown: FC<TicketStatusDropdownProps> = ({
  value,
  style,
  loading,
  disabled,
  ticketType,
  placeholder,
  handleStatusChange,
}) => {
  const onChange = useCallback<OnChange>((_, data) => {
    handleStatusChange(data.value!.toString() as StatusTypes);
  }, []);
  const isInfopage = useAppSelector(selectIsInfopage);
  const ticketTypename = isInfopage ? 'infopage' : 'task';

  const contentOptionsStatus = useAppSelector((state) => {
    const options = state.contentStatusOptions.byTicketType[`${ticketType}_${ticketTypename}`] || []
    const defaultOptions = state.contentStatusOptions.byTicketType[`${DEFAULT_KEY_PREFIX}_${ticketTypename}`] || []

    const combineOptions = [...options, ...defaultOptions]
      .sort((a, b) => a.position - b.position)
      .map(({ name, value, disabled, icon }) => ({ text: t(name, { defaultValue: name }), icon: icon || 'dot circle', value, disabled }))
    return combineOptions
  });

  return (
    <Dropdown
      id="ticketStatusDropdown"
      search
      selection
      style={style}
      value={value}
      loading={loading}
      disabled={disabled}
      selectOnBlur={false}
      options={contentOptionsStatus}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

export default TicketStatusDropdown;
