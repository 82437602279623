import React from 'react';
import Scrollbars from 'react-custom-scrollbars';

import type { TFunction } from 'i18next';
import type { SemanticICONS } from 'semantic-ui-react';

import TopBarStatusButtons from 'src/Components/Case/TopBar/TopBarStatusButtons';
import Widgets from 'src/Components/Case/Widget/Widgets';
import ErrorBoundary from 'src/ErrorBoundary';

export const taskStatusOptions: (
  t: TFunction,
  isInfopage: boolean
) => {
  text: string;
  icon: SemanticICONS;
  value: string;
}[] = (t, isInfopage) =>
    isInfopage
      ? [
        {
          text: t('INFOPAGE_STATUS_DRAFT'),
          icon: 'write',
          value: 'draft'
        },
        {
          value: 'inReview',
          icon: 'magnify',
          text: t('INFOPAGE_STATUS_INREVIEW')
        },
        {
          value: 'waitingToBePublished',
          icon: 'wait',
          text: t('INFOPAGE_STATUS_WAITING')
        },
        {
          value: 'published',
          icon: 'book',
          text: t('INFOPAGE_STATUS_PUBLISHED')
        },
        {
          value: 'waste',
          icon: 'trash',
          text: t('INFOPAGE_STATUS_WASTE')
        }
      ]
      : [
        {
          text: t('CASE_STATUS_TODO'),
          icon: 'exclamation',
          value: 'todo'
        },
        {
          text: t('CASE_STATUS_DOING'),
          icon: 'clock',
          value: 'doing'
        },
        {
          text: t('CASE_STATUS_DONE'),
          icon: 'check',
          value: 'done'
        }
      ];

export const DesktopLayoutRight = () => {
  return (
    <div id="topelement">
      <div className="case-right-side">
        <TopBarStatusButtons contentType="task" />

        <Scrollbars autoHide>
          <div className="case-widgets">
            <ErrorBoundary>
              <Widgets />
            </ErrorBoundary>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};
