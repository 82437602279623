import type { ContentStatusOptions } from '@eeedo/types';

import { apiClient } from 'src/Utilities/httpClients';

interface ApiContentStatusOptions extends Pick<ContentStatusOptions, 'type' | 'name'> {
  ticketType: number[];
}

export default class ContentStatusOptionsApi {
  static getContentStatusOptions = async (): Promise<ContentStatusOptions[]> => {
    return apiClient.get('/contentStatusOptions').then((response) => response.data);
  };

  static patchContentStatusOptions = async (data: {
    id: number;
    payload: Partial<ApiContentStatusOptions>;
  }): Promise<ContentStatusOptions> => {
    return apiClient.patch('/contentStatusOptions/' + data.id, data.payload).then((response) => response.data);
  };

  static deleteContentStatusOptions = async (id: number, ticketStatus?: string) => {
    return apiClient
      .delete('/contentStatusOptions/' + id, { data: { ticketStatus } })
      .then((response) => response.data);
  };

  static toggleContentStatusOptions = async (id: number) => {
    return apiClient.patch('/contentStatusOptions/toggle/' + id).then((response) => response.data);
  };

  static createContentStatusOptions = async (data: ApiContentStatusOptions) => {
    return apiClient.post('/contentStatusOptions/', data).then((response) => response.data);
  };

  static changeOrderContentStatusOptions = async (data: Pick<ContentStatusOptions, 'id' | 'position'>[]) => {
    return apiClient.patch('/contentStatusOptions/position', data).then((response) => response.data);
  };
}
