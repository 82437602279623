import { DefaultTicketsOrdering } from '@eeedo/types';
import {
  faArrowRotateRight,
  faFilter,
  faMagnifyingGlass,
  faSpinnerScale,
  faTableList
} from '@fortawesome/pro-regular-svg-icons';
import {
  faFilter as faFilterSolid,
  faMagnifyingGlass as faMagnifyingGlassSolid
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import type { FC } from 'react';

import TicketListDropdown from './TicketListDropdown';
import * as styles from './TicketListHeader.style';
import TicketListHeaderIcon from './TicketListHeaderIcon';
import useRefreshTicketList from './useRefreshTicketList';
import { activateTicketlistTab } from 'src/actions/CaseListActions';
import FeatureFlags from 'src/api/FeatureFlags';
import Section from 'src/Components/generic/Section/Section';
import { setTicketListFilterOpen, setTicketListSearchOpen } from 'src/reducers/ticketListTabToolsReducer';
import {
  selectActiveTicketListTab,
  selectIsInfopage,
  selectIsSingleLineView,
  selectTicketListTabs,
  selectTicketListTabTools
} from 'src/selectors/rootStateSelectors';
import { useAppSelector } from 'src/store';
import { StaticTabs } from 'src/types/TicketList';
import { SINGLE_LINE_VIEW_INFOPAGES_PATH, SINGLE_LINE_VIEW_TICKETS_PATH } from 'src/Utilities/constants';

const [defaultDirection, defaultSorting] = DefaultTicketsOrdering;

const TicketListHeader: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ticketListTabs = useAppSelector(selectTicketListTabs);
  const activeTicketListTab = useAppSelector(selectActiveTicketListTab);
  const isSingleLineView = useAppSelector(selectIsSingleLineView);
  const isInfopage = useAppSelector(selectIsInfopage);
  const { isFilterOpen, isSearchOpen } = useAppSelector(selectTicketListTabTools);
  const [refreshLock, loading, onRefresh] = useRefreshTicketList();
  const history = useHistory();

  const { filters, sorting, direction, searchCriteria } = activeTicketListTab ?? {};
  const isFilterOn =
    !Object.keys(filters ?? {}).every((k) => !filters?.[k]) ||
    (sorting && sorting !== defaultSorting) ||
    (direction && direction !== defaultDirection);
  const isSearchOn = !!searchCriteria?.length;

  const toggleSingleLineView = useCallback(() => {
    isSingleLineView
      ? history.goBack()
      : history.push(isInfopage ? SINGLE_LINE_VIEW_INFOPAGES_PATH : SINGLE_LINE_VIEW_TICKETS_PATH);
    dispatch(setTicketListFilterOpen(!isSingleLineView));
  }, [isSingleLineView, isInfopage, dispatch]);

  const onClickSearch = () => {
    const searchTab = ticketListTabs.find((tab) => tab.id !== StaticTabs.MAIN_VIEW);

    if (!FeatureFlags.isFlagOn('ENABLE_MULTIPLE_TAB_SEARCH') && searchTab?.id && !isSearchOpen) {
      dispatch(activateTicketlistTab(searchTab.id));
    }

    dispatch(setTicketListSearchOpen(!isSearchOpen));
  };

  const onClickFilter = () => {
    dispatch(setTicketListFilterOpen(!isFilterOpen));
  };

  return (
    <Section direction="row" gap={4} padding="0 8px" margin="0 0 10px 0">
      <TicketListDropdown isSingleLineView={isSingleLineView} toggleSingleLineView={toggleSingleLineView} />

      <div style={styles.TicketListIconsContainer}>
        <TicketListHeaderIcon
          active={isSearchOn}
          selected={isSearchOpen}
          icon={isSearchOn ? faMagnifyingGlassSolid : faMagnifyingGlass}
          onClick={onClickSearch}
          title={t('SEARCH')}
        />

        {FeatureFlags.isFlagOn('ENABLE_TICKET_LIST_FILTERING') && (
          <TicketListHeaderIcon
            active={isFilterOn}
            selected={isFilterOpen}
            icon={isFilterOn ? faFilterSolid : faFilter}
            onClick={onClickFilter}
            title={t('SEARCH_FILTERTERM')}
          />
        )}

        {!loading ? (
          <TicketListHeaderIcon
            selected={false}
            disabled={refreshLock}
            icon={faArrowRotateRight}
            onClick={onRefresh}
            title={t('REFRESH')}
          />
        ) : (
          <FontAwesomeIcon icon={faSpinnerScale} style={{ padding: '11px 11px' }} spinPulse />
        )}

        <TicketListHeaderIcon
          selected={isSingleLineView}
          icon={faTableList}
          onClick={toggleSingleLineView}
          title={t('TICKETLIST_TICKET_VIEW_LIST')}
        />
      </div>
    </Section>
  );
};

export default React.memo(TicketListHeader);
