import { cloneDeep } from 'lodash-es';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Header, Icon, Loader } from 'semantic-ui-react';

import type { SenderEmail } from '@eeedo/types';
import type { WithTranslation } from 'react-i18next';

import Comments from '../Comments/Comments';
import StickyWrapper from '../generic/Sticky/StickyWrapper';
import LoaderComponent from '../Loader/Loader';
import CaseContentEditor from './CaseContentEditor';
import CaseIFrameViewTabBar from './CaseIFrameViewTabBar';
import FeatureFlags from 'src/api/FeatureFlags';
import { contentsVC } from 'src/api/VersionControl';
import TopBarGeneralInfo from 'src/Components/Case/TopBar/TopBarGeneralInfo';
import TopBarStatusButtons from 'src/Components/Case/TopBar/TopBarStatusButtons';
import DraftsContainer from 'src/containers/DraftsContainer';
import ErrorBoundary from 'src/ErrorBoundary';
import { getUrlSearchParam } from 'src/Utilities/helper';
import { taskIdToNumericalId } from 'src/Utilities/ticketList';

import type { CaseHOCProps } from 'src/containers/CaseContainer';
import type { Ticket } from 'src/types/Ticket';

import './Case.css';

import { Scrollbars } from 'react-custom-scrollbars';

import ResizeDetectorContainer from '../generic/ResizeDetectorContainer';
import { CommentsContainerContext } from './CommentsContainerContext';
import { SHOULD_SHRINK_WIDTH } from './constants';

interface CaseProps extends CaseHOCProps, WithTranslation {
  task: Ticket;
  loading: boolean;
  iFrame: { url: string; activeTab: string; tabTitle: string } | undefined;
}

interface CaseState {
  isReplyHeaderPinned: boolean;
}

export class Case extends React.Component<CaseProps, CaseState> {
  static contextType = CommentsContainerContext;
  context: React.ContextType<typeof CommentsContainerContext>;

  private senderEmails: SenderEmail[];
  private replyContainer: React.RefObject<HTMLDivElement>;
  private replyHeaderRef = React.createRef<HTMLDivElement>();

  constructor(props: CaseProps) {
    super(props);

    this.state = { isReplyHeaderPinned: false };

    this.senderEmails = [];
    this.replyContainer = React.createRef<HTMLDivElement>();
  }

  componentWillReceiveProps(nextProps: CaseProps) {
    if (nextProps.task) {
      if (nextProps.ticketTypes?.length > 0 && !nextProps.task.entityFields) {
        this.senderEmails = [];
        nextProps.ticketTypes.forEach((element) => {
          const ticketType = cloneDeep(element);
          if (ticketType.senderemail) {
            ticketType.senderemail.type = ticketType.name;
            if (ticketType.name === nextProps.task.taskType) {
              ticketType.senderemail.default = true;
            }
            this.senderEmails.push(ticketType.senderemail);
          }
        });
      }
    }

    if (this.props.scrollInstantly) {
      this.props.falsifyInstantScroll();
      this.replyContainer?.current?.scrollIntoView({
        behavior: 'auto'
      });
    }
  }

  componentDidMount() {
    this.getTask(this.props.match.params.id);
  }

  componentDidUpdate(prevProps: CaseProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.getTask(this.props.match.params.id);
    }

    if (this.props.needToScroll !== prevProps.needToScroll && this.props.needToScroll) {
      if (!this.props.scrollInstantly) {
        this.replyContainer?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }

      this.props.falsifyScroll();
    }

    this.isReplyHeaderInViewport(-47);
  }

  private getTask = (id: string) => {
    this.props.openTicketTab(id);
    contentsVC.loadDraft({
      taskId: taskIdToNumericalId(id),
      attachments: []
    });
  };

  private isReplyHeaderInViewport = (offset = 0) => {
    if (!this.replyHeaderRef.current) return false;

    const top = this.replyHeaderRef.current!.getBoundingClientRect().top;

    // Additional conditions to prevent unnecessary state changes
    if (top + offset >= 0 && top - offset <= window.innerHeight) {
      if (this.state.isReplyHeaderPinned) {
        return this.setState({ isReplyHeaderPinned: false });
      }
    } else {
      if (!this.state.isReplyHeaderPinned) {
        return this.setState({ isReplyHeaderPinned: true });
      }
    }
  };

  /*private parseComment = (comment: TicketComment | null) => {
    const htmlMetaData = comment?.metaData?.html;
    const source = htmlMetaData || comment?.content || '';
    const nToBr = !htmlMetaData;
    const attachments = this.props.task?.attachments;
    return parseContent(source, attachments, nToBr);
  };*/

  private onCommentsContainerSizeChange = (width: number) => {
    const shouldShrink = width < SHOULD_SHRINK_WIDTH;
    const previous = this.context.shouldShrink;
    if (previous !== shouldShrink) {
      this.context.setShouldShrink(shouldShrink);
    }
  };

  caseView = () => {
    const { t } = this.props;
    // const lastExternalComment = getLastComment(this.props.task?.comments, true);
    // const parsedContent = this.parseComment(lastExternalComment);
    const isNavigationHidden = !!getUrlSearchParam('hideNavigation');

    return this.props.task ? (
      <ErrorBoundary>
        <div
          id={this.props.mobileMode ? 'mobileElement topelement' : 'topelement'}
          className={isNavigationHidden ? 'hiddenNavigation' : ''}
        >
          <Loader size="huge" active={this.props.loading}>
            {t('CASE_LOADING_CONTENT')}
          </Loader>
          <div
            className={this.props.mobileMode ? 'mobileElement detailViewScrollContainer' : 'detailViewScrollContainer'}
          >
            <TopBarGeneralInfo contentType="task" />

            <StickyWrapper className={this.props.mobileMode ? 'case-left-side-mobile' : 'case-left-side'}>
              <Scrollbars autoHide onScroll={() => this.isReplyHeaderInViewport(-43)}>
                <ResizeDetectorContainer onSizeChange={this.onCommentsContainerSizeChange}>
                  {this.props.mobileMode && (
                    <TopBarStatusButtons contentType="task" />
                  )}

                  {!FeatureFlags.isFlagOn('DISABLE_TASK_CONTENTS') && (
                    <div className="case-content-editor-wrapper">
                      {this.props.userData.permissions.includes('updateContent') && <CaseContentEditor />}
                    </div>
                  )}

                  <Comments
                    ticket={this.props.task}
                    senderEmails={this.senderEmails}
                    mobileMode={this.props.mobileMode}
                  />

                  {this.props.userData.permissions.includes('updateContent') && (
                    <div style={{ padding: '24px', borderTop: '1px solid rgba(17, 17, 17, 0.08)' }}>
                      <div ref={this.replyHeaderRef}></div>

                      {/* TODO: move to separate ReplyHeader component */}
                      <Header
                        as="h3"
                        style={{
                          display: 'flex',
                          padding: '7px 0',
                          margin: 0
                        }}
                        {...(this.state.isReplyHeaderPinned && {
                          style: {
                            display: 'flex',
                            position: 'sticky',
                            bottom: '-14px',
                            background: '#fff',
                            width: '100%',
                            padding: '7px 0',
                            borderTop: '1px solid rgba(0, 0, 0, .1)',
                            cursor: 'pointer',
                            margin: 0
                          },
                          onClick: () => {
                            if (this.state.isReplyHeaderPinned) {
                              this.replyHeaderRef.current?.scrollIntoView({ behavior: 'smooth' });
                            }
                          }
                        })}
                      >
                        <Icon name="talk" />
                        {t('CASE_ANSWER')}

                        {/*{this.props.task.channel === ChannelType.Email && lastExternalComment && (
                        <div
                          className="Comment-Content images-max-w-95"
                          style={{ marginLeft: 'auto', marginRight: '5px' }}
                        >
                          <ErrorBoundary>
                            <CommentEditorWidgetContainer
                              senderEmails={this.senderEmails}
                              created={lastExternalComment.created!}
                              title={lastExternalComment.title || ''}
                              comment={parsedContent}
                              taskId={this.props.task.id}
                              metaData={lastExternalComment.metaData}
                              isHTML={get(lastExternalComment, ['metaData', 'html'])}
                              attachmentIds={getCommentAttachmentsIds(lastExternalComment.metaData, this.props.task)}
                              buttonsType="primary"
                            />
                          </ErrorBoundary>
                        </div>
                      )}*/}
                      </Header>

                      <ErrorBoundary>
                        <div ref={this.replyContainer}>
                          <DraftsContainer />
                        </div>
                      </ErrorBoundary>
                    </div>
                  )}
                </ResizeDetectorContainer>
              </Scrollbars>
            </StickyWrapper>
          </div>
        </div>
      </ErrorBoundary>
    ) : (
      <LoaderComponent />
    );
  };

  render() {
    if (this.props.iFrame) {
      const taskId = taskIdToNumericalId(this.props.task?.id);
      return (
        <ErrorBoundary>
          <CaseIFrameViewTabBar
            caseTitle={this.props.task.id}
            frameTitle={`TSK${this.props.iFrame.tabTitle}`}
            taskId={taskId!}
            activeTab={this.props.iFrame.activeTab}
          />
          {this.props.iFrame.activeTab === 'case' ? (
            <this.caseView />
          ) : (
            <iframe src={this.props.iFrame.url} style={{ height: '84.5vh', width: '100%' }} frameBorder="0" />
          )}
        </ErrorBoundary>
      );
    }
    return <this.caseView />;
  }
}

export default withTranslation('translations')(Case);
