import { faMagnifyingGlass, faTrash, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import type { ButtonProps } from 'semantic-ui-react';

import { closeTicketlistTab } from 'src/actions/ticketListTabActionsRTK';
import Button from 'src/Components/generic/Button/Button';
import { StaticTabs } from 'src/types/TicketList';

interface SearchControlsProp {
  onSubmit(event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps): void;
  onClear(event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps): void;
  tabId?: string;
}

const SearchControls: React.FC<SearchControlsProp> = ({ tabId, onSubmit, onClear }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClickRemove = () => {
    if (tabId) {
      dispatch(closeTicketlistTab(tabId));
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <Button
        content={t('SEARCH')}
        iconLeft={<FontAwesomeIcon icon={faMagnifyingGlass} />}
        id="ticketFilterSearchBtn"
        onClick={onSubmit}
        type="normal"
      />
      <Button
        content={t('GENERAL_CLEAR')}
        icon={<FontAwesomeIcon icon={faXmark} />}
        id="ticketFilterSearchClearBtn"
        onClick={onClear}
        style={{ marginLeft: '8px' }}
        type="error"
      />
      {tabId && tabId !== StaticTabs.MAIN_VIEW && (
        <Button onClick={onClickRemove} style={{ marginLeft: 'auto' }} icon={<FontAwesomeIcon icon={faTrash} />} />
      )}
    </div>
  );
};

export default SearchControls;
