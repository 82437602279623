import { convertPrefixStringToNumber } from '@eeedo/utils';
import { faChevronDown, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { difference } from 'lodash-es';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { CurrentUserStatus, UserStatus, UserWithProfile } from '@eeedo/types';

import Button from '../generic/Button/Button';
import Dropdown from '../generic/Dropdown/Dropdown';
import ReadinessLabel from '../Management/UsersStatusManagement/ReadinessLabel/ReadinessLabel';
import ChannelsAvailabilityCell from './Cells/ChannelsAvailabilityCell';
import UserNameCell from './Cells/UserNameCell';
import Pagination from 'src/Components/generic/Pagination/Pagination';
import Table from 'src/Components/generic/Table/Table';
import { useAppSelector } from 'src/store';
import { Roles } from 'src/types/User';

import type { HeaderItem, RowItem } from 'src/Components/generic/Table/Table';

const paginationSize = 10;

const PerStatusTable = () => {
  const { t } = useTranslation();
  const [paginationPage, setPaginationPage] = useState<number>(1);
  const [filters, setFilters] = useState<{ ticketTypes?: number[] }>({ ticketTypes: undefined });
  const ticketTypes = useAppSelector((state) => state.ticketTypes);
  const users = useAppSelector((state) => state.usersList.usersList.filter((user) => !Roles.isBlocked(user.role.id)));
  const userStatuses = useAppSelector((state) => state.userStatusesState.userStatuses);
  const currentUserStatuses = useAppSelector((state) => state.userStatusesState.currentUserStatuses);

  const usersById = useMemo(() => {
    return users.reduce<Record<number, UserWithProfile>>(
      (acc, user) => ({ ...acc, [convertPrefixStringToNumber(user.UID)]: user }),
      {}
    );
  }, [users]);
  const [expandedRowId, setExpandedRowId] = useState<number | null>(null);
  const toggleRow = (id: number) => {
    setExpandedRowId(expandedRowId === id ? null : id);
  };

  const filteredCurrentStatusesByStatus = useMemo(() => {
    return Object.values(currentUserStatuses).reduce((acc, currentUserStatus) => {
      if (filters.ticketTypes?.length) {
        const user = usersById[currentUserStatus.UID];
        if (difference(filters.ticketTypes, user.ticketTypes).length !== 0) {
          return acc;
        }
      }
      const key = currentUserStatus.originalStatus;
      acc[key] = acc[key] ? [...acc[key], currentUserStatus] : [currentUserStatus];
      return acc;
    }, {} as Record<number, CurrentUserStatus[][number][]>);
  }, [currentUserStatuses, filters, usersById]);

  const getNestedRow = (userStatus: UserStatus, currentUserStatusesPerStatus: CurrentUserStatus[]) => {
    const nestedHeaders: HeaderItem[] = [
      { key: 'user', content: t('USER'), headerCellProps: { width: 2 } },
      { key: 'channels', content: t('SETTINGS_TITLE_ADMIN_MANAGE_CHANNELS'), headerCellProps: { width: 14 } }
    ];

    const nestedData = currentUserStatusesPerStatus.map((currentUserStatus) => {
      const user = usersById[currentUserStatus.UID];
      return {
        key: `nested-${currentUserStatus.id}`,
        cells: [UserNameCell(user), ChannelsAvailabilityCell(convertPrefixStringToNumber(user.UID))]
      };
    }) as RowItem[];

    const nestedTable = <Table headers={nestedHeaders} data={nestedData} rootTableProps={{ celled: true }} />;

    return {
      key: `expanded-${userStatus.id}`,
      cells: [
        {
          content: nestedTable,
          cellProps: { colSpan: 16 }
        }
      ],
      rowProps: { className: 'accordionRow' }
    };
  };

  const renderUserStatuses = useCallback(() => {
    const rows: RowItem[] = [];

    Object.values(userStatuses)
      .slice((paginationPage - 1) * paginationSize, paginationPage * paginationSize)
      .forEach((userStatus) => {
        const currentUserStatusesPerStatus = filteredCurrentStatusesByStatus[userStatus.id] || [];
        const usersPerStatus = currentUserStatusesPerStatus.length;

        const mainRow: RowItem = {
          key: `row-${userStatus.id}`,
          cells: [
            {
              content: usersPerStatus ? (
                <Button className="chevronButton" onClick={() => toggleRow(userStatus.id)}>
                  <FontAwesomeIcon icon={expandedRowId === userStatus.id ? faChevronDown : faChevronRight} />
                </Button>
              ) : null
            },
            {
              content: (
                <ReadinessLabel type={userStatus.type === 'ready' ? 'active' : 'inactive'} text={userStatus.text} />
              )
            },
            {
              content: <>{usersPerStatus}</>
            }
          ]
        };

        rows.push(mainRow);

        if (expandedRowId === userStatus.id) {
          rows.push(getNestedRow(userStatus, currentUserStatusesPerStatus));
        }
      });

    return rows;
  }, [userStatuses, currentUserStatuses, expandedRowId, filters, paginationPage]);

  return (
    <>
      <div className="availabilityFilters">
        <Dropdown
          selection
          search={true}
          multiple={true}
          clearable={true}
          placeholder={t('TICKET_TYPES')}
          onChange={(_, data) => setFilters((prevValue) => ({ ...prevValue, ticketTypes: data.value as number[] }))}
          options={ticketTypes.map((ticketType) => ({ text: ticketType.name, value: ticketType.id }))}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', clear: 'both' }}>
        <Table
          headers={[
            { key: 'actions', content: t('GENERAL_ACTIONS'), headerCellProps: { width: 1 } },
            { key: 'status', content: t('GENERAL_STATUS'), headerCellProps: { width: 2 } },
            { key: 'users', content: t('availability.table.users_amount'), headerCellProps: { width: 13 } }
          ]}
          stickyHeader={true}
          data={renderUserStatuses()}
          rootTableProps={{ celled: true, striped: true }}
        />

        <div style={{ margin: '10px auto 0' }}>
          <Pagination
            itemsLength={Object.keys(userStatuses).length}
            activePage={paginationPage}
            pageSize={paginationSize}
            onPageChange={(activePage) => setPaginationPage(activePage)}
          />
        </div>
      </div>
    </>
  );
};

export default PerStatusTable;
