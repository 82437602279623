import classNames from 'classnames';
import React, { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { DropdownDivider, DropdownItem, DropdownMenu, Dropdown as SDropdown } from 'semantic-ui-react';

import type { ComponentProps, FC } from 'react';

import Input from '../Input/Input';

import './Dropdown.css';

export type DropdownColor = 'dark_violet' | 'light_violet' | 'green' | 'white';

export interface DropdownProps extends ComponentProps<typeof SDropdown> {
  color?: DropdownColor;
  searchMenuEnabled?: boolean;
}

const Dropdown: FC<DropdownProps> = ({ color, searchMenuEnabled = false, children = null, ...props }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownClass = classNames(
    {
      [`color_${color}`]: !!color
    },
    props.className
  );

  return (
    <SDropdown {...props} className={dropdownClass}>
      {searchMenuEnabled ? (
        <DropdownMenu className="searchMenu">
          <Input
            icon="search"
            iconPosition="left"
            className="search"
            placeholder="Filter by name"
            onChange={(_, data) => setSearchTerm(data.value)}
          />

          <DropdownDivider />

          <Scrollbars
            autoHeight
            renderView={(props) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  marginBottom: '0px',
                  marginRight: '-11px',
                  overflowX: 'hidden',
                  maxHeight: '185px'
                }}
              />
            )}
            renderTrackHorizontal={(props) => <div {...props} style={{ display: 'none' }} />}
            renderThumbHorizontal={(props) => <div {...props} style={{ display: 'none' }} />}
          >
            {props.options
              ?.filter((option) => (option.text as string)?.toLowerCase().includes(searchTerm.toLowerCase()))
              .map((option) => (
                <DropdownItem style={{ cursor: 'pointer' }} {...option} />
              ))}
          </Scrollbars>
        </DropdownMenu>
      ) : (
        children
      )}
    </SDropdown>
  );
};

export default React.memo(Dropdown);
