import React, { useEffect, useState } from 'react';

import type { Direction, SortBy, TabFilter } from '@eeedo/types';
import type { FC } from 'react';

import Sorting from 'src/Components/Filter/Sorting';
import Filter from 'src/Components/ticketList/Filter';
import { filterComponent, mainControlsWrapper } from 'src/Components/ticketList/Filter.style';
import { useAppSelector } from 'src/store';

import type { LocalFiltersState } from 'src/Components/ticketList/Filter';
import type { IFilterItem } from 'src/types/Filter';
import type { ContentTypesFields } from 'src/types/Ticket';
import { useTranslation } from 'react-i18next';

export interface IProps {
  setFilter(args: IFilterItem): void;
  setSorting(sorting: SortBy, direction: Direction): void;
  contentType: ContentTypesFields;
  filters: TabFilter;
  sorting: SortBy;
  direction: Direction;
}

type FilterParams = 'title' | 'originalContact' | 'lastContactAddress';

const FilterPresetsFiltersEditor: FC<IProps> = (props) => {
  const type = props.contentType === 'tickets' ? 'task' : 'infopage';
  const { t } = useTranslation();
  const reduxProps = useAppSelector((state) => ({
    categories: state.categories,
    ticketTypesMetadata: state.ticketTypesMetadata,
    personalData: state.userData,
    ticketTypes: state.ticketTypes,
    channels: state.channels,
    tags: state.tags,
    usersList: state.usersList.usersList,
    priorities: state.ticketPriorities,
    statusOptions: state.contentStatusOptions.all?.filter((option) => option.type === type && !option.disabled)
      .map((option) => ({ text: t(option.name), value: option.value }))
  }));
  const getParam = (param: FilterParams) => {
    return props.filters[param];
  };

  const [localFiltersState, setLocalFiltersState] = useState<LocalFiltersState>({
    titleFilter: getParam('title'),
    originalContactFilter: getParam('originalContact'),
    lastContactAddressFilter: getParam('lastContactAddress')
  });

  const changeFilter = (value: any, type: keyof TabFilter) => {
    props.setFilter({
      id: '',
      parameter: type,
      value: value
    });
  };

  const onChangeFilter = (filter: FilterParams, prop: keyof LocalFiltersState, value: string) => {
    setLocalFiltersState({ ...localFiltersState, [prop]: value });
    props.setFilter({
      id: '',
      parameter: filter as keyof TabFilter,
      value: value
    });
  };

  useEffect(() => {
    setLocalFiltersState({
      titleFilter: getParam('title'),
      originalContactFilter: getParam('originalContact'),
      lastContactAddressFilter: getParam('lastContactAddress')
    });
  }, [props.filters.title, props.filters.originalContact, props.filters.lastContactAddress]);

  return (
    <div className="filterComponent" style={{ ...filterComponent(true), margin: 0 }}>
      {props.contentType === 'tickets' && (
        <div style={mainControlsWrapper}>
          <Sorting sorting={props.sorting} direction={props.direction} onChange={props.setSorting} />
        </div>
      )}
      <Filter
        ticketTypesMetadata={reduxProps.ticketTypesMetadata}
        categories={reduxProps.categories}
        personalData={reduxProps.personalData}
        ticketTypes={reduxProps.ticketTypes}
        channels={reduxProps.channels}
        tags={reduxProps.tags}
        usersList={reduxProps.usersList}
        priorities={reduxProps.priorities}
        contentType={props.contentType}
        localFiltersState={localFiltersState}
        filters={props.filters}
        statusOptions={reduxProps.statusOptions}
        prefixId="MODAL_"
        toggleExtraOptions={() => undefined}
        changeFilter={changeFilter}
        onChangeFilter={onChangeFilter}
        isExtraOptionsOpen
        asEditor
      />
    </div>
  );
};

export default FilterPresetsFiltersEditor;
