import { createReducer } from '@reduxjs/toolkit';

import { fetchEIdentificationsSuccess } from 'src/actions/eIdentificationActions';

import type { EIdentification } from '@eeedo/types/src/EIdentification';

type EIdentificationsState = {
  eIdentifications: { [key: string]: EIdentification[] };
};

const initialState: EIdentificationsState = {
  eIdentifications: {}
};

const eIdentificationReducer = createReducer(initialState.eIdentifications, (builder) => {
  builder.addCase(fetchEIdentificationsSuccess, (state, action) => {
    const { contentId, eIdentifications } = action.payload;
    state[contentId] = eIdentifications;
  });
});

export default eIdentificationReducer;
