import type { CurrentUserStatus, UserStatus, UserStatusLog } from '@eeedo/types';
import type { AxiosResponse } from 'axios';

import { apiClient } from 'src/Utilities/httpClients';

export type UserStatusPayload = Pick<UserStatus, 'active' | 'text' | 'type'>;

export default class UserStatusApi {
  static getUserStatuses = async (): Promise<UserStatus[]> =>
    apiClient.get('/userStatuses').then((response: AxiosResponse<UserStatus[]>) => response.data);

  static getCurrentUserStatuses = async (): Promise<CurrentUserStatus[]> =>
    apiClient.get('/currentUserStatuses').then((response: AxiosResponse<CurrentUserStatus[]>) => response.data);

  static getUserStatusLogs = async ({
    UID,
    take,
    idCursor
  }: {
    UID: number;
    take: number;
    idCursor?: number;
  }): Promise<UserStatusLog[]> => {
    return apiClient
      .get(`/userStatuses/logs/${UID}`, { params: { take, idCursor } })
      .then((response: AxiosResponse<UserStatusLog[]>) => response.data);
  };

  static changeCurrentUserStatus = async ({
    UID,
    originalStatus,
    description
  }: {
    UID: number;
    originalStatus: number;
    description?: string;
  }): Promise<CurrentUserStatus> =>
    apiClient
      .patch(`/currentUserStatuses/${UID}`, { originalStatus, description })
      .then((response: AxiosResponse<CurrentUserStatus>) => response.data);

  static updateUserStatus = async ({ id, payload }: { id: number; payload: Partial<UserStatusPayload> }) =>
    apiClient.patch(`/userStatuses/${id}`, payload).then((response: AxiosResponse<void>) => response.data);

  static createUserStatus = async (payload: UserStatusPayload) =>
    apiClient.post('/userStatuses', payload).then((response: AxiosResponse<void>) => response.data);
}
