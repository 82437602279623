import { createSlice } from '@reduxjs/toolkit';
import { isEqual } from 'lodash-es';

import type { UIDPreferences, UIDPreferencesData } from '@eeedo/types';
import type { PayloadAction } from '@reduxjs/toolkit';

type UserPreferencesState = UIDPreferencesData;

const initialState: UserPreferencesState = {
  isSingleWidgetView: { enable: false },
  isRightSidebarOpen: { enable: true }
};

const userPreferencesSlice = createSlice({
  name: 'userPreferences',
  initialState,
  reducers: {
    setPreference: (
      state,
      action: PayloadAction<{
        key: keyof UserPreferencesState;
        value: UIDPreferencesData[keyof UserPreferencesState];
      }>
    ) => {
      state[action.payload.key] = action.payload.value;
    },
    setPreferences: (state, action: PayloadAction<Pick<UIDPreferences, 'key' | 'value'>[]>) => {
      action.payload.length &&
        action.payload.forEach((item) => {
          state[item.key] = item.value;
        });
    },
    updatePreference: (state, action: PayloadAction<Pick<UIDPreferences, 'key' | 'value'>>) => {
      if (!isEqual(state[action.payload.key], action.payload.value)) {
        state[action.payload.key] = action.payload.value;
      }
    }
  }
});

export const { setPreference, setPreferences, updatePreference } = userPreferencesSlice.actions;

export default userPreferencesSlice.reducer;
