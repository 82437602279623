import { createSlice } from '@reduxjs/toolkit';

import type { AiFunction } from '@eeedo/types';
import type { PayloadAction } from '@reduxjs/toolkit';

import { createAiFunction, deleteAiFunction, fetchAiFunctions, updateAiFunction } from '../actions/aiFunctionAction';

export interface AiFunctionsState {
  data: AiFunction[];
  loading: boolean;
  error: string | null;
}

const initialState: AiFunctionsState = {
  data: [],
  loading: false,
  error: null
};

const aiFunctionsSlice = createSlice({
  name: 'aiFunctions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // FETCH
    builder
      .addCase(fetchAiFunctions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAiFunctions.fulfilled, (state, action: PayloadAction<AiFunction[]>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchAiFunctions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message || 'Failed to fetch AI functions';
      });

    // CREATE
    builder
      .addCase(createAiFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAiFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.data.push(action.payload);
      })
      .addCase(createAiFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message || 'Failed to create AI function';
      });

    // UPDATE
    builder
      .addCase(updateAiFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAiFunction.fulfilled, (state, action: PayloadAction<AiFunction>) => {
        state.loading = false;
        const updated = action.payload;
        const index = state.data.findIndex((f) => f.id === updated.id);
        if (index !== -1) {
          state.data[index] = updated;
        }
      })
      .addCase(updateAiFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message || 'Failed to update AI function';
      });

    // DELETE
    builder
      .addCase(deleteAiFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAiFunction.fulfilled, (state, action: PayloadAction<number>) => {
        state.loading = false;
        const deletedId = action.payload;
        state.data = state.data.filter((f) => f.id !== deletedId);
      })
      .addCase(deleteAiFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message || 'Failed to delete AI function';
      });
  }
});

export default aiFunctionsSlice.reducer;
