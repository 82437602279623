import { isEqual, uniqWith } from 'lodash-es';

import type { RpaRuleNodeDelaySimple, RpaRuleTrigger, Suggestion, TicketType } from '@eeedo/types';
import type { DropdownItemProps } from 'semantic-ui-react';

const rulesToAIFunctionConflictMapping: { [key: string]: string[] } = {
  delegation_added: ['comment.'],
  delegation_removed: ['comment.'],
  entity_attached: ['comment.'],
  comment: [],
  status_changed: ['comment.'],
  taskType_changed: ['comment.'],
  tag_added: ['comment.'],
  call_rescheduled: ['comment.']
};

export const validateAIFunctionPerRPAEvent = (aiFunction: Suggestion, trigger?: RpaRuleTrigger) => {
  return trigger && rulesToAIFunctionConflictMapping[trigger].some((conflict) => aiFunction.value.includes(conflict));
};

export const getEntityTypes = (ticketTypes: TicketType[]): DropdownItemProps[] => {
  const entityTypes: { text: string; value: any }[] = [];

  ticketTypes
    .filter((ticketType) => ticketType.entityRouting?.length)
    .forEach((ticketType) => {
      ticketType.entityRouting!.forEach((entityRouting) => {
        entityTypes.push({
          text: entityRouting.displayName,
          value: entityRouting.entityType
        });
      });
    });
  return uniqWith(entityTypes, isEqual);
};

export const getEntitiesFieldsOptions = (
  ticketTypes: TicketType[],
  entityType?: string
): [DropdownItemProps[], DropdownItemProps[]] => {
  let key = 0;

  const fieldSets = ticketTypes
    .filter(({ entityRouting }) => !entityType || entityRouting?.some((item) => item.entityType === entityType))
    .flatMap(({ fieldSets }) => fieldSets)
    .filter(({ group }) => group === 'entity');

  const entityFields: { text: string; value: any; key: number }[] = [];
  fieldSets
    .filter((fieldSet) => !entityType || !fieldSet.entityTypes?.includes(entityType))
    .forEach((fieldSet) => {
      fieldSet[fieldSet.id].forEach((field: { name: string; value: string }) => {
        entityFields.push({
          key: key++,
          text: `${fieldSet.displayName} - ${field.name}`,
          value: {
            name: fieldSet.displayName,
            field: field.value
          }
        });
      });
    });

  const searchableFields: { text: string; value: any; key: number }[] = [];
  fieldSets
    .filter((fieldSet) => !entityType || fieldSet.entityTypes?.includes(entityType))
    .forEach((fieldSet) => {
      fieldSet[fieldSet.id].forEach((field: { name: string; value: string; displayField?: string }) => {
        if (['emailField', 'phoneField'].includes(field.displayField ?? '')) {
          searchableFields.push({
            key: key++,
            text: `${fieldSet.displayName} - ${field.name}`,
            value: {
              name: fieldSet.displayName,
              field: field.value
            }
          });
        }
      });
    });

  return [entityFields, searchableFields];
};

export const getCaseDetailsFields = (ticketTypes: TicketType[]): DropdownItemProps[] => {
  const dropdownOptions: { text: string; value: any; key: number }[] = [];

  let key = 0;
  ticketTypes.forEach((ticketType) => {
    ticketType.fieldSets
      .filter(({ group }) => group === 'CaseDetails')
      .forEach((fieldSet) => {
        if (!fieldSet[fieldSet.id]) return;

        fieldSet[fieldSet.id].forEach((field: { name: string; value: string }) => {
          dropdownOptions.push({
            key: key++,
            text: `${fieldSet.displayName} - ${field.name}`,
            value: {
              _type: 'CaseDetails',
              field: field.value
            }
          });
        });
      });
  }, []);

  return dropdownOptions;
};

export const getNodeDelayMsValue = (nodeDelay: Partial<RpaRuleNodeDelaySimple>) => {
  return nodeDelay.delayMs ? nodeDelay.delayMs / 1000 : 0;
};
