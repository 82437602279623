export interface ContentStatusOptions<T = 'string'> {
  id: number;
  type: string;
  name: string;
  value: string;
  icon: string;
  editable: boolean;
  createdAt: string;
  createdBy: T;
  updatedAt: string;
  updatedBy: T;
  ticketType: {
    id: number;
    name: string;
  }[];
  disabled?: boolean;
  position: number;
  isDeleted: boolean;
}

export interface ContentStatusHistory {
  id: number;
  contentId: number;
  timestamp: string;
  UID: number;
  originalStatusId: number;
  newStatusId: number | null;
  duration: number | null;
  durationWorkingHours: number | null;
  workingHoursId: number | null;
}

export const DEFAULT_KEY_PREFIX = '__NO_TICKET_';
