import { convertPrefixStringToNumber } from '@eeedo/utils';
import { faChevronDown, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { TicketType, UserWithProfile } from '@eeedo/types';

import Button from '../generic/Button/Button';
import Input from '../generic/Input/Input';
import ReadinessLabel from '../Management/UsersStatusManagement/ReadinessLabel/ReadinessLabel';
import ChannelsAvailabilityCell from './Cells/ChannelsAvailabilityCell';
import UserNameCell from './Cells/UserNameCell';
import TimerRefresh from './TimerRefresh';
import Pagination from 'src/Components/generic/Pagination/Pagination';
import Table from 'src/Components/generic/Table/Table';
import { useAutoRefreshSelector } from 'src/hooks/useAutoRefreshSelector';
import { StaticTabs } from 'src/types/TicketList';
import { Roles } from 'src/types/User';

import type { HeaderItem, RowItem } from 'src/Components/generic/Table/Table';
import type { TicketListTicket } from 'src/types/Ticket';
import { useModal } from '../Modal/ModalContext';
import ChangeAvailabilityModal from '../UserStatus/ChangeAvailabilityModal';

const paginationSize = 10;

const PerTicketTypeTable = () => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const [paginationPage, setPaginationPage] = useState<number>(1);
  const [filters, setFilters] = useState<{ ticketTypeName?: string }>({
    ticketTypeName: undefined
  });
  const [tickets, refreshTickets] = useAutoRefreshSelector(
    (state) => state.ticketListTabs[StaticTabs.MAIN_VIEW].tickets
  );
  const [channels, refreshChannels] = useAutoRefreshSelector((state) =>
    state.channels.filter((channel) => channel.active)
  );
  const [onlineStatuses, refreshOnlineStatuses] = useAutoRefreshSelector((state) => state.onlineStatus);
  const [workStatuses, refreshWorkStatuses] = useAutoRefreshSelector((state) => state.workStatus.status);
  const [users, refreshUsers] = useAutoRefreshSelector((state) =>
    state.usersList.usersList.filter((user) => !Roles.isBlocked(user.role.id))
  );
  const [ticketTypes, refreshTicketTypes] = useAutoRefreshSelector((state) => state.ticketTypes);
  const [userStatuses, refreShuserStatuses] = useAutoRefreshSelector((state) => state.userStatusesState.userStatuses);
  const [currentUserStatuses, refreshCurrentUserStatuses] = useAutoRefreshSelector(
    (state) => state.userStatusesState.currentUserStatuses
  );
  const [channelsAvailability, refreshChannelsAvailability] = useAutoRefreshSelector(
    (state) => state.channelsAvailability
  );
  const [expandedRowId, setExpandedRowId] = useState<number | null>(null);

  const refreshData = useCallback(() => {
    refreshTickets();
    refreshChannels();
    refreshWorkStatuses();
    refreshOnlineStatuses();
    refreshTicketTypes();
    refreshUsers();
    refreShuserStatuses();
    refreshCurrentUserStatuses();
    refreshChannelsAvailability();
  }, []);

  const toggleRow = (id: number) => {
    setExpandedRowId(expandedRowId === id ? null : id);
  };

  const usersByTicketType = useMemo(() => {
    return ticketTypes.reduce((acc, ticketType) => {
      const filteredUsers = users.filter((user) => user.ticketTypes.includes(ticketType.id));
      const usersWithOnlineStatus = filteredUsers.map((user) => {
        const UID = convertPrefixStringToNumber(user.UID);
        const isStatusReady = userStatuses[currentUserStatuses[UID].originalStatus].type === 'ready';
        const isConnected = onlineStatuses[UID] === 'online';
        const isOnline = isStatusReady && isConnected;

        return { user, isOnline };
      });

      acc[ticketType.id] = usersWithOnlineStatus;
      return acc;
    }, {} as Record<number, { user: UserWithProfile; isOnline: boolean }[]>);
  }, [users, userStatuses, currentUserStatuses]);

  const ticketsByTicketType = useMemo(() => {
    return tickets.reduce((acc, ticket) => {
      const currentTickets = acc[ticket.taskType] || [];
      return {
        ...acc,
        [ticket.taskType]: [...currentTickets, ticket]
      };
    }, {} as Record<string, TicketListTicket[]>);
  }, [tickets]);

  const workingOnTicketsByTicketType = useMemo(() => {
    return tickets.reduce((acc, ticket) => {
      const currentTickets = acc[ticket.taskType] || [];
      if (workStatuses.find((workStatus) => parseInt(workStatus.ticketId) === convertPrefixStringToNumber(ticket.id))) {
        return {
          ...acc,
          [ticket.taskType]: [...currentTickets, ticket]
        };
      }

      return acc;
    }, {} as Record<number, TicketListTicket[]>);
  }, [tickets, workStatuses]);

  const getNestedRow = (ticketType: TicketType, availableUsers: UserWithProfile[]) => {
    const nestedHeaders: HeaderItem[] = [
      { key: 'user', content: t('USER'), headerCellProps: { width: 3 } },
      { key: 'status', content: t('userStatusManagement.labels.user_status'), headerCellProps: { width: 2 } },
      { key: 'channels', content: t('SETTINGS_TITLE_ADMIN_MANAGE_CHANNELS'), headerCellProps: { width: 11 } }
    ];

    const nestedData = availableUsers.map((user) => {
      const UID = convertPrefixStringToNumber(user.UID);
      const currentUserStatus = currentUserStatuses[UID];
      const userStatus = userStatuses[currentUserStatus.originalStatus];

      return {
        key: `nested-${UID}`,
        cells: [
          UserNameCell(user),
          {
            content: (
              <ReadinessLabel
                type={userStatus.type === 'ready' ? 'active' : 'inactive'}
                text={currentUserStatus.description || userStatus.text}
                onClick={() => openModal(<ChangeAvailabilityModal UID={UID} />, 'small')}
              />
            )
          },
          ChannelsAvailabilityCell(UID)
        ]
      };
    }) as RowItem[];

    const nestedTable = <Table headers={nestedHeaders} data={nestedData} rootTableProps={{ celled: true }} />;

    return {
      key: `expanded-${ticketType.id}`,
      cells: [
        {
          content: nestedTable,
          cellProps: { colSpan: channels.length + defaultHeaders.length } // Full width,
        }
      ],
      rowProps: { className: 'accordionRow' }
    };
  };

  const renderChannels = useCallback(() => {
    const rows: RowItem[] = [];

    const filteredTicketTypes = ticketTypes.filter((ticketType) => {
      if (filters.ticketTypeName) {
        return ticketType.name.toLowerCase().includes(filters.ticketTypeName.toLowerCase());
      }
      return true;
    });

    filteredTicketTypes
      .slice((paginationPage - 1) * paginationSize, paginationPage * paginationSize)
      .forEach((ticketType) => {
        const detectedUsers = usersByTicketType[ticketType.id] || [];
        const todoTicketsAmount =
          ticketsByTicketType[ticketType.name]?.filter((ticket) => ticket.status === 'todo').length || 0;
        const workingOnTicketsAmount = workingOnTicketsByTicketType[ticketType.name]?.length || 0;
        const onlineUsersPerTicketType = usersByTicketType[ticketType.id]?.filter((record) => !!record.isOnline);

        const mainRow: RowItem = {
          key: `row-${ticketType.id}`,
          cells: [
            {
              content: detectedUsers.length ? (
                <Button className="chevronButton" onClick={() => toggleRow(ticketType.id)}>
                  <FontAwesomeIcon icon={expandedRowId === ticketType.id ? faChevronDown : faChevronRight} />
                </Button>
              ) : null,
              cellProps: { style: { position: 'sticky', left: 0 } }
            },
            {
              content: <>{ticketType.name}</>,
              cellProps: { style: { position: 'sticky', left: 0 } }
            },
            {
              content: <>{todoTicketsAmount}</>,
              cellProps: { style: { textAlign: 'center' } }
            },
            {
              content: <>{workingOnTicketsAmount}</>,
              cellProps: { style: { textAlign: 'center' } }
            },
            {
              content: <>{onlineUsersPerTicketType.length}</>,
              cellProps: { style: { textAlign: 'center' } }
            },
            {
              content: <>{detectedUsers.length - onlineUsersPerTicketType.length}</>,
              cellProps: { style: { textAlign: 'center' } }
            },
            ...channels.map((channel) => ({
              content: (
                <>{ticketsByTicketType[ticketType.name]?.filter((ticket) => ticket.channel === channel.id)?.length}</>
              ),
              cellProps: { style: { textAlign: 'center' } }
            }))
          ]
        };

        rows.push(mainRow);

        if (expandedRowId === ticketType.id) {
          rows.push(
            getNestedRow(
              ticketType,
              detectedUsers.map((record) => record.user)
            )
          );
        }
      });

    return rows;
  }, [userStatuses, currentUserStatuses, channelsAvailability, expandedRowId, filters, paginationPage]);

  const defaultHeaders = [
    { key: 'actions', content: t('GENERAL_ACTIONS'), headerCellProps: { style: { position: 'sticky', left: 0 } } },
    {
      key: 'ticketType',
      content: t('TICKET_TYPE'),
      headerCellProps: { style: { position: 'sticky', left: 0 } }
    },
    { key: 'todo', content: t('availability.labels.todo_tickets'), headerCellProps: {} },
    { key: 'doing', content: t('availability.labels.currently_working_on'), headerCellProps: {} },
    { key: 'usersOnline', content: t('availability.labels.users_online'), headerCellProps: {} },
    { key: 'usersOffline', content: t('availability.labels.users_offline'), headerCellProps: {} }
  ];

  return (
    <>
      <div className="availabilityFilters">
        <Input
          placeholder={t('GENERAL_NAME')}
          value={filters.ticketTypeName}
          onChange={(_, data) => setFilters((prevValue) => ({ ...prevValue, ticketTypeName: data.value }))}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', clear: 'both' }}>
        <TimerRefresh refreshData={refreshData} />

        <div style={{ overflowX: 'auto' }}>
          <Table
            headers={[
              ...defaultHeaders,
              ...channels.map(
                (channel) =>
                ({
                  key: String(channel.id),
                  content: channel.channel,
                  headerCellProps: {}
                } as HeaderItem)
              )
            ]}
            stickyHeader={true}
            data={renderChannels()}
            rootTableProps={{ celled: true, striped: true }}
            customStyles={{ width: 'max-content', minWidth: '100%' }}
          />
        </div>

        <div style={{ margin: '10px auto 0' }}>
          <Pagination
            itemsLength={
              ticketTypes.filter((ticketType) => {
                if (filters.ticketTypeName) {
                  return ticketType.name.toLowerCase().includes(filters.ticketTypeName.toLowerCase());
                }
                return true;
              }).length
            }
            activePage={paginationPage}
            pageSize={paginationSize}
            onPageChange={(activePage) => setPaginationPage(activePage)}
          />
        </div>
      </div>
    </>
  );
};

export default PerTicketTypeTable;
