import { FilterPresetType } from '@eeedo/types';
import {
  faArrowUpRightFromSquare,
  faBookOpen,
  faChartColumn,
  faClipboardCheck,
  faGear,
  faGlobe,
  faHouse,
  faUser,
  faUsers
} from '@fortawesome/pro-regular-svg-icons';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Divider } from 'semantic-ui-react';

import UnifiedMenuItem from './UnifiedMenuItem';
import { activateTicketlistTab } from 'src/actions/CaseListActions';
import FeatureFlags from 'src/api/FeatureFlags';
import ModalLoaderComponent from 'src/Components/Loader/ModalLoader';
import useFilterPresets from 'src/Components/ticketList/FilterPresetsWidget/useFilterPresets';
import ExportDataContainer from 'src/containers/ExportDataContainer';
import { selectIsCurrentUserAdmin } from 'src/selectors/rootStateSelectors';
import { useAppSelector } from 'src/store';
import { StaticTabs } from 'src/types/TicketList';
import { SINGLE_LINE_VIEW_INFOPAGES_PATH, SINGLE_LINE_VIEW_TICKETS_PATH } from 'src/Utilities/constants';

import type { MenuItem } from './UnifiedMenuItem';
import type { State } from 'src/types/initialState';

const UnifiedMenu = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const isAdmin = useSelector<State, boolean>(selectIsCurrentUserAdmin);
  const [isExportDataModalOpen, setIsExportDataModalOpen] = useState(false);
  const activeTicketTab = useAppSelector((state) => state.ticketTabs.find(({ activeTab }) => activeTab));
  const setExportDataModalOpen = useCallback(() => setIsExportDataModalOpen(true), [setIsExportDataModalOpen]);
  const setExportDataModalClosed = useCallback(() => setIsExportDataModalOpen(false), [setIsExportDataModalOpen]);
  const [sortedFilterPresets, , applyFilter] = useFilterPresets();

  const isKnowledgeBaseEnabled = FeatureFlags.isFlagOn('ENABLE_KNOWLEDGE_BASE');
  const isEntityViewerEnabled = FeatureFlags.isFlagOn('ENABLE_ENTITY_VIEWER');
  const ticketsPath = activeTicketTab?.type === 'tickets' ? `/case/${activeTicketTab.id}` : '/';
  const infopagesPath =
    isKnowledgeBaseEnabled && activeTicketTab?.type === 'infopages' ? `/infopage${activeTicketTab.id}` : '/infopage';

  const onClickTicketList = (path: string) => () => {
    history.push(path);
    dispatch(activateTicketlistTab(StaticTabs.MAIN_VIEW));
  };

  const menuItems: (MenuItem & { action?: (...args: any[]) => void })[] = useMemo(
    () => [
      {
        icon: faHouse,
        text: t('MENU_DROPDOWN_MAINVIEW'),
        action: onClickTicketList(ticketsPath),
        activePaths: [ticketsPath, SINGLE_LINE_VIEW_TICKETS_PATH]
      },
      {
        icon: faBookOpen,
        text: t('MENU_DROPDOWN_INFOPAGES'),
        action: onClickTicketList(infopagesPath),
        activePaths: [infopagesPath, SINGLE_LINE_VIEW_INFOPAGES_PATH]
      },
      { icon: faArrowUpRightFromSquare, text: t('MENU_DROPDOWN_EXPORT'), action: setExportDataModalOpen },
      { icon: faChartColumn, text: t('MENU_DROPDOWN_REPORTING'), href: '/reporting' },
      { icon: faGear, text: t('MENU_DROPDOWN_SETTINGS'), href: '/settings' },
      ...(isAdmin
        ? [{ icon: faClipboardCheck, text: t('availability.labels.availability'), href: '/availability' }]
        : []),
      ...(isEntityViewerEnabled ? [{ icon: faUsers, text: t('entityViewer.entity_view'), href: '/entities' }] : [])
    ],
    [setExportDataModalOpen, activeTicketTab, t]
  );

  const filtersItems = useMemo(
    () =>
      sortedFilterPresets.map((filterPreset) => ({
        id: filterPreset.id,
        icon: filterPreset.type === FilterPresetType.global ? faGlobe : faUser,
        text: filterPreset.name,
        filterCounts: filterPreset.counts,
        isActive: filterPreset.isSelected,
        action: () => applyFilter(filterPreset.id)
      })),
    [sortedFilterPresets]
  );

  return (
    <div className="layout-desktop-unified-menu">
      <div className="layout-desktop-unified-menu_items">
        {menuItems.map((item) => (
          <UnifiedMenuItem item={item} key={item.text} />
        ))}
      </div>

      {!!filtersItems.length && (
        <>
          <Divider />

          <div className="layout-desktop-unified-menu_items">
            {filtersItems.map((item) => (
              <UnifiedMenuItem item={item} key={item.id ?? item.text} />
            ))}
          </div>
        </>
      )}

      {isExportDataModalOpen && (
        <React.Suspense fallback={<ModalLoaderComponent />}>
          <ExportDataContainer handleClose={setExportDataModalClosed} isOpen={isExportDataModalOpen} />
        </React.Suspense>
      )}
    </div>
  );
};

export default UnifiedMenu;
