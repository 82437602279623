import React from 'react';

import type { UserWithProfile } from '@eeedo/types';
import type { FC } from 'react';
import type { TableCellProps } from 'semantic-ui-react';

import UserAvatar from 'src/Components/User/UserAvatar';
import Section from 'src/Components/generic/Section/Section';

interface UserNameCellProps {
  user: UserWithProfile;
}

const UserNameCell: FC<UserNameCellProps> = ({ user }) => {
  return (
    <Section display="inline-flex" align="center" justify='flex-start' gap={4}>
      <UserAvatar UID={user.UID} size="22" />
      <span>
        {user.profile.firstName || ''} {user.profile.lastName || ''}
      </span>
    </Section>
  );
};

const defaultTableCellProps = { width: 1, className: 'userProfileColumn' };

export default (user: UserWithProfile, cellProps?: TableCellProps) => ({
  content: <UserNameCell user={user} />,
  cellProps: { ...defaultTableCellProps, ...cellProps }
});
