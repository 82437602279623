import { useCallback, useEffect, useRef, useState } from 'react';
import { useStore } from 'react-redux';

import type { State } from 'src/types/initialState';

export function useAutoRefreshSelector<T>(selector: (state: State) => T, interval = 15000): [T, () => void] {
  const store = useStore<State>();
  const initialSelectedState = selector(store.getState());
  const [displayState, setDisplayState] = useState<T>(initialSelectedState);
  const latestStateRef = useRef<T>(initialSelectedState);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      latestStateRef.current = selector(store.getState());
    });
    return unsubscribe;
  }, [store, selector]);

  useEffect(() => {
    const timer = setInterval(() => {
      setDisplayState(latestStateRef.current);
    }, interval);
    return () => clearInterval(timer);
  }, [interval]);

  const refresh = useCallback(() => {
    setDisplayState(latestStateRef.current);
  }, []);

  return [displayState, refresh];
}
