import { Direction, FilterPresetType, FilterPresetView, SortBy } from '@eeedo/types';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pick } from 'lodash-es';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, Icon } from 'semantic-ui-react';

import type { FilterPresetPayload } from '@eeedo/types';
import type { FC } from 'react';

import useFilterPresets from './useFilterPresets';
import { setActiveFilterPreset } from 'src/actions/filterPresetsActions';
import FilterPresetsModal from 'src/Components/Management/FilterPresets/FilterPresetsModal';
import { selectActiveTicketListTab, selectIsInfopage } from 'src/selectors/rootStateSelectors';
import { useAppSelector } from 'src/store';

import type { State } from 'src/types/initialState';

type FilterPresetsWidgetProps = {
  isFilterOn: boolean;

  clearFilters: () => void;
};

const FilterPresetsWidget: FC<FilterPresetsWidgetProps> = ({ isFilterOn, clearFilters }) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPayload, setModalPayload] = useState<Record<string, any> | FilterPresetPayload>({});
  const [editId, setEditId] = useState<number | null>(null);

  const [sortedFilterPresets, defaultFilterPreset, applyFilter] = useFilterPresets(clearFilters);

  const activeFilterPresetId = useAppSelector((state) => state.filterPresets.activeId);
  const activeTicketTab = useAppSelector(selectActiveTicketListTab);
  const { filters, sorting, direction } = activeTicketTab ?? {};

  const isCurrentUserAdmin = useSelector((state: State) =>
    ['ROL1', 'ROL10'].includes(state.usersList.usersList.find((u) => u.UID === state.userData.UID)?.role.id ?? '')
  );
  const isInfopage = useAppSelector(selectIsInfopage);
  const currentView = isInfopage ? FilterPresetView.infopage : FilterPresetView.main;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isFilterOn) {
      dispatch(setActiveFilterPreset({ filterPresetId: null }));
    }
  }, [isFilterOn]);

  const initOverwriteCurrent = useCallback(() => {
    const preset = sortedFilterPresets.find((p) => p.id === activeFilterPresetId);
    if (!preset) {
      throw new Error('Something went wrong');
    }
    setModalPayload(
      pick(
        {
          ...preset,
          direction: direction ?? Direction.ASC,
          filters: filters ? { ...filters } : {},
          sorting: sorting ?? SortBy.dueDate,
          isDefault: defaultFilterPreset === preset.id
        },
        [
          'name',
          'filters',
          'sorting',
          'direction',
          'isFavourite',
          'isActive',
          'type',
          'ticketTypes',
          'view',
          'isDefault'
        ]
      )
    );
    setEditId(activeFilterPresetId);
    setIsModalOpen(true);
  }, [
    setModalPayload,
    setIsModalOpen,
    setEditId,
    sortedFilterPresets,
    activeFilterPresetId,
    direction,
    filters,
    sorting
  ]);

  const initiateSaveAsNew = useCallback(() => {
    setModalPayload({
      direction: direction ?? Direction.ASC,
      filters: filters ?? {},
      sorting: sorting ?? SortBy.dueDate
    });
    setIsModalOpen(true);
  }, [setModalPayload, setIsModalOpen, direction, filters, sorting]);

  return (
    <div style={{ margin: '5px 0 5px 10px' }}>
      <Dropdown
        trigger={
          <Button icon basic color={activeFilterPresetId ? 'red' : undefined} style={{ borderRadius: '12px' }}>
            <FontAwesomeIcon icon={faEllipsis} />
          </Button>
        }
        icon={false}
        floating
        labeled
        direction="left"
      >
        <Dropdown.Menu>
          <Dropdown.Header>{t('filter_presets.dropdown_title')}</Dropdown.Header>
          <Dropdown.Divider />
          <Dropdown.Menu scrolling>
            {sortedFilterPresets.length > 0 ? (
              sortedFilterPresets.map((preset) => (
                <Dropdown.Item
                  selected={preset.id === activeFilterPresetId}
                  onClick={() => applyFilter(preset.id)}
                  style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
                >
                  <div>{preset.name}</div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {preset.id === defaultFilterPreset || preset.isFavourite ? (
                      <Icon
                        name="star"
                        color={preset.id === defaultFilterPreset ? 'red' : 'blue'}
                        style={{ marginLeft: '20px' }}
                      />
                    ) : null}
                    <div style={{ marginLeft: '6px' }}>
                      {preset.type === FilterPresetType.global ? (
                        <Icon name="world" color="black" />
                      ) : (
                        <Icon name="user" color="black" />
                      )}
                    </div>
                  </div>
                </Dropdown.Item>
              ))
            ) : (
              <Dropdown.Item disabled>{t('filter_presets.dropdown_no_filters')}</Dropdown.Item>
            )}
          </Dropdown.Menu>
          <Dropdown.Divider />
          <Dropdown.Header style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Button
              disabled={
                !isFilterOn ||
                Boolean(
                  activeFilterPresetId &&
                    sortedFilterPresets.find((p) => p.id === activeFilterPresetId)?.type === FilterPresetType.global &&
                    !isCurrentUserAdmin
                )
              }
              labelPosition="left"
              color={activeFilterPresetId ? 'red' : 'blue'}
              onClick={() => {
                activeFilterPresetId ? initOverwriteCurrent() : initiateSaveAsNew();
              }}
              icon
            >
              <Icon name="save" />
              {activeFilterPresetId
                ? t('filter_presets.dropdown_button_overwrite')
                : t('filter_presets.dropdown_button_save_as_new')}
            </Button>
          </Dropdown.Header>
        </Dropdown.Menu>
      </Dropdown>
      <FilterPresetsModal
        modalPayload={modalPayload as FilterPresetPayload}
        editId={editId}
        isOpen={isModalOpen}
        closeModal={() => {
          setIsModalOpen(false);
          setEditId(null);
          setModalPayload({});
        }}
        setSelectedPreset={(filterPresetId: number | null) => dispatch(setActiveFilterPreset({ filterPresetId }))}
        currentView={currentView}
      />
    </div>
  );
};

export default FilterPresetsWidget;
