import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Field } from '@eeedo/types';
import type { FC } from 'react';

import SearchOmnibarItem from './SearchOmnibarItem';
import { useAppSelector } from 'src/store';

import type { SearchCriterion } from 'src/types/Search';

interface Props {
  email: string | null;
  onSearch: (searchCriterion: SearchCriterion) => void;
  phoneNumber: string | null;
  text: string | null;
  selectedTicketType?: string;
}

const SearchByEntityField: FC<Props> = ({ email, onSearch, phoneNumber, text, selectedTicketType }) => {
  const { t } = useTranslation();
  const ticketTypes = useAppSelector((state) => state.ticketTypes);
  const userData = useAppSelector((state) => state.userData);
  const ticketType = ticketTypes.find((type) =>
    selectedTicketType ? type.name === selectedTicketType : userData.ticketTypes.includes(type.id)
  );
  const getEntitiesEmailFields = (type: string) =>
    (ticketType?.fieldSets ?? []).flatMap((fieldSet) =>
      fieldSet[fieldSet.id]
        .map((field: Field) => ({ ...field, displayName: fieldSet.displayName }))
        .filter((field: Field) => field.displayField === type)
    );

  const onClickItem = (field: Field, value: string) => () => {
    if (field.name) {
      onSearch({
        datagroup: 'entity',
        name: field.name,
        object: field.object ?? false,
        param: field.value,
        text: value,
        value: value
      });
    }
  };

  const searchFields = [
    ...getEntitiesEmailFields('emailField').map((field) => ({ field, value: email })),
    ...getEntitiesEmailFields('phoneField').map((field) => ({ field, value: phoneNumber })),
    ...getEntitiesEmailFields('name1').map((field) => ({ field, value: text })),
    ...getEntitiesEmailFields('name2').map((field) => ({ field, value: text }))
  ].filter((field): field is { field: Field; value: string } => !!field.value);

  if (!ticketType) {
    return null;
  }

  return searchFields.map(({ field, value }, index) => (
    <SearchOmnibarItem
      key={index}
      label={t('search_and_command_palette.search_tickets_by_entity_field', {
        entityName: field.displayName,
        fieldName: field.name
      })}
      onClick={onClickItem(field, value)}
      searchText={value}
    />
  ));
};

export default SearchByEntityField;
