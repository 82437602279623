import { convertPrefixStringToNumber } from '@eeedo/utils';
import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'semantic-ui-react';

import type { UserStatus, UserStatusLog } from '@eeedo/types';
import type { FC } from 'react';

import Input from '../generic/Input/Input';
import UserStatusListItem from './UserStatusListItem/UserStatusListItem';
import { fetchUserStatusLogs } from 'src/actions/userStatusActions';
import { useAppSelector, useAppThunkDispatch } from 'src/store';

interface ChangeUserStatusProps {
  selectedStatus: UserStatus | undefined;
  statusDescription: string;
  updateUserData?: {
    UID?: number,
  },
  setSelectedStatus: React.Dispatch<React.SetStateAction<UserStatus | undefined>>;
  setStatusDescription: React.Dispatch<React.SetStateAction<string>>;
}

const ChangeUserStatus: FC<ChangeUserStatusProps> = ({
  selectedStatus,
  statusDescription,
  updateUserData,

  setSelectedStatus,
  setStatusDescription
}) => {
  const { t } = useTranslation();
  const dispatch = useAppThunkDispatch();
  const userStatuses = useAppSelector((state) => state.userStatusesState.userStatuses);
  const UID = useAppSelector((state) => convertPrefixStringToNumber(state.userData.UID));
  const [userStatusLogs, setUserStatusLogs] = useState<UserStatusLog[]>([]);
  const [descriptionError, setDescriptionError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (updateUserData?.UID) {
      dispatch(fetchUserStatusLogs({ UID: updateUserData.UID, take: 5 }))
        .then(unwrapResult)
        .then((result) => setUserStatusLogs(result))
    } else {
      dispatch(fetchUserStatusLogs({ UID, take: 5 }))
        .then(unwrapResult)
        .then((result) => setUserStatusLogs(result));
    }
  }, []);


  useEffect(() => {
    if (statusDescription.length > 32) {
      setDescriptionError(t('availability.errors.status_description_too_long'));
    } else {
      setDescriptionError(undefined);
    }
  }, [statusDescription, t]);

  return (
    <div>
      <h3 className="sectionHeader">{t('availability.labels.original_statuses')}</h3>
      {Object.values(userStatuses)
        .filter((userStatus) => !!userStatus.active)
        .map((userStatus) => (
          <UserStatusListItem
            key={userStatus.id}
            statusType={userStatus.type}
            text={userStatus.text}
            active={userStatus.text === selectedStatus?.text}
            onClick={() => setSelectedStatus(userStatus)}
          />
        ))}

      <Divider />

      <h3 className="sectionHeader">{t('availability.labels.status_description_label')}</h3>
      <Input
        size="small"
        type="text"
        placeholder={t('availability.labels.status_description_placeholder')}
        value={statusDescription}
        error={!!descriptionError}
        errorMessage={descriptionError}
        onChange={(_, data) => setStatusDescription(data.value as string)}
      />

      <h3 className="sectionHeader">{t('availability.labels.recent_statuses')}</h3>
      {userStatusLogs.map((userStatusLog) => {
        const originalStatus = userStatuses[userStatusLog.originalStatus];
        const isActive =
          !!userStatusLog.description &&
          (userStatusLog.description === statusDescription || userStatusLog.description === selectedStatus?.text);
        return (
          <UserStatusListItem
            statusType={originalStatus.type}
            text={userStatusLog.description || originalStatus.text}
            active={isActive}
            onClick={() => {
              setSelectedStatus(originalStatus);
              setStatusDescription(userStatusLog.description || '');
            }}
          />
        );
      })}
    </div>
  );
};

export default ChangeUserStatus;
