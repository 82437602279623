import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash-es';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import type { FC, MouseEvent } from 'react';

import * as styles from './TicketListHeader.style';
import { activateTicketlistTab } from 'src/actions/CaseListActions';
import { closeTicketlistTab } from 'src/actions/ticketListTabActionsRTK';
import Button from 'src/Components/generic/Button/Button';
import FloatingDropdown from 'src/Components/generic/FloatingDropdown/FloatingDropdown';
import { setTicketListFilterOpen, setTicketListSearchOpen } from 'src/reducers/ticketListTabToolsReducer';
import { selectTicketListTabs } from 'src/selectors/rootStateSelectors';
import { StaticTabs } from 'src/types/TicketList';

interface Props {
  isSingleLineView: boolean;
  toggleSingleLineView: () => void;
}

const TicketListDropdown: FC<Props> = ({ isSingleLineView, toggleSingleLineView }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tabs = useSelector(selectTicketListTabs);
  const [selectedSearchTabId, setSelectedSearchTabId] = React.useState<string | null>(null);
  const sortedSearchTabs = sortBy(
    tabs.filter((tab) => tab.id !== StaticTabs.MAIN_VIEW),
    ({ id }) => id
  );
  const activeTab = tabs.find((tab) => tab.activeTab);
  const selectedSearchTab = sortedSearchTabs.find((tab) => tab.id === selectedSearchTabId) ?? sortedSearchTabs[0];

  useEffect(() => {
    if (activeTab?.id && activeTab.id !== StaticTabs.MAIN_VIEW) {
      setSelectedSearchTabId(activeTab.id);
    }
  }, [activeTab]);

  const handleSelectTab = (id: string) => () => {
    setSelectedSearchTabId(id);
    dispatch(activateTicketlistTab(id));
  };

  const onClickMainView = () => {
    if (isSingleLineView) {
      toggleSingleLineView();
    }
    dispatch(setTicketListFilterOpen(false));
    dispatch(setTicketListSearchOpen(false));
    dispatch(activateTicketlistTab(StaticTabs.MAIN_VIEW));
  };

  const onClickRemoveSearch = (tabId: string) => (event: MouseEvent) => {
    event.stopPropagation();
    dispatch(closeTicketlistTab(tabId));
  };

  const getSearchTitle = (searchTabsLength: number, tabId: string) => {
    if (searchTabsLength === 1) {
      return t('TAB_NAME_SEARCH');
    }
    const index = sortedSearchTabs.findIndex((tab) => tab.id === tabId);
    return index !== -1 ? `${t('TAB_NAME_SEARCH')} ${index + 1}` : `${t('TAB_NAME_SEARCH')} 1`;
  };

  return (
    <>
      <Button
        borderless
        content={t('MENU_DROPDOWN_MAINVIEW')}
        active={activeTab?.id === StaticTabs.MAIN_VIEW}
        onClick={onClickMainView}
      />

      {sortedSearchTabs.length === 1 && (
        <Button
          active={activeTab?.id === sortedSearchTabs[0].id}
          borderless
          content={t('TAB_NAME_SEARCH')}
          onClick={handleSelectTab(sortedSearchTabs[0].id)}
          icon={<FontAwesomeIcon icon={faXmark} onClick={onClickRemoveSearch(sortedSearchTabs[0].id)} />}
        />
      )}

      {sortedSearchTabs.length > 1 && (
        <FloatingDropdown
          buttonProps={{
            active: activeTab?.id !== StaticTabs.MAIN_VIEW,
            borderless: true,
            content: getSearchTitle(sortedSearchTabs.length, selectedSearchTab?.id),
            onClick: handleSelectTab(selectedSearchTab?.id)
          }}
          dropdownProps={{
            color: activeTab?.id !== StaticTabs.MAIN_VIEW ? 'light_violet' : 'white'
          }}
          options={sortedSearchTabs.map((tab) => ({
            value: tab.id,
            onClick: handleSelectTab(tab.id),
            selected: activeTab?.id === tab.id,
            content:
              tab.id === StaticTabs.MAIN_VIEW ? (
                t('MENU_DROPDOWN_MAINVIEW')
              ) : (
                <div style={styles.TicketListDropdownOptionStyle}>
                  {getSearchTitle(sortedSearchTabs.length, tab.id)}
                  <FontAwesomeIcon icon={faXmark} onClick={onClickRemoveSearch(tab.id)} />
                </div>
              )
          }))}
        />
      )}
    </>
  );
};

export default TicketListDropdown;
