import { DEFAULT_KEY_PREFIX } from '@eeedo/types';
import { createReducer } from '@reduxjs/toolkit';

import type { ContentStatusOptions } from '@eeedo/types';

import {
  createContentStatusOptions,
  deleteContentStatusOptions,
  fetchContentStatusOptionsSuccess,
  refreshContentStatusOptions,
  updateContentStatusOptions
} from 'src/actions/ContentStatusOptionsAction';

export type ContentStatusOptionsState = {
  all: ContentStatusOptions[];
  byTicketType: Record<string, ContentStatusOptions[]>;
};

const initialState: ContentStatusOptionsState = {
  all: [],
  byTicketType: {}
};

const contentStatusOptionsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchContentStatusOptionsSuccess, (state, action) => {
      state.all = action.payload.contentStatusOptions.sort((a, b) => a.position - b.position);
      state.byTicketType = groupByTicketType(state.all);
    })
    .addCase(updateContentStatusOptions, (state, action) => {
      const { contentStatusOptions } = action.payload;
      state.all = [...state.all.filter((option) => option.id !== contentStatusOptions.id), contentStatusOptions].sort(
        (a, b) => a.position - b.position
      );
      state.byTicketType = groupByTicketType(state.all);
    })
    .addCase(createContentStatusOptions, (state, action) => {
      const { contentStatusOptions } = action.payload;
      state.all = [...state.all, contentStatusOptions]
        .sort((a, b) => a.id - b.id)
        .sort((a, b) => a.position - b.position);
      state.byTicketType = groupByTicketType(state.all);
    })
    .addCase(deleteContentStatusOptions, (state, action) => {
      const { id } = action.payload;
      state.all = state.all.filter((option) => option.id !== id).sort((a, b) => a.position - b.position);
      state.byTicketType = groupByTicketType(state.all);
    })
    .addCase(refreshContentStatusOptions, (state, action) => {
      const { CSOs } = action.payload;
      state.all = CSOs.sort((a, b) => a.position - b.position);
      state.byTicketType = groupByTicketType(state.all);
    });
});

const groupByTicketType = (options: ContentStatusOptions[]): Record<string, ContentStatusOptions[]> =>
  options.reduce((acc, curr) => {
    if (curr.ticketType.length === 0) {
      // Use a reserved default key to avoid collisions with valid ticket types
      const defaultKey = `${DEFAULT_KEY_PREFIX}_${curr.type}`;
      if (!acc[defaultKey]) {
        acc[defaultKey] = [];
      }
      acc[defaultKey].push(curr);
    } else {
      curr.ticketType.forEach((ticket) => {
        const key = `${ticket.name}_${curr.type}`;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(curr);
      });
    }
    return acc;
  }, {} as Record<string, ContentStatusOptions[]>);

export default contentStatusOptionsReducer;
