import { connect } from 'react-redux';

import { clearInfopagelistFilter, setInfopagelistFilter } from 'src/actions/infoPagesActionsRTK';
import { clearTicketlistFilter, setTicketlistFilter, setTicketlistSorting } from 'src/actions/ticketListTabActionsRTK';
import FilterWrapper from 'src/Components/ticketList/FilterWrapper';
import { selectActiveTicketListTab, selectIsFilterOn } from 'src/selectors/rootStateSelectors';

import type {
  IFilterWrapperDispatchToProps,
  IFilterWrapperStateToProps
} from 'src/Components/ticketList/FilterWrapper';
import type { IFilterItem } from 'src/types/Filter';
import type { State } from 'src/types/initialState';
import type { ContentTypesFields } from 'src/types/Ticket';

interface OwnProps {
  contentType: ContentTypesFields;
}

const mapStateToProps = (state: State, ownProps: OwnProps): IFilterWrapperStateToProps => {
  const activeTicketTab = selectActiveTicketListTab(state);
  const type = ownProps.contentType === 'infopages' ? 'infopage' : 'task';

  return {
    isFilterOn: selectIsFilterOn(state),
    categories: state.categories,
    ticketTypesMetadata: state.ticketTypesMetadata,
    personalData: state.userData,
    ticketTypes: state.ticketTypes,
    channels: state.channels,
    tab: activeTicketTab,
    tags: state.tags,
    usersList: state.usersList.usersList,
    priorities: state.ticketPriorities,
    isUsingUrlParams: true,
    statusOptions: state.contentStatusOptions.all
      ?.filter((option) => option.type === type && !option.disabled)
      .map((option) => ({ text: option.name, value: option.value })),
    filters: activeTicketTab?.filters,
    isFullWidthContainer: false
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: OwnProps) => ({
  setFilter: (item: IFilterItem) => {
    dispatch((ownProps.contentType === 'infopages' ? setInfopagelistFilter : setTicketlistFilter)(item));
  },
  clearAllFilters: (id: string) => {
    dispatch((ownProps.contentType === 'infopages' ? clearInfopagelistFilter : clearTicketlistFilter)(id));
  },
  setSorting:
    ownProps.contentType === 'tickets'
      ? (...args: Parameters<typeof setTicketlistSorting>) => {
          dispatch(setTicketlistSorting(...args));
        }
      : undefined
});

export default connect<IFilterWrapperStateToProps, IFilterWrapperDispatchToProps, OwnProps, State>(
  mapStateToProps,
  mapDispatchToProps
)(FilterWrapper);
