import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import type { ContentStatusOptions } from '@eeedo/types';

import { startAjaxCall } from './ajaxStatusActions';
import { initialRequestFailure, initialRequestInit, initialRequestSuccess } from './initActions';
import ContentStatusOptionsApi from 'src/api/ContentStatusOptionsApi';

export const fetchContentStatusOptionsSuccess = createAction<{ contentStatusOptions: ContentStatusOptions[] }>(
  'FETCH_CONTENT_STATUS_OPTIONS_SUCCESS'
);

export const updateContentStatusOptions = createAction<{ contentStatusOptions: ContentStatusOptions }>(
  'UPDATE_CONTENT_STATUS_OPTIONS'
);

export const createContentStatusOptions = createAction<{ contentStatusOptions: ContentStatusOptions }>(
  'CREATE_CONTENT_STATUS_OPTIONS'
);

export const deleteContentStatusOptions = createAction<{ id: number }>('DELETE_CONTENT_STATUS_OPTIONS');
export const refreshContentStatusOptions = createAction<{ CSOs: ContentStatusOptions[] }>(
  'REFRESH_CONTENT_STATUS_OPTIONS'
);

export const fetchContentStatusOptions = createAsyncThunk<void, void, { rejectValue: Error }>(
  'fetchContentStatusOptions',
  async (_, { dispatch }) => {
    dispatch(startAjaxCall({ name: 'FETCH_CONTENT_STATUS_OPTIONS' }));
    dispatch(initialRequestInit('contentStatusOptions'));
    try {
      const result = await ContentStatusOptionsApi.getContentStatusOptions();
      dispatch(fetchContentStatusOptionsSuccess({ contentStatusOptions: result }));
      dispatch(initialRequestSuccess('contentStatusOptions'));
    } catch (error) {
      dispatch(initialRequestFailure('contentStatusOptions', error));
    }
  }
);
