import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import type { Tag } from '@eeedo/types';

import SingleLineTable from './SingleLineTable';
import { updateSelectedTasks } from 'src/actions/singleLineViewActions';
import Button from 'src/Components/generic/Button/Button';
import Dropdown from 'src/Components/generic/Dropdown/Dropdown';
import Modal from 'src/Components/generic/Modal/Modal';
import PriorityDropdown from 'src/Components/generic/PriorityDropdown';
import Section from 'src/Components/generic/Section/Section';
import TicketTypeDropdown from 'src/Components/generic/TicketTypeDropdown';
import { closeModal, setSinglineLineViewAction } from 'src/reducers/singleLineViewReducer';
import { useAppSelector } from 'src/store';

import type { ContentTypesFields, Ticket, TicketListTicket } from 'src/types/Ticket';

interface Props {
  orderedTickets: TicketListTicket[];
  contentType: ContentTypesFields;
}

const SingleLineTicketViewModel: React.FC<Props> = ({ orderedTickets, contentType }) => {
  const { openModalType, selected } = useAppSelector((state) => state.singleLineView);
  const ticketsSelected = useMemo(
    () => orderedTickets.filter((ticket) => selected[ticket.id]),
    [orderedTickets, selected]
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const close = () => dispatch(closeModal());

  const handleChange = (update: Partial<Ticket>) => {
    dispatch(setSinglineLineViewAction(openModalType as string));
    dispatch(updateSelectedTasks(update));
  };

  const renderChangeContent = useMemo(() => {
    switch (openModalType) {
      case 'priority':
        return <Priority onChange={(priority) => handleChange({ priority })} />;
      case 'tag':
        return <Tags onChange={(tags) => handleChange({ tags: tags })} />;
      case 'ticketType':
        return <TicketType onChange={(ticketType) => handleChange({ taskType: ticketType })} />;
      case 'delegatedTo':
        return <Delegations onChange={(delegated) => handleChange({ delegatedTo: delegated })} />;
      default:
        return null;
    }
  }, [openModalType]);

  return (
    <Modal
      open={!!openModalType}
      onClose={close}
      header={t('GENERAL_CHANGE_TICKET')}
      children={
        <Section gap={12} direction="column">
          {renderChangeContent}
          <SingleLineTable tickets={ticketsSelected} contentType={contentType} />
        </Section>
      }
    />
  );
};

const Delegations = ({ onChange }: { onChange: (tags: string[]) => void }) => {
  const { t } = useTranslation();

  const [delegated, setDelegated] = useState<string[]>([]);
  const { usersList, userData } = useAppSelector((state) => ({
    usersList: state.usersList.usersList,
    userData: state.userData
  }));

  const options = [...usersList].map((user) => {
    const text = `${((user || {}).profile || {}).firstName} ${((user || {}).profile || {}).lastName}`;
    return {
      text,
      value: user.UID
    };
  });
  return (
    <Section gap={12} direction="column">
      <Dropdown
        disabled={!userData.permissions.includes('updateContent')}
        selection
        search
        fluid
        multiple
        forceSelection={false}
        selectOnBlur={false}
        id="delegateDropdown"
        selectOnNavigation={false}
        onChange={(event, data) => {
          setDelegated(data.value as string[]);
        }}
        placeholder={t('PLACEHOLDER_WRITE_TO_SEARCH')}
        options={options}
      />
      <Button disabled={!userData.permissions.includes('updateContent')} onClick={() => onChange(delegated)}>
        {t('GENERAL_SAVE')}
      </Button>
    </Section>
  );
};

const Priority = ({ onChange }: { onChange: (ID: number) => void }) => {
  const { t } = useTranslation();
  const { userData } = useAppSelector(({ userData }) => ({ userData }));
  const [priority, setPriority] = useState<number>();

  return (
    <Section gap={12} direction="column">
      <PriorityDropdown id="priorityDropdown" onChange={(_, data) => setPriority(data.value as number)} />
      <Button
        disabled={!userData.permissions.includes('updateContent') && !priority}
        onClick={() => onChange(priority!)}
      >
        {t('GENERAL_SAVE')}
      </Button>
    </Section>
  );
};

const TicketType = ({ onChange }: { onChange: (ID: string) => void }) => {
  const { t } = useTranslation();
  const { userData } = useAppSelector(({ userData }) => ({ userData }));
  const [ticketsType, setTicketsType] = useState<string>();

  return (
    <Section gap={12} direction="column">
      <TicketTypeDropdown
        value={ticketsType || ''}
        disabled={!userData.permissions.includes('updateContent')}
        handleTypeChange={(value: string) => {
          setTicketsType(value);
        }}
      />
      <Button
        disabled={!userData.permissions.includes('updateContent') && !ticketsType}
        onClick={() => onChange(ticketsType!)}
      >
        {t('GENERAL_SAVE')}
      </Button>
    </Section>
  );
};
const Tags = ({ onChange }: { onChange: (tags: Tag['id'][]) => void }) => {
  const { t } = useTranslation();
  const [tagsAdd, setTagsAdd] = useState<string[]>([]);
  const { tags, userData } = useAppSelector(({ tags, userData }) => ({ tags, userData }));

  const handleAddTag = (tags: Tag['id'][]) => {
    setTagsAdd(tags);
  };

  const tagOptions = tags.filter((tag) => !tag.deprecated).map((tag) => ({ text: tag.name, value: tag.id }));

  return (
    <Section gap={12} direction="column">
      <Dropdown
        fluid
        disabled={!userData.permissions.includes('updateContent')}
        onChange={(_, data) => {
          handleAddTag(data.value as Tag['id'][]);
        }}
        options={tagOptions}
        placeholder={t('PLACEHOLDER_WRITE_TO_SEARCH')}
        search
        selectOnBlur={false}
        selectOnNavigation={false}
        selection
        multiple
        size="mini"
      />
      <Button disabled={!userData.permissions.includes('updateContent')} onClick={() => onChange(tagsAdd)}>
        {t('GENERAL_SAVE')}
      </Button>
    </Section>
  );
};

export default React.memo(SingleLineTicketViewModel);
