import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';

import type { FC } from 'react';

import { selectSortedTags } from 'src/selectors/rootStateSelectors';
import { useAppSelector } from 'src/store';

import type { State } from 'src/types/initialState';

interface Props {
  selectedTag?: string;
  selectedTicketType?: string;
  setSelectedTag: (value?: string) => void;
  setSelectedTicketType: (value?: string) => void;
}

const SearchOmnibarFilters: FC<Props> = ({
  selectedTag,
  selectedTicketType,
  setSelectedTag,
  setSelectedTicketType
}) => {
  const { t } = useTranslation();
  const tags = useAppSelector(selectSortedTags);
  const ticketTypes = useAppSelector((state: State) => state.ticketTypes);

  return (
    <div className="command_palette_filters">
      <Dropdown
        basic
        selection
        clearable
        placeholder={t('FILTER_BY_TICKETTYPE')}
        options={ticketTypes.map(({ id, name }) => ({ value: name, text: name, key: id }))}
        value={selectedTicketType}
        onChange={(e, data: any) => setSelectedTicketType(data.value)}
        size="small"
      />

      <Dropdown
        basic
        selection
        clearable
        placeholder={t('FILTER_BY_TAG')}
        options={tags.map(({ id, name }) => ({ value: id, text: name }))}
        value={selectedTag}
        onChange={(e, data: any) => setSelectedTag(data.value)}
      />
    </div>
  );
};

export default React.memo(SearchOmnibarFilters);
