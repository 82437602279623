import { UserStatusType } from '@eeedo/types';
import { convertPrefixStringToNumber } from '@eeedo/utils';
import { faClose, faMemoCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ModalActions, ModalContent, ModalHeader } from 'semantic-ui-react';

import type { ChannelAvailability, UserStatus } from '@eeedo/types';
import type { FC } from 'react';

import Button from '../generic/Button/Button';
import ChangeChannelsAvailability from './ChangeChannelsAvailability';
import ChangeUserStatus from './ChangeUserStatus';
import { selectCurrentUserStatus } from './selectors';
import { changeChannelsAvailability } from 'src/actions/channelActions';
import { changeCurrentUserStatus } from 'src/actions/userStatusActions';
import { useModal } from 'src/Components/Modal/ModalContext';
import { useAppSelector, useAppThunkDispatch } from 'src/store';

import './ChangeAvailabilityModal.css';

interface ChangeAvailabilityModalProps {
  UID?: number;
}

const ChangeAvailabilityModal: FC<ChangeAvailabilityModalProps> = (updateUserData) => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const dispatch = useAppThunkDispatch();
  const [, originalStatus] = useSelector(selectCurrentUserStatus);
  const UID = useAppSelector((state) => convertPrefixStringToNumber(state.userData.UID));
  const [updateStatusInProgress, setUpdateStatusInProgress] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<UserStatus | undefined>(undefined);
  const [statusDescription, setStatusDescription] = useState<string>('');
  const [changedChannelsAvailability, setChangeAvailability] = useState<ChannelAvailability[]>([]);
  const username = useAppSelector((state) => {
    const user = state.usersList.usersList.find((user) => convertPrefixStringToNumber(user.UID) === updateUserData.UID);
    return `${user?.profile?.firstName || ''} ${user?.profile?.lastName || ''}`.trim();
  });

  const handleSubmit = async () => {
    setUpdateStatusInProgress(true);

    if (updateUserData.UID && selectedStatus) {
      await dispatch(
        changeCurrentUserStatus({
          UID: updateUserData.UID,
          originalStatus: selectedStatus?.id,
          description: statusDescription
        })
      );
    }
    else if (selectedStatus) {
      await dispatch(
        changeCurrentUserStatus({ UID, originalStatus: selectedStatus?.id, description: statusDescription })
      );
    }

    if (changedChannelsAvailability.length && selectedStatus?.type !== UserStatusType.NOTREADY) {
      await dispatch(changeChannelsAvailability(changedChannelsAvailability));
    }

    closeModal();
    setUpdateStatusInProgress(false);
  };

  return (
    <>
      <ModalHeader className="changeAvailabilityModal__header">
        <FontAwesomeIcon icon={faMemoCircleCheck} style={{ color: 'var(--purple-default)' }} />
        <h3>{t('availability.labels.change_availability') + ' ' + username}</h3>

        <FontAwesomeIcon
          icon={faClose}
          onClick={() => closeModal()}
          style={{ cursor: 'pointer', marginLeft: 'auto', color: 'var(--neutral-500)' }}
        />
      </ModalHeader>

      <ModalContent className="changeAvailabilityModal__content">
        <ChangeUserStatus
          updateUserData={{ UID: updateUserData.UID ? convertPrefixStringToNumber(updateUserData.UID) : undefined }}
          selectedStatus={selectedStatus}
          statusDescription={statusDescription}
          setSelectedStatus={setSelectedStatus}
          setStatusDescription={setStatusDescription}
        />

        <ChangeChannelsAvailability
          isBlocked={
            selectedStatus?.type === UserStatusType.NOTREADY ||
            (originalStatus.type === UserStatusType.NOTREADY && !selectedStatus)
          }
          changedChannelsAvailability={changedChannelsAvailability}
          setChangeAvailability={setChangeAvailability}
        />
      </ModalContent>

      <ModalActions>
        <Button
          type="primary"
          loading={updateStatusInProgress}
          content={t('GENERAL_SAVE')}
          disabled={(!selectedStatus && !changedChannelsAvailability.length) || updateStatusInProgress}
          onClick={handleSubmit}
        />

        <Button
          content={t('GENERAL_CANCEL')}
          onClick={() => closeModal()}
          iconLeft={<FontAwesomeIcon icon={faClose} />}
        />
      </ModalActions>
    </>
  );
};

export default ChangeAvailabilityModal;
