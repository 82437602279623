import classNames from 'classnames';
import React from 'react';
import { Table as SemanticTable } from 'semantic-ui-react';

import type { CSSProperties, FC, ReactNode } from 'react';
import type {
  TableProps as SemanticTableProps,
  TableCellProps,
  TableHeaderCellProps,
  TableRowProps
} from 'semantic-ui-react';

import './Table.css';

export interface HeaderItem {
  key: string;
  content: ReactNode;
  headerCellProps?: TableHeaderCellProps;
}

interface CellItem {
  content: ReactNode;
  cellProps?: TableCellProps;
  key?: string;
}

export interface RowItem {
  cells: CellItem[];
  rowProps?: TableRowProps;
  key?: string;
}

interface TableProps {
  rootTableProps?: SemanticTableProps;
  headers?: HeaderItem[];
  data?: RowItem[];
  customStyles?: CSSProperties;
  className?: string;
  stickyHeader?: boolean;
}

interface TableWrapperProps {
  customStyles?: CSSProperties;
  className?: string;
  rootTableProps?: SemanticTableProps;
  children?: React.ReactNode;
}

const Table = ({
  rootTableProps,
  headers = [],
  data = [],
  customStyles,
  className,
  stickyHeader = false
}: TableProps) => {
  const headerClassName = classNames({ sticky: stickyHeader });

  return (
    <div style={customStyles} className={`genericTable ${className || ''}`}>
      <SemanticTable {...rootTableProps}>
        {headers.length > 0 && (
          <SemanticTable.Header className={headerClassName} fullWidth>
            <SemanticTable.Row>
              {headers.map((header) => (
                <SemanticTable.HeaderCell key={header.key} {...header.headerCellProps}>
                  {header.content}
                </SemanticTable.HeaderCell>
              ))}
            </SemanticTable.Row>
          </SemanticTable.Header>
        )}

        <SemanticTable.Body>
          {data.map((row, rowIndex) => (
            <SemanticTable.Row key={row.key || `row-${rowIndex}`} {...row.rowProps}>
              {row.cells.map((cell, cellIndex) => (
                <SemanticTable.Cell key={cell.key || `cell-${rowIndex}-${cellIndex}`} {...cell.cellProps}>
                  {cell.content}
                </SemanticTable.Cell>
              ))}
            </SemanticTable.Row>
          ))}
        </SemanticTable.Body>
      </SemanticTable>
    </div>
  );
};

const TableWrapper: FC<TableWrapperProps> = ({ customStyles, className, rootTableProps, children }) => (
  <div style={customStyles} className={`genericTable ${className || ''}`}>
    <SemanticTable {...rootTableProps}>
      {children}
    </SemanticTable>
  </div >
);
const Header = SemanticTable.Header;
const Body = SemanticTable.Body;
const Row = SemanticTable.Row;
const Cell = SemanticTable.Cell;


export default Table;

export {
  TableWrapper,
  Header,
  Body,
  Row,
  Cell
}
