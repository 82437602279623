import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from 'semantic-ui-react';

import type { FC } from 'react';

import { openTicketTab } from 'src/actions/ticketsActions';
import { setTabsPersist, typeKeyMap } from 'src/reducers/tabsPersistReducer';

import type { State } from 'src/types/initialState';
import type { ContentTypesFields } from 'src/types/Ticket';

const selectPreviousTabsIds = (contentType: ContentTypesFields) => (state: State) =>
  state.tabsPersist[typeKeyMap[contentType]];
const selectCurrentTicketTabsIds = (contentType: ContentTypesFields) => (state: State) =>
  state.ticketTabs.filter(({ type }) => type === contentType).map(({ id }) => id);

interface Props {
  contentType: ContentTypesFields;
}

const TabBarRestore: FC<Props> = ({ contentType }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentTicketsIds = useSelector(selectCurrentTicketTabsIds(contentType));
  const previousTicketsIds = useSelector(selectPreviousTabsIds(contentType));
  const ticketIdsToRestore = previousTicketsIds.filter((id) => !currentTicketsIds.includes(id));

  const onClickClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(
      setTabsPersist({
        ids: currentTicketsIds,
        type: contentType
      })
    );
  };

  const onClickRestore = () => {
    ticketIdsToRestore.forEach((id) =>
      dispatch(
        openTicketTab({
          contentId: id,
          contentType,
          shouldActivateTicket: false
        })
      )
    );
  };

  if (!ticketIdsToRestore.length) {
    return null;
  }

  return (
    <Menu.Item className="pulse-active TabBar-Tab item" onClick={onClickRestore}>
      <span>{t('RESTORE_PREVIOUS_TABS')}</span>
      <span>
        <FontAwesomeIcon className="TabBar-Delete-Icon" onClick={onClickClose} icon={faXmark} />
      </span>
    </Menu.Item>
  );
};

export default TabBarRestore;
