import { createAsyncThunk } from '@reduxjs/toolkit';
import { t } from 'i18next';
import iziToast from 'izitoast';

import type { UserStatusLog } from '@eeedo/types';

import { startAjaxCall } from './ajaxStatusActions';
import { initialRequestFailure, initialRequestSuccess } from './initActions';
import UserStatusApi from 'src/api/UserStatusApi';
import { fetchCurrentUserStatusesSuccess, fetchUserStatusesSuccess } from 'src/reducers/userStatusReducer';

import type { UserStatusPayload } from 'src/api/UserStatusApi';

export const fetchUserStatuses = createAsyncThunk<void, void, { rejectValue: Error }>(
  'fetchUserStatuses',
  async (_, { dispatch }) => {
    dispatch(startAjaxCall({ name: 'FETCH_USER_STATUSES' }));
    try {
      const result = await UserStatusApi.getUserStatuses();
      dispatch(fetchUserStatusesSuccess(result));
      dispatch(initialRequestSuccess('userStatuses'));
    } catch (error) {
      dispatch(initialRequestFailure('userStatuses', error));
    }
  }
);

export const fetchCurrentUserStatuses = createAsyncThunk<void, void, { rejectValue: Error }>(
  'fetchCurrentUserStatuses',
  async (_, { dispatch }) => {
    dispatch(startAjaxCall({ name: 'FETCH_CURRENT_USER_STATUSES' }));
    try {
      const result = await UserStatusApi.getCurrentUserStatuses();
      dispatch(fetchCurrentUserStatusesSuccess(result));
      dispatch(initialRequestSuccess('currentUserStatuses'));
    } catch (error) {
      dispatch(initialRequestFailure('currentUserStatuses', error));
    }
  }
);

export const fetchUserStatusLogs = createAsyncThunk<
  UserStatusLog[],
  { UID: number; take?: number; idCursor?: number },
  { rejectValue: Error }
>('fetchUserStatusLogs', async ({ UID, take = 5, idCursor }, { dispatch }) => {
  dispatch(startAjaxCall({ name: 'FETCH_USER_STATUS_LOGS' }));
  try {
    const result = await UserStatusApi.getUserStatusLogs({ UID, take, idCursor });
    return result;
  } catch (error) {
    iziToast.error({
      title: t('ERROR_HAPPENED'),
      message: t('availability.toasts.fetch_user_status_logs_fail'),
      position: 'bottomRight',
      timeout: 2500,
      icon: 'delete'
    });
    return [];
  }
});

export const changeCurrentUserStatus = createAsyncThunk<
  { status: 'success' | 'error' },
  { UID: number; originalStatus: number; description?: string },
  { rejectValue: Error }
>('changeUserStatus', async (payload, { dispatch }) => {
  dispatch(startAjaxCall({ name: 'CHANGE_CURRENT_USER_STATUS' }));
  try {
    await UserStatusApi.changeCurrentUserStatus(payload);

    iziToast.success({
      title: t('SUCCESS'),
      icon: 'icon check',
      message: t('availability.toasts.current_user_status_updated'),
      position: 'bottomRight',
      timeout: 2500
    });
    return { status: 'success' };
  } catch (error) {
    iziToast.error({
      title: t('ERROR_HAPPENED'),
      message: t('availability.toasts.current_user_status_update_failed'),
      position: 'bottomRight',
      timeout: 2500,
      icon: 'delete'
    });
    return { status: 'error' };
  }
});

export const updateUserStatus = createAsyncThunk<
  { status: 'success' | 'error' },
  { id: number; payload: Partial<UserStatusPayload> },
  { rejectValue: Error }
>('updateUserStatus', async ({ id, payload }, { dispatch }) => {
  dispatch(startAjaxCall({ name: 'UPDATE_USER_STATUS' }));

  try {
    await UserStatusApi.updateUserStatus({ id, payload });

    iziToast.success({
      title: t('SUCCESS'),
      icon: 'icon check',
      position: 'bottomRight',
      message: t('userStatusManagement.toasts.userStatus_update_success'),
      timeout: 2500
    });
    return { status: 'success' };
  } catch (error) {
    iziToast.error({
      title: t('ERROR_HAPPENED'),
      message: t('userStatusManagement.toasts.userStatus_update_fail'),
      position: 'bottomRight',
      timeout: 2500,
      icon: 'delete'
    });
    return { status: 'error' };
  }
});

export const createUserStatus = createAsyncThunk<
  { status: 'success' | 'error' },
  UserStatusPayload,
  { rejectValue: Error }
>('createUserStatus', async (payload, { dispatch }) => {
  dispatch(startAjaxCall({ name: 'CREATE_USER_STATUS' }));

  try {
    await UserStatusApi.createUserStatus(payload);

    iziToast.success({
      title: t('SUCCESS'),
      icon: 'icon check',
      position: 'bottomRight',
      message: t('userStatusManagement.toasts.userStatus_create_success'),
      timeout: 2500
    });
    return { status: 'success' };
  } catch (error) {
    iziToast.error({
      title: t('ERROR_HAPPENED'),
      message: t('userStatusManagement.toasts.userStatus_create_fail'),
      position: 'bottomRight',
      timeout: 2500,
      icon: 'delete'
    });
    return { status: 'error' };
  }
});
