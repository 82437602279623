import { faAngleDown, faGear, faHashtag, faQuestion, faRightFromBracket } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import type { ComponentProps } from 'react';

import { logout } from './actions/authActions';
import EnvSettings from './api/EnvSettings';
import Dropdown from './Components/generic/Dropdown/Dropdown';
import UserAvatar from './Components/User/UserAvatar';
import CurrentUserStatusLabel from './Components/UserStatus/CurrentUserStatus';
import Internationalization from './i18n';
import * as styles from './ProfileDropdown.style';
import { useAppSelector } from './store';
import { setHtmlLangAttribute } from './Utilities/helper';

import './ProfileDropdown.css';

const ProfileDropdown = () => {
  const { t, i18n } = useTranslation();
  const userData = useAppSelector((state) => state.userData);
  const dispatch = useDispatch();
  const manualLogout = useCallback(() => {
    dispatch(logout({ type: 'manual' }));
  }, [dispatch]);

  let defaultLang = 'fi';
  if (userData && userData.userPreferences) {
    defaultLang = userData.userPreferences.defaultLanguage;
  }

  const [lang, setLang] = useState(defaultLang || i18n.language);

  useEffect(() => {
    getI18n().changeLanguage(lang);
    setHtmlLangAttribute(lang);
  }, []);

  const onLanguageChange = useCallback<NonNullable<ComponentProps<typeof Dropdown>['onChange']>>(
    (_e, data) => {
      if (Array.isArray(data.value) || !data.value || typeof data.value !== 'string') {
        return;
      }

      Internationalization.changeLanguage(i18n, data.value);
      setLang(data.value);
    },
    [i18n, setLang]
  );

  const userName = `${userData.profile.firstName} ${userData.profile.lastName}`;
  const version = import.meta.env.VITE_CONTAINER_IMAGE;

  const options = useMemo(
    () => [
      {
        value: 'lang',
        content: (
          <Dropdown
            icon={<FontAwesomeIcon icon={faAngleDown} style={styles.langIcon} />}
            style={styles.lang}
            selectOnBlur={false}
            value={lang}
            direction="left"
            onChange={onLanguageChange}
            options={Internationalization.getLanguages()}
          />
        )
      },
      {
        value: 'version',
        disabled: true,
        icon: <FontAwesomeIcon icon={faHashtag} style={styles.icon} />,
        content: `${t('VERSION')} ${version ?? ''}`
      },
      {
        value: 'instructions',
        href: EnvSettings.getSettings().GUIDE_URL,
        target: '_blank',
        icon: <FontAwesomeIcon icon={faQuestion} style={styles.icon} />,
        content: t('INSTRUCTIONS')
      },
      {
        value: 'settings',
        icon: <FontAwesomeIcon icon={faGear} style={styles.icon} />,
        as: Link,
        to: '/settings',
        content: t('PREFERENCES_EDIT_SETTINGS')
      },
      {
        value: 'logout',
        onClick: manualLogout,
        icon: <FontAwesomeIcon icon={faRightFromBracket} style={styles.icon} />,
        content: t('MAIN_TOPBAR_LOGOUT')
      }
    ],
    [lang, t, onLanguageChange, manualLogout]
  );

  return (
    <Dropdown
      className="user"
      value={userName}
      selection
      style={styles.dropdown}
      icon={<FontAwesomeIcon icon={faAngleDown} />}
      trigger={
        <div className="divider text">
          <UserAvatar
            UID={userData.UID}
            className="navBarProfileAvatar"
            round
            size="32"
            imgClassName="ProfileDropdown-Image"
            showUserStatusType={true}
          />

          <div className="ProfileDropdown-Wrapper">
            <div className="ProfileDropdown-Name">{userName}</div>
            <CurrentUserStatusLabel />
          </div>
        </div>
      }
      options={options}
    />
  );
};

export default React.memo(ProfileDropdown);
