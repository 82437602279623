import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Comment, Icon, Loader } from 'semantic-ui-react';

import CommentTextEncapsulated, { EXPAND_COMMENT_HEIGHT, EXPAND_COMMENT_LENGTH } from './CommentTextEncapsulated';

import type { Attachment, Comment as TicketComment } from 'src/types/Ticket';

interface CommentTextProps {
  parsedContent?: string | null;
  isChatComment: boolean;
  isCommentExpanded: boolean;
  isLastComment: boolean;
  attachments: Attachment[];
  botButtonClickedState?: TicketComment;
  metaData?: TicketComment['metaData'];
  type: TicketComment['type'];
  toggleExpand(): void;
  isCommentPreview?: boolean;
}

const CommentText = ({
  parsedContent,
  isChatComment,
  isCommentExpanded,
  isLastComment,
  botButtonClickedState,
  metaData,
  toggleExpand,
  isCommentPreview
}: CommentTextProps) => {
  const { t } = useTranslation();
  const [commentTextHeight, setCommentTextHeight] = React.useState(0);
  const isCommentTooLong =
    !isCommentPreview &&
    parsedContent &&
    !isLastComment &&
    (commentTextHeight > EXPAND_COMMENT_HEIGHT || parsedContent.length > EXPAND_COMMENT_LENGTH);

  return (
    <Comment.Text style={{ overflowWrap: 'break-word' }}>
      {parsedContent === undefined && (
        <div className="Comment-Loader">
          <Loader active inline />
        </div>
      )}

      {isChatComment && (
        <div className={'Comment-Content images-max-w-95'}>
          <em>{parsedContent}</em>
        </div>
      )}
      {!isChatComment && !botButtonClickedState && (
        <div>
          <div
            data-test="commentTextEncapsulated"
            className={`${!isCommentExpanded && isCommentTooLong ? 'collapsedComment' : ''}`}
          >
            <CommentTextEncapsulated
              comment={parsedContent}
              setCommentTextHeight={setCommentTextHeight}
              isCommentExpanded={isCommentExpanded || isLastComment}
            />
          </div>

          {isCommentTooLong && (
            <div className="expandCommentIcon">
              <Icon name={!isCommentExpanded ? 'chevron down' : 'chevron up'} onClick={toggleExpand} />
            </div>
          )}
        </div>
      )}

      {!!metaData?.buttons && (
        <div className="Comment-Content images-max-w-95" style={{ marginTop: '6px' }}>
          {metaData.buttons.map((buttonData) => (
            <Button size="tiny" disabled={true}>
              {buttonData.label}
            </Button>
          ))}
        </div>
      )}

      {!!botButtonClickedState && (
        <div className="Comment-Content images-max-w-95" style={{ marginTop: '6px' }}>
          <p style={{ marginBottom: '5px' }}>
            {`${t('AUTOMATIC_COMMENT_BOT_CLICKED_BUTTON')} `}
            {
              botButtonClickedState.metaData?.buttons?.find(
                (button) => button.buttonState === metaData?.userClickedButton
              )?.label
            }
          </p>

          {botButtonClickedState.metaData?.buttons?.map((buttonData) => (
            <Button
              size="tiny"
              primary={buttonData.buttonState === metaData?.userClickedButton}
              disabled={buttonData.buttonState !== metaData?.userClickedButton}
              style={{
                pointerEvents: 'none'
              }}
            >
              {buttonData.label}
            </Button>
          ))}
        </div>
      )}
    </Comment.Text>
  );
};

export default React.memo(CommentText);
