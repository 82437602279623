import iziToast from 'izitoast';
import { cloneDeep } from 'lodash-es';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Grid, Icon, Modal } from 'semantic-ui-react';

import type { UserWithProfile } from '@eeedo/types';
import type { WithTranslation } from 'react-i18next';
import type { ConnectedProps } from 'react-redux';
import type { RouteComponentProps } from 'react-router';

import TopBarWorkStatusButtons from './TopBarWorkStatusButtons/TopBarWorkStatusButtons';
import { updateTicket } from 'src/actions/ticketsActions';
import { removeTicketFromTicketlist } from 'src/actions/ticketsActionsRTK';
import { ticketStopWorkingOn } from 'src/actions/workStatusActions';
import FeatureFlags from 'src/api/FeatureFlags';
import TicketsApi from 'src/api/TicketsApi';
import TicketStatusDropdown from 'src/Components/generic/TicketStatusDropdown';
import TicketTypeDropdown from 'src/Components/generic/TicketTypeDropdown';
import EnreachVoiceButtons from 'src/Components/PhoneServices/EnreachVoiceButtons';
import ShortcutsStatusButtons from 'src/Components/Shortcuts/ShortcutsStatusButtons';
import SocketInstance from 'src/realTimeNotifications';
import { StaticTabs } from 'src/types/TicketList';
import { checkMandatoryFieldsForTicketClosing } from 'src/Utilities/restrictions';
import { handleAssignOperationRejection } from 'src/Utilities/ticket';
import { replaceWorkingOn, startWorkingOn, stopWorkingOn } from 'src/Utilities/workStatusParser';

import type { ContentTypes } from 'src/types/ContentTypes';
import type { State } from 'src/types/initialState';
import type { ThunkAppDispatch } from 'src/types/store';
import type { StatusTypes, Ticket } from 'src/types/Ticket';

import './TopBarStatusButtons.css';

import TopBarWorkStatusUser from './TopBarWorkStatusUser/TopBarWorkStatusUser';

interface OwnProps {
  contentType: ContentTypes;
}

export type TopBarStatusButtonsProps = TopBarStatusButtonsHOCProps;

interface TopBarState {
  isMandatoryFieldRequirementModalOpen: boolean;
  unfilledMandatoryFields: string[];
  user: UserWithProfile | undefined;
  ticketTypeNames: { text: string; value: string; key: string }[];
}

class TopBarStatusButtons extends React.Component<TopBarStatusButtonsProps, TopBarState> {
  constructor(props: TopBarStatusButtonsProps) {
    super(props);

    this.state = {
      isMandatoryFieldRequirementModalOpen: false,
      unfilledMandatoryFields: [],
      user: props.users.find((user) => user.UID === props.task?.createdByUser?.trim()),
      ticketTypeNames: []
    };

    this.setTicketTypeNames(props);
  }

  componentWillReceiveProps(nextProps: TopBarStatusButtonsProps) {
    if (nextProps.task) {
      this.setState({
        user: nextProps.users.find((user) => user.UID === nextProps.task.createdByUser.trim())
      });
      this.setTicketTypeNames(nextProps);
      this.defineUserElements(nextProps);
    }
  }

  componentWillMount() {
    window['__startWorkingOnAtExactly'] = async ({ concurrentUser }: { concurrentUser: number }) => {
      // Concurrent user
      await this.onStartWorking(`USR${concurrentUser}`);

      // Current user
      await this.onStartWorking(this.props.userData.UID);
    };
  }

  componentWillUnmount() {
    delete window['__startWorkingOnAtExactly'];
  }

  private onStartWorking = async (UID: string) => {
    return TicketsApi.startWorkingOn(...startWorkingOn(UID, this.props.task.id))
      .then(() => this.handleStatusChange('doing'))
      .catch(handleAssignOperationRejection);
  };

  private onStopWorking = (UID: string) => {
    this.props.stopWorkingOn(this.props.task.id, UID);
  };

  private onReplaceWorking = (oldUID: string, newUID: string) => {
    TicketsApi.replaceWorkingOn(...replaceWorkingOn(this.props.task.id, `USR${oldUID}`, newUID)).catch(
      handleAssignOperationRejection
    );
  };

  private getMandatoryFieldRequirementModal = () => {
    const { t } = this.props;

    return (
      <Modal
        open={this.state.isMandatoryFieldRequirementModalOpen}
        onClose={() => this.setState({ isMandatoryFieldRequirementModalOpen: false })}
      >
        <Modal.Header>{t('MANDATORY_FIELD_REQUIREMENT_TITLE')}</Modal.Header>
        <Modal.Content>
          {t('MANDATORY_FIELD_REQUIREMENT_CONTENT')}
          <ul>
            {this.state.unfilledMandatoryFields.map((field) => {
              return <li>{t(field)}</li>;
            })}
          </ul>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            icon
            onClick={() => this.setState({ isMandatoryFieldRequirementModalOpen: false })}
            labelPosition="left"
          >
            <Icon name="check" />
            {t('GENERAL_CLOSE')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  private setTicketTypeNames = (props: TopBarStatusButtonsProps) => {
    let metadata = props.ticketTypesMetadata;

    if (typeof metadata !== 'undefined') {
      // TODO MONGODB Remove type.canAdd === undefined when we stop using mongo
      metadata = metadata.filter(
        (type) => type.canAdd === undefined || type.canAdd || type.name === props.task.taskType
      );

      const ticketTypeNames = metadata
        .filter((type) => type.allowed)
        .map((type, index) => ({
          text: type.name,
          value: type.name,
          key: `${type.name}-${index}`,
          // TODO MONGODB Remove type.canAdd !== undefined when we stop using mongo
          disabled: type.canAdd !== undefined && !type.canAdd
        }));

      if (FeatureFlags.isFlagOn('ALLOW_CHANGING_TO_FORBIDDEN_TICKET_TYPES')) {
        const ticketTypeNamesForbidden = metadata
          .filter((type) => !type.allowed)
          .map((type, index) => {
            return {
              text: type.name,
              value: type.name,
              key: `${type.name}-${index}`,
              icon: 'angle right',
              className: 'ticketTypeDropdownForbiddenTicketTypeName',
              // TODO MONGODB Remove type.canAdd !== undefined when we stop using mongo
              disabled: type.canAdd !== undefined && !type.canAdd
            };
          });

        this.setState({
          ticketTypeNames: [...ticketTypeNames, ...ticketTypeNamesForbidden]
        });
      } else {
        this.setState({
          ticketTypeNames: ticketTypeNames
        });
      }
    }
  };

  // TODO: refactor to simply return userProfileLabel/userProfileContent Element without storing it under class property
  private defineUserElements = (props: TopBarStatusButtonsProps) => {
    const { users, task } = this.props;
    if (!users?.length || !task) {
      return;
    }

    this.setState({
      user: props.users.find((user) => user.UID === props.task.createdByUser.trim())
    });
  };

  private handleStatusChange(newStatus: StatusTypes) {
    if (this.props.task.id !== 'NEW') {
      if (newStatus === 'done') {
        const task = cloneDeep(this.props.task);
        task.status = newStatus;

        if (this.checkCloseAsDoneRequirements(this.props.user.UID, true)) {
          return this.props.updateTicket(task.id, { status: newStatus });
        }
        return;
      }

      return this.props.updateTicket(this.props.task.id, { status: newStatus });
    }
  }

  private handleTypeChange = (typeToChangeTo: string) => {
    const { t } = this.props;

    if (this.props.task) {
      if (
        this.props.ticketTypesMetadata.find((type) => {
          return type.name === typeToChangeTo && type.allowed === true;
        })
      ) {
        this.props.updateTicket(this.props.task.id, {
          taskType: typeToChangeTo
        });
      } else {
        iziToast.question({
          timeout: 0,
          progressBar: false,
          close: false,
          overlay: true,
          id: 'question',
          zindex: 999,
          message: t('TICKET_TYPE_IS_FORBIDDEN_AND_WILL_DISABLE_THE_TICKET'),
          position: 'center',
          buttons: [
            [
              `<button><b>${t('GENERAL_ACCEPT')}</b></button>`,
              async (instance, toast) => {
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'confirm');

                await this.props.closeAndUpdateTicketType({
                  UID: this.props.user.UID,
                  ticketId: this.props.task.id,
                  taskType: typeToChangeTo
                });

                this.props.removeTicketFromTicketlist({ ticketId: this.props.task.id });
              },
              false
            ],
            [
              `<button>${t('GENERAL_CANCEL')}</button>`,
              (instance, toast) => {
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'cancel');
              },
              false
            ]
          ]
        });
      }
    }
  };

  private checkCloseAsDoneRequirements = (UID: string, returnBoolean = false) => {
    const task = this.props.task;
    const { fields, status } = checkMandatoryFieldsForTicketClosing(task, this.props.ticketTypes, this.props.tags);
    if (!returnBoolean && status === 'OK') {
      return this.props.closeAsDone(task.id, UID);
    } else if (returnBoolean && status === 'OK') {
      return true;
    } else {
      return this.setState({ unfilledMandatoryFields: fields, isMandatoryFieldRequirementModalOpen: true });
    }
  };

  render() {
    const { task, user, workStatus, userData } = this.props;
    if (!task || !user) {
      return null;
    }

    const isAjaxCallInProgress = this.props.ajaxCalls.some(
      (ajaxCall) => ajaxCall.name === 'UPDATE_TICKET' && ajaxCall.id === task.id
    );

    const isActiveCloseAs = (status: StatusTypes) =>
      (task?.status !== status || !!workStatus) && userData.permissions.includes('updateContent');

    return (
      <Grid.Column className="statusButtonsDropdownsColumn" width={6} key="column">
        {this.getMandatoryFieldRequirementModal()}

        <TopBarWorkStatusUser ticketId={task.id} />

        <div className="topbarStatusButtons">
          <TicketStatusDropdown
            disabled={isAjaxCallInProgress || !userData.permissions.includes('updateContent')}
            value={task.status}
            handleStatusChange={(value: string) => {
              this.handleStatusChange(value as StatusTypes);
            }}
            loading={isAjaxCallInProgress}
            ticketType={task.taskType}
          />

          <TicketTypeDropdown
            value={task.taskType}
            loading={isAjaxCallInProgress}
            disabled={isAjaxCallInProgress || !userData.permissions.includes('updateContent')}
            handleTypeChange={(value: string) => {
              this.handleTypeChange(value);
            }}
          />
        </div>

        {this.props.contentType === 'task' && (
          <TopBarWorkStatusButtons
            isActiveCloseAs={isActiveCloseAs}
            ticketId={task.id}
            onCloseAndReturnAsDoing={this.props.closeAndReturnAsDoing}
            onCloseAsDone={this.checkCloseAsDoneRequirements}
            onReplaceWorking={this.onReplaceWorking}
            onReturnAsNew={this.props.returnAsNew}
            onStartWorking={this.onStartWorking}
            onStopWorking={this.onStopWorking}
          />
        )}

        {this.props.contentType === 'task' && <EnreachVoiceButtons />}

        <ShortcutsStatusButtons
          onCloseAsDone={() => isActiveCloseAs('done') && this.checkCloseAsDoneRequirements(user.UID)}
        />
      </Grid.Column>
    );
  }
}

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const task = state.detailedTickets.find((ticket) => ticket.id === state.activeTicketTab)!;
  return {
    task,
    tags: state.tags,
    ajaxCalls: state.ajaxCallsInProgress,
    mobileMode: state.mobileReducer.mobileMode,
    workStatus: state.workStatus.status.find((status) => {
      if (!task) {
        return false;
      }
      return 'TSK' + status.ticketId === task.id;
    }),
    users: state.usersList.usersList,
    user: state.usersList.usersList.find((user) => user.UID === state.userData.UID)!,
    ticketTypesMetadata: state.ticketTypesMetadata,
    tabs: state.ticketTabs,
    ticketTypes: state.ticketTypes,
    userData: state.userData,
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch: ThunkAppDispatch) => {
  return {
    updateTicket: (id: string, data: Partial<Ticket>) => {
      dispatch(updateTicket({ id, ticket: data }));
    },
    closeAsDone: async (id: string, UID: string | undefined) => {
      if (UID !== undefined) {
        await dispatch(ticketStopWorkingOn(...stopWorkingOn(UID, id)));
      }
      await dispatch(updateTicket({ id, ticket: { status: 'done' }, closeAfterUpdate: true }));

      SocketInstance.leaveRoom(id);
    },
    removeTicketFromTicketlist: ({ ticketId }: { ticketId: string }) => {
      dispatch(removeTicketFromTicketlist({ ticketId, id: StaticTabs.MAIN_VIEW }));
    },
    closeAndUpdateTicketType: async ({
      UID,
      ticketId: id,
      taskType
    }: {
      UID: string;
      ticketId: string;
      taskType: string;
    }) => {
      await dispatch(updateTicket({ id, ticket: { taskType }, closeAfterUpdate: true }));

      if (UID !== undefined) {
        await dispatch(ticketStopWorkingOn(...stopWorkingOn(UID, id)));
      }
      SocketInstance.leaveRoom(id);
    },
    returnAsNew: async (id: string, UID: string | undefined) => {
      await dispatch(updateTicket({ id, ticket: { status: 'todo' }, closeAfterUpdate: true }));

      if (UID !== undefined) {
        await dispatch(ticketStopWorkingOn(...stopWorkingOn(UID, id)));
      }
      SocketInstance.leaveRoom(id);
    },
    closeAndReturnAsDoing: async (id: string, UID: string | undefined) => {
      await dispatch(updateTicket({ id, ticket: { status: 'doing' }, closeAfterUpdate: true }));

      if (UID !== undefined) {
        await dispatch(ticketStopWorkingOn(...stopWorkingOn(UID, id)));
      }
      SocketInstance.leaveRoom(id);
    },
    stopWorkingOn: async (id: string, UID?: string) => {
      if (UID !== undefined) {
        await dispatch(ticketStopWorkingOn(...stopWorkingOn(UID, id)));
      }
    }
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface TopBarStatusButtonsHOCProps
  extends RouteComponentProps,
  WithTranslation,
  ConnectedProps<typeof connector> { }

export default withRouter(connector(withTranslation('translations')(TopBarStatusButtons)));
