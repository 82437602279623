import { createSlice } from '@reduxjs/toolkit';

import type { ChannelAvailability } from '@eeedo/types';
import type { PayloadAction } from '@reduxjs/toolkit';

export type ChannelsAvailabilityState = {
  [UID: number]: {
    [channelId: number]: boolean;
  };
};

const initialState: ChannelsAvailabilityState = {};

const channelsAvailabilitySlice = createSlice({
  name: 'channelsAvailability',
  initialState,
  reducers: {
    loadChannelsAvailabilitySuccess(_, action: PayloadAction<ChannelsAvailabilityState>) {
      return action.payload;
    },
    changeChannelsAvailabilitySuccess(state, action: PayloadAction<ChannelAvailability>) {
      const { UID, channelId, available } = action.payload;
      if (!state[UID]) {
        state[UID] = {};
      }
      state[UID][channelId] = available;
    },
    removeChannelsAvailabilitySuccess(state, action: PayloadAction<{ UID: number; channelId: number }>) {
      const { UID, channelId } = action.payload;
      if (state[UID]) {
        delete state[UID][channelId];
      }
    }
  }
});

export const { loadChannelsAvailabilitySuccess, changeChannelsAvailabilitySuccess, removeChannelsAvailabilitySuccess } =
  channelsAvailabilitySlice.actions;

export default channelsAvailabilitySlice.reducer;
