import { convertPrefixStringToNumber } from '@eeedo/utils';
import { createSlice } from '@reduxjs/toolkit';

import type { OnlineStatusChangePayload, OnlineStatusType, UserWithProfile } from '@eeedo/types';
import type { PayloadAction } from '@reduxjs/toolkit';

export type OnlineStatusState = {
  [UID: number]: OnlineStatusType;
};

const initialState: OnlineStatusState = {};

const onlineStatusSlice = createSlice({
  name: 'onlineStatus',
  initialState,
  reducers: {
    initBasicOnlineStatusState(_, action: PayloadAction<UserWithProfile[]>) {
      return action.payload.reduce((acc, user) => {
        acc[convertPrefixStringToNumber(user.UID)] = 'offline';
        return acc;
      }, {});
    },
    initOnlineStatusState(state, action: PayloadAction<string[]>) {
      return action.payload.reduce((acc, UID) => {
        acc[convertPrefixStringToNumber(UID)] = 'online';
        return acc;
      }, state);
    },
    updateOnlineStatus(state, action: PayloadAction<OnlineStatusChangePayload>) {
      const { UID, status } = action.payload;
      state[convertPrefixStringToNumber(UID)] = status;
    }
  }
});

export const { initBasicOnlineStatusState, initOnlineStatusState, updateOnlineStatus } = onlineStatusSlice.actions;

export default onlineStatusSlice.reducer;
