import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { t } from 'i18next';
import iziToast from 'izitoast';

import type { Channel, ChannelAvailability } from '@eeedo/types';
import type { AxiosError } from 'axios';

import ChannelApi from '../api/ChannelApi';
import { startAjaxCall } from './ajaxStatusActions';
import { FETCH_CHANNEL_TYPES_FAILURE, FETCH_CHANNEL_TYPES_SUCCESS, PATCH_CHANNELS_SUCCESS } from './index';
import { initialRequestFailure, initialRequestInit, initialRequestSuccess } from './initActions';
import { loadChannelsAvailabilitySuccess } from 'src/reducers/channelsAvailabilityReducer';

export const loadChannelTypesSuccess = (channelTypes: Channel[]) => {
  return { type: FETCH_CHANNEL_TYPES_SUCCESS, payload: { channelTypes } };
};

export const loadChannelTypesFailure = (error: Error) => {
  return {
    type: FETCH_CHANNEL_TYPES_FAILURE,
    payload: { type: 'channelTypes', error: error }
  };
};

export const patchChannelSuccess = (channel: Channel) => {
  return { type: PATCH_CHANNELS_SUCCESS, channel };
};

export const fetchChannelTypes = createAsyncThunk<void, void, { rejectValue: Error }>(
  'fetchChannelTypes',
  async (_, { dispatch }) => {
    dispatch(startAjaxCall({ name: 'FETCH_CHANNEL_TYPES' }));
    dispatch(initialRequestInit('channelTypes'));
    try {
      const channelTypes = await ChannelApi.getChannelTypes();
      dispatch(loadChannelTypesSuccess(channelTypes));
      dispatch(initialRequestSuccess('channelTypes'));
    } catch (error) {
      console.error('Failed to load channel types', (error as Error).message);
      if (isAxiosError(error)) {
        dispatch(loadChannelTypesFailure(error as AxiosError));
      }
      dispatch(initialRequestFailure('channelTypes', error));
    }
  }
);

export const fetchChannelsAvailability = createAsyncThunk<void, void, { rejectValue: Error }>(
  'fetchChannelsAvailability',
  async (_, { dispatch }) => {
    dispatch(startAjaxCall({ name: 'FETCH_CHANNELS_AVAILABILITY' }));
    try {
      const channelsAvailability = await ChannelApi.getChannelsAvailability();
      dispatch(loadChannelsAvailabilitySuccess(channelsAvailability));
      dispatch(initialRequestSuccess('channelsAvailability'));
    } catch (error) {
      console.error('Failed to load channels availability', (error as Error).message);
      dispatch(initialRequestFailure('channelsAvailability', error));
    }
  }
);

export const changeChannelsAvailability = createAsyncThunk<void, ChannelAvailability[], { rejectValue: Error }>(
  'changeChannelsAvailability',
  async (channelAvailability) => {
    try {
      await ChannelApi.changeChannelsAvailability(channelAvailability);
      iziToast.success({
        title: t('SUCCESS'),
        icon: 'icon check',
        message: t('availability.toasts.channels_availability_changed'),
        timeout: 5000
      });
    } catch (error) {
      console.error('Failed to update channels availability', (error as Error).message);
      iziToast.error({
        title: t('ERROR_HAPPENED'),
        message: t('availability.toasts.channels_availability_change_failed'),
        position: 'bottomRight',
        timeout: 2500,
        icon: 'delete'
      });
    }
  }
);

export const patchChannel = createAsyncThunk<void, { id: number; data: Channel }, { rejectValue: Error }>(
  'patchChannel',
  async (payload, { dispatch }) => {
    dispatch(startAjaxCall({ name: 'PATCH_CHANNEL' }));
    await ChannelApi.patchChannel(payload);
  }
);
