import React, { useCallback, useState } from 'react';
import { ListIcon, ListItem } from 'semantic-ui-react';

import type { FC } from 'react';

import type { AttachmentMenuItem as IAttachmentMenuItem } from 'src/types/Attachments';

interface AttachmentMenuItemProps {
  option: IAttachmentMenuItem;
}

const styles = {
  item: (active: boolean, disabled?: boolean) => ({
    color: active ? 'rgb(86, 0, 163)' : 'rgba(0, 0, 0, 0.95)',
    fontSize: '14px',
    fontWeight: 600,
    pointerEvents: 'all',
    padding: '11px 16px',
    borderRadius: '12px',
    cursor: disabled ? 'default' : 'pointer',
    backgroundColor: active ? 'rgb(239, 235, 243)' : '#FFF'
  }),
  icon: { marginRight: '7px' }
};

const AttachmentMenuItem: FC<AttachmentMenuItemProps> = ({ option }) => {
  const [active, setActive] = useState(false);
  const { icon, disabled, text, onClick } = option;

  const onMouseOver = useCallback(() => {
    setActive(true);
  }, [setActive]);
  const onMouseOut = useCallback(() => {
    setActive(false);
  }, [setActive]);

  return (
    <ListItem
      role="option"
      icon={icon}
      disabled={disabled}
      onClick={onClick as any}
      style={styles.item(active, disabled)}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      {icon && <ListIcon name={icon} style={styles.icon} />}
      {text}
    </ListItem>
  );
};

export default React.memo(AttachmentMenuItem);
