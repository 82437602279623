import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

export interface SingleLineViewState {
  enabled: boolean;
  selected: Record<string, boolean>;
  inProgress: Record<string, boolean>;
  action?: string;
  openModalType?: string;
}

const initialState: SingleLineViewState = {
  enabled: false,
  selected: {},
  inProgress: {},
  openModalType: undefined
};

const singleLineViewSlice = createSlice({
  name: 'singleLineView',
  initialState,
  reducers: {
    toggleSingleLineView(state, { payload }: PayloadAction<boolean>) {
      state.enabled = payload;
    },
    setSelectedTicket(state, { payload }: PayloadAction<{ id: string; checked: boolean }>) {
      state.selected[payload.id] = payload.checked;
    },
    setAllSelectedTickets(state, { payload }: PayloadAction<{ ids: string[]; checked: boolean }>) {
      payload.ids.forEach((id) => {
        state.selected[id] = payload.checked;
      });
    },
    resetSelectedTickets(state) {
      state.selected = {};
    },
    setInProgress(state, { payload }: PayloadAction<{ id: string; inProgress: boolean }>) {
      state.inProgress[payload.id] = payload.inProgress;
    },
    setSinglineLineViewAction(state, { payload }: PayloadAction<string>) {
      state.action = payload;
    },
    resetSingleLineViewState() {
      return initialState;
    },
    setModalTypeAndOpen(state, { payload }: PayloadAction<string>) {
      state.openModalType = payload;
    },
    closeModal(state) {
      state.openModalType = undefined;
    }
  }
});

export const {
  toggleSingleLineView,
  setSelectedTicket,
  setAllSelectedTickets,
  resetSelectedTickets,
  setInProgress,
  setSinglineLineViewAction,
  resetSingleLineViewState,
  setModalTypeAndOpen,
  closeModal
} = singleLineViewSlice.actions;

export default singleLineViewSlice.reducer;
