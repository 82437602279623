import { apiClient } from 'src/Utilities/httpClients';

export default class OpenAIApi {
  static generateTicketRelevantInfo = async (payload: {
    contentId: number;
    content: string;
    language: string;
    isHtml?: boolean;
  }) => {
    return apiClient.post('/openai/whatIsRelevant', payload).then((response) => response.data);
  };

  static generateTags = async (payload: { contentId: number; content: string; isHtml?: boolean }) => {
    return apiClient.post<{ tags: string[] }>('/openai/generateTags', payload).then((response) => response.data);
  };

  static generateTranscriptionsSummary = async (payload: { contentId: number }) => {
    return apiClient
      .post<{ summary: string }>('/openai/generateTranscriptionSummary', payload)
      .then((response) => response.data);
  };

  static triggerAIFunction = async (payload: {
    language: string;
    templateId?: number;
    data: {
      contentId?: number;
      commentId?: number;
      UID: number;
      editor?: {
        selection?: string;
        prompt?: string;
      };
    };
    allowMissingParams: boolean;
    avoidCommentCreation?: boolean;
  }) => {
    return apiClient
      .post(`/openai/generateAnswerByPrompt?avoidCommentCreation=${payload.avoidCommentCreation || false}`, payload)
      .then((response) => response.data);
  };
}
