import { get, uniqBy } from 'lodash-es';

import type { SenderEmail } from '@eeedo/types';

import EnvSettings from 'src/api/EnvSettings';

import type { CommentMetadata } from '../../types/Ticket';

export const trimAndSplitRecipients = (recipient: string | string[] | undefined) => {
  if (recipient === undefined || Array.isArray(recipient)) {
    return recipient;
  }

  return recipient.split(/,|;|\s|\n/gi).map((rcp: string) => {
    return rcp.trim();
  });
};

export const filterDuplicateRecipients = (
  to: CommentMetadata['to'],
  from: CommentMetadata['from'] | string | null,
  senderEmails: SenderEmail[]
) => {
  const toArray = Array.isArray(to) ? to : [to];
  const fromArray = Array.isArray(from) ? from : [from];

  let filteredTo = toArray.filter((email) => typeof email === 'string');
  const filteredFrom = fromArray.filter((email) => typeof email === 'string');

  if (EnvSettings.getSettings().FILTER_SYSTEM_EMAIL === 'true') {
    filteredTo = filteredTo.filter(
      (email) => !senderEmails.some((systemEmail) => systemEmail.email?.toLowerCase() === email?.toLowerCase())
    );
  }

  return uniqBy([...filteredFrom, ...filteredTo], (email) => email?.toLowerCase()).join(', ');
};

export const defineToParameter = (metaData: { [x: string]: any }) => {
  const replyTo = metaData?.headers?.['reply-to'];

  if (replyTo?.value) {
    return replyTo.value[0].address;
  }

  const replyToText = replyTo?.text || replyTo;
  if (typeof replyToText === 'string') {
    const emailTag = replyToText.match(/<(.*?)>/);
    return get(emailTag, [1], replyToText);
  }

  return metaData.from;
};
