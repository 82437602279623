import { convertPrefixStringToNumber } from '@eeedo/utils';
import { capitalize } from 'lodash-es';
import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { Divider } from 'semantic-ui-react';

import type { ChannelAvailability } from '@eeedo/types';
import type { FC } from 'react';

import Label from '../generic/Label/Label';
import Toggle from '../generic/Toggle/Toggle';
import { selectCurrentUserChannelsAvailability } from './selectors';
import { useAppSelector } from 'src/store';

interface ChangeChannelsAvailabilityProps {
  isBlocked: boolean;
  changedChannelsAvailability: ChannelAvailability[];
  setChangeAvailability: React.Dispatch<React.SetStateAction<ChannelAvailability[]>>;
}

const ChangeChannelsAvailability: FC<ChangeChannelsAvailabilityProps> = ({
  isBlocked,
  changedChannelsAvailability,
  setChangeAvailability
}) => {
  const { t } = useTranslation();
  const { channels, userData, channelsAvailability } = useAppSelector((state) => ({
    channels: state.channels,
    userData: state.userData,
    channelsAvailability: selectCurrentUserChannelsAvailability(state)
  }));

  const handleToggle = (channelId: number, checked: boolean) => {
    if (channelsAvailability[channelId] === !!checked) {
      setChangeAvailability((prevValue) => [...prevValue.filter((ca) => ca.channelId !== channelId)]);
      return;
    }

    setChangeAvailability((prevValue) => [
      ...prevValue.filter((ca) => ca.channelId !== channelId),
      { UID: convertPrefixStringToNumber(userData.UID), channelId, available: checked }
    ]);
  };

  const allToggled = userData.channels.every((channelId) => {
    const currentAvailability = channelsAvailability[channelId];
    const changed = changedChannelsAvailability.find((channel) => channel.channelId === channelId)?.available;
    return typeof changed === 'boolean' ? changed : currentAvailability;
  });

  const handleToggleAll = (_: React.FormEvent<HTMLInputElement>, { checked }: { checked?: boolean }) => {
    const newChecked = !!checked;
    setChangeAvailability(() => {
      return userData.channels.reduce<ChannelAvailability[]>((acc, channelId) => {
        if (channelsAvailability[channelId] !== newChecked) {
          acc.push({
            UID: convertPrefixStringToNumber(userData.UID),
            channelId,
            available: newChecked
          });
        }
        return acc;
      }, []);
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <Scrollbars
        autoHide
        renderTrackHorizontal={(props) => <div {...props} className="track-horizontal" />}
        renderThumbHorizontal={(props) => <div {...props} className="thumb-horizontal" />}
        className="scrollbarWrapper"
      >
        {isBlocked ? (
          <div className="blockedOverlay">
            <Label color="yellow">{t('availability.labels.offline_status_warning')}</Label>
          </div>
        ) : null}

        <h3 className="sectionHeader" style={{ margin: '0 0 16px' }}>
          {t('availability.labels.channels_availability')}
        </h3>

        <div className="channelWrapper">
          <Toggle id="toggleAll" checked={allToggled} onChange={handleToggleAll} />
          <label htmlFor="toggleAll">{t('GENERAL_TOGGLE_ALL')}</label>
        </div>

        <Divider style={{ marginRight: '16px' }} />

        {userData.channels.map((channelId) => {
          const currentAvailability = channelsAvailability[channelId];
          const changedAvailability = changedChannelsAvailability.find(
            (channelAvailability) => channelAvailability.channelId === channelId
          )?.available;
          const channel = channels.find((channel) => channel.id === channelId)!;

          return (
            <div className="channelWrapper" key={channelId}>
              <Toggle
                id={channel.channel}
                checked={typeof changedAvailability === 'boolean' ? changedAvailability : currentAvailability}
                onChange={(_, { checked }) => handleToggle(channelId, checked!)}
              />
              <label htmlFor={channel.channel}>{capitalize(channel.channel)}</label>
            </div>
          );
        })}
      </Scrollbars>
    </div>
  );
};

export default ChangeChannelsAvailability;
