import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import type { EnhancedStore } from '@reduxjs/toolkit';
import type { TypedUseSelectorHook } from 'react-redux';
import type { AnyAction } from 'redux';
import type { PersistConfig } from 'redux-persist';

import sideEffectSetUserPreferencesMiddleware from './actions/userPreferencesAction';
import { leaveRoomMiddleware } from './middlewares/leaveRoomMiddleware';
import { socketInitMiddleware } from './middlewares/socketInitMiddleware';
import { urlSyncMiddleware } from './middlewares/urlSyncMiddleware';
import { userTimeLogsMiddleware } from './middlewares/userTimeLogsMiddleware';
import reducers from './reducers';
import { history } from './reducers/browserHistory';
import { commentsMenuInitialState } from './reducers/commentsMenuReducer';

import type { State } from './types/initialState';
import type { ThunkAppDispatch } from './types/store';

const migrations = {
  3: (state: State): State => ({ ...state, commentsMenu: commentsMenuInitialState })
};

const persistKeys = ['tabsPersist', 'commentsMenu', 'windows'];

const persistConfig: PersistConfig<State> = {
  key: 'root',
  version: 3,
  storage,
  whitelist: persistKeys,
  migrate: createMigrate(migrations as any, { debug: false })
};

const rootReducer = (state: State, action: AnyAction) => {
  // In case user performs logout action we want to clear redux store, leaving only persist items
  if (action.type === 'LOGOUT_SUCCESS' || action.type === 'LOGOUT_FAILURE') {
    const resultState = persistKeys.reduce<Partial<State>>((acc, key) => {
      acc[key] = state[key];
      return acc;
    }, {});
    return reducers(resultState as State, action);
  }

  return reducers(state, action);
};

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false
  }),
  routerMiddleware(history),
  urlSyncMiddleware,
  socketInitMiddleware,
  leaveRoomMiddleware,
  userTimeLogsMiddleware,
  sideEffectSetUserPreferencesMiddleware
];

const persistedReducer = persistReducer(persistConfig, rootReducer as any);

export const store: EnhancedStore<State> = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware
});

export type StoreType = typeof store.getState;
export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>();
export const useAppSelector: TypedUseSelectorHook<State> = useSelector;

export const persistor = persistStore(store);
