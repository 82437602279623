import React from 'react';

import type { FC } from 'react';

import './ReadinessLabel.css';

interface ReadinessLabelProps {
  type: 'active' | 'inactive';
  text: string;
  onClick?: () => void;
}

const ReadinessLabel: FC<ReadinessLabelProps> = ({ type, text, onClick }) => {
  return (
    <div className={`readinessLabel ${onClick ? 'clickable' : ''}`} onClick={onClick}>
      <span className="userStatusTypeIndication" style={{ backgroundColor: type === 'active' ? 'green' : 'red' }} />
      {text}
    </div>
  );
};

export default ReadinessLabel;
