import classNames from 'classnames';
import React from 'react';
import { Pagination as SemanticPagination } from 'semantic-ui-react';

import type { FC } from 'react';

import './Pagination.css';

interface PaginationProps {
  itemsLength: number;
  activePage: number;
  pageSize?: number;
  type?: PaginationType;

  onPageChange: (activePage: number) => void;
}

export type PaginationType = 'primary' | 'normal' | 'error' | 'positive';

const Pagination: FC<PaginationProps> = ({ itemsLength, activePage, pageSize = 10, type = 'normal', onPageChange }) => {
  /**
   * Hardcoded fix to change library component state
   * Issue on library side with using multiple Pagination component on the same page
   */
  const [reference, setReference] = React.useState<SemanticPagination | null>(null);
  const totalPages = Math.max(Math.ceil(itemsLength / pageSize), activePage);
  React.useEffect(() => {
    reference?.setState({ activePage });
  }, [activePage]);

  if (totalPages <= 1) {
    return null;
  }

  const paginationClass = classNames('genericPagination', `type__${type}`);

  return (
    <SemanticPagination
      ref={(ref) => setReference(ref)}
      paginationPage={activePage}
      totalPages={totalPages}
      className={paginationClass}
      onPageChange={(_, { activePage }) => onPageChange(activePage as number)}
    />
  );
};

export default Pagination;
