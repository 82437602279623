import { Direction } from '@eeedo/types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { type StrictDropdownItemProps } from 'semantic-ui-react';

import type { FC } from 'react';
import type { ConnectedProps } from 'react-redux';

import SingleLineTicketViewContent from './SingleLineTicketViewContent';
import { openSelectedTasks, updateSelectedTasks } from 'src/actions/singleLineViewActions';
import { setModalTypeAndOpen, setSinglineLineViewAction } from 'src/reducers/singleLineViewReducer';
import { selectActiveTicketListTab } from 'src/selectors/rootStateSelectors';
import { useAppSelector } from 'src/store';
import { doneTicketsOrdering, filterTicketList, ticketOrdering } from 'src/Utilities/ticketList';

import type { AccordionTab } from './SingleLineTicketViewContent';
import type { State } from 'src/types/initialState';
import type { TicketListTicket } from 'src/types/Ticket';

import './SingleLineTicketView.css';

type SingleLineTicketViewProps = ConnectedProps<typeof connector>;

const SingleLineTicketView: FC<SingleLineTicketViewProps> = ({ categories, personalData, usersList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activeTicketTab, contentStatuses } = useAppSelector((state) => ({
    activeTicketTab: selectActiveTicketListTab(state),
    contentStatuses: state.contentStatusOptions.all.filter((option) => option.type === 'task' && !option.disabled)
  }));
  const { tickets: contents = [], filters = {}, sorting = null, direction = Direction.ASC } = activeTicketTab ?? {};
  const contentType = 'tickets';

  const orderedTickets = ticketOrdering(contents, sorting, direction, personalData.userCustomTicketOrdering);
  const filterArgs = {
    categories,
    personalData,
    filters,
    usersList
  };

  const getTickets = (status: string): TicketListTicket[] => {
    const tickets = (status === 'done'
      ? doneTicketsOrdering(contents.filter((ticket) => ticket.status === 'done'))
      : orderedTickets.filter((ticket) => ticket.status === status)) as TicketListTicket[]

    return filterTicketList({
      ...filterArgs,
      status,
      tickets
    });
  }

  const accordionTabs: AccordionTab[] = useMemo(
    () => contentStatuses.map(({ name, value }, index) => ({
      index, title: t(name), status: value,
      tickets: getTickets(value)
    })),
    [orderedTickets, contentStatuses]
  );

  const onOpenTickets = () => {
    dispatch(openSelectedTasks({ contentType: 'tickets' }));
  };

  const onOptionClick = useCallback<NonNullable<StrictDropdownItemProps['onClick']>>((_, { text, update }) => {
    dispatch(setSinglineLineViewAction(text as string));
    dispatch(updateSelectedTasks(update));
  }, []);

  const openModal = useCallback<NonNullable<StrictDropdownItemProps['onClick']>>(
    (_, { value }) => {
      dispatch(setModalTypeAndOpen(value as string));
    },
    [dispatch]
  );

  const dropdownOptions = useMemo(
    () => [
      {
        text: t('olt_actions.open'),
        value: 'open',
        onClick: onOpenTickets
      },
      {
        text: t('olt_actions.deprecate'),
        value: 'deprecate',
        onClick: onOptionClick,
        update: { deprecated: true }
      },
      {
        text: t('olt_actions.status.todo'),
        value: 'setToTodo',
        onClick: onOptionClick,
        update: { taskStatus: 'todo' }
      },
      {
        text: t('olt_actions.status.doing'),
        value: 'setToDoing',
        onClick: onOptionClick,
        update: { taskStatus: 'doing' }
      },
      {
        text: t('olt_actions.status.done'),
        value: 'setToDone',
        onClick: onOptionClick,
        update: { taskStatus: 'done' }
      },
      {
        text: t('olt_actions.ticket.priority'),
        value: 'priority',
        onClick: openModal
      },
      {
        text: t('olt_actions.ticket.tag'),
        value: 'tag',
        onClick: openModal
      },
      {
        text: t('olt_actions.ticket.ticketType'),
        value: 'ticketType',
        onClick: openModal
      },
      {
        text: t('olt_actions.ticket.delegations'),
        value: 'delegatedTo',
        onClick: openModal
      }
    ],
    [onOptionClick, t]
  );

  return (
    <SingleLineTicketViewContent
      accordionTabs={accordionTabs}
      contentType={contentType}
      orderedTickets={orderedTickets}
      dropdownOptions={dropdownOptions}
    />
  );
};

const connector = connect((state: State) => {
  return {
    categories: state.categories,
    personalData: state.userData,
    usersList: state.usersList.usersList
  };
});

export default connector(SingleLineTicketView);
