import { UIDPreferencesKeys } from '@eeedo/types';
import React, { createContext, useContext, useState } from 'react';

import type { UIDPreferencesData } from '@eeedo/types';
import type { FunctionComponent } from 'react';

import { useAppSelector } from 'src/store';
import { setPreference } from 'src/reducers/userPreferencesReducer';
import { useDispatch } from 'react-redux';

interface WidgetViewContextType {
  selectedWidget: string;
  isSingleWidgetView: UIDPreferencesData['isSingleWidgetView']['enable'];
  isRightSidebarOpen: UIDPreferencesData['isRightSidebarOpen']['enable'];

  setSelectedWidget: (widget: string) => void;
  toggleWidgetView: () => void;
  toggleRightSidebar: () => void;
}

const WidgetViewContext = createContext<WidgetViewContextType | undefined>(undefined);

export const useWidgetView = () => {
  const context = useContext(WidgetViewContext);
  if (context === undefined) {
    throw new Error('useWidgetView must be used within a WidgetViewProvider');
  }
  return context;
};

export const WidgetViewProvider: FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  const dispatch = useDispatch();
  const [selectedWidget, setSelectedWidget] = useState<string>('CustomerInfo');

  const { isSingleWidgetView, isRightSidebarOpen } = useAppSelector(({ userPreferences }) => ({
    isSingleWidgetView: userPreferences.isSingleWidgetView.enable,
    isRightSidebarOpen: userPreferences.isRightSidebarOpen.enable
  }));

  const toggleWidgetView = () => {
    // If right sidebar is close, open it to show the widget menu
    if (!isRightSidebarOpen) {
      toggleRightSidebar();
    }
    dispatch(
      setPreference({
        key: UIDPreferencesKeys.isSingleWidgetView,
        value: { enable: !isSingleWidgetView }
      })
    );
  };

  const toggleRightSidebar = () => {
    dispatch(
      setPreference({
        key: UIDPreferencesKeys.isRightSidebarOpen,
        value: { enable: !isRightSidebarOpen }
      })
    );
  };

  return (
    <WidgetViewContext.Provider
      value={{
        toggleWidgetView,
        setSelectedWidget,
        toggleRightSidebar,
        selectedWidget,
        isSingleWidgetView,
        isRightSidebarOpen
      }}
    >
      {children}
    </WidgetViewContext.Provider>
  );
};
