import type { CSSProperties } from 'react';

export const TicketListDropdownOptionStyle: CSSProperties = {
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  marginRight: '-10px'
};

export const TicketListIconsContainer: CSSProperties = {
  display: 'flex',
  gap: '4px',
  marginLeft: 'auto'
};
