import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from 'semantic-ui-react';

import type { Channel } from '@eeedo/types';
import type { FC } from 'react';
import type { TableCellProps } from 'semantic-ui-react';

import ReadinessLabel from '../../Management/UsersStatusManagement/ReadinessLabel/ReadinessLabel';
import { useAppSelector } from 'src/store';
import ChangeAvailabilityModal from 'src/Components/UserStatus/ChangeAvailabilityModal';
import { useModal } from 'src/Components/Modal/ModalContext';

import './ChannelsAvailabilityCell.css';

interface ChannelsAvailabilityCellProps {
  UID: number;
}

const ChannelsAvailabilityCell: FC<ChannelsAvailabilityCellProps> = ({ UID }) => {
  const { t } = useTranslation();
  const { openModal } = useModal();

  const channels = useAppSelector((state) => state.channels);
  const channelsAvailability = useAppSelector((state) => state.channelsAvailability);
  const userChannelsAvailability = channelsAvailability[UID];

  const channelKeys = useMemo(() => {
    if (!userChannelsAvailability) return [];
    return Object.keys(userChannelsAvailability)
      .map(Number)
      .sort((a, b) =>
        userChannelsAvailability[a] === userChannelsAvailability[b] ? 0 : userChannelsAvailability[a] ? -1 : 1
      );
  }, [userChannelsAvailability]);
  const visibleChannelKeys = useMemo(() => channelKeys.slice(0, 4), [channelKeys]);
  const hiddenChannelKeys = useMemo(() => channelKeys.slice(4), [channelKeys]);
  const channelsById = useMemo(() => {
    return channels.reduce<Record<number, Channel>>((acc, channel) => ({ ...acc, [channel.id]: channel }), {});
  }, [channels]);

  const renderChannelLabel = (channelId: number) => {
    const channelAvailability = userChannelsAvailability[channelId];
    const channel = channelsById[channelId];

    return (
      <ReadinessLabel
        onClick={() => openModal(<ChangeAvailabilityModal UID={UID} />, 'small')}
        key={channelId}
        type={channelAvailability ? 'active' : 'inactive'}
        text={channel?.channel || ''}
      />
    );
  };

  return (
    <div className="channelsContainer">
      {visibleChannelKeys.map(renderChannelLabel)}

      {hiddenChannelKeys.length > 0 && (
        <Popup
          on="hover"
          hoverable
          position="bottom left"
          className="popupChannels"
          trigger={
            <div className="popupChannelsTrigger">
              <FontAwesomeIcon icon={faEllipsis} />
            </div>
          }
          content={
            <>
              <h3>{t('availability.table.user_channels')}</h3>
              {hiddenChannelKeys.map(renderChannelLabel)}
            </>
          }
        />
      )}
    </div>
  );
};

const defaultTableCellProps = { width: 2, className: 'channelsColumn' };

export default (UID: number, cellProps?: TableCellProps) => ({
  content: <ChannelsAvailabilityCell UID={UID} />,
  cellProps: { ...defaultTableCellProps, ...cellProps }
});
