import { convertPrefixStringToNumber } from '@eeedo/utils';
import { faChevronRight, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { difference } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { UserStatusLog, UserWithProfile } from '@eeedo/types';

import Dropdown from '../generic/Dropdown/Dropdown';
import Input from '../generic/Input/Input';
import ReadinessLabel from '../Management/UsersStatusManagement/ReadinessLabel/ReadinessLabel';
import ChannelsAvailabilityCell from './Cells/ChannelsAvailabilityCell';
import UserNameCell from './Cells/UserNameCell';
import Button from 'src/Components/generic/Button/Button';
import Pagination from 'src/Components/generic/Pagination/Pagination';
import Table from 'src/Components/generic/Table/Table';
import { useAppSelector, useAppThunkDispatch } from 'src/store';
import { Roles } from 'src/types/User';
import { DATE_TIME_FORMAT, getPrettyDate, getRelativeTimeLabel } from 'src/Utilities/dates';
import { useModal } from '../Modal/ModalContext';
import ChangeAvailabilityModal from '../UserStatus/ChangeAvailabilityModal';

import type { HeaderItem, RowItem } from 'src/Components/generic/Table/Table';
import { fetchUserStatusLogs } from 'src/actions/userStatusActions';
import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment';

const paginationSize = 10;

const PerUserTable = () => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const dispatch = useAppThunkDispatch();

  const [expandedRowId, setExpandedRowId] = useState<string | null>(null);
  const [nestedPagination, setNestedPagination] = useState<boolean>()
  const [loading, setLoading] = useState<boolean>(false);
  const [userStatusHistory, setUserStatusHistory] = useState<UserStatusLog[]>([]);
  const [paginationPage, setPaginationPage] = useState<number>(1);
  const [filters, setFilters] = useState<{
    ticketTypes?: number[];
    name?: string;
    units?: string[];
  }>({
    ticketTypes: undefined,
    name: undefined,
    units: undefined
  });

  const users = useAppSelector((state) => state.usersList.usersList.filter((user) => !Roles.isBlocked(user.role.id)));
  const ticketTypes = useAppSelector((state) => state.ticketTypes);
  const onlineStatuses = useAppSelector((state) => state.onlineStatus);
  const userStatuses = useAppSelector((state) => state.userStatusesState.userStatuses);
  const currentUserStatuses = useAppSelector((state) => state.userStatusesState.currentUserStatuses);

  const gerUserUnitsList = useCallback(() => {
    const allUnits = users.map((user) => user.profile.unit).filter((unit) => unit && unit.trim() !== '');
    return allUnits.filter((unit, index) => allUnits.indexOf(unit) === index);
  }, [users]);

  const filteredUsers = useMemo(() => {
    const filteredUsers: UserWithProfile[] = [];
    for (const user of users) {
      if (filters.name) {
        const fullName = `${user.profile.firstName.toLowerCase()} ${user.profile.lastName.toLowerCase()}`;
        if (!fullName.includes(filters.name.toLowerCase())) continue;
      }
      if (filters.ticketTypes?.length && difference(filters.ticketTypes, user.ticketTypes).length > 0) {
        continue;
      }
      if (filters.units?.length && !filters.units.includes(user.profile.unit)) {
        continue;
      }
      filteredUsers.push(user);
    }
    return filteredUsers;
  }, [users, filters]);

  const toggleNestedRow = (id: string) => {
    setNestedPagination(undefined);
    setUserStatusHistory([]);
    setExpandedRowId(expandedRowId === id ? null : id);
  };

  const toggleNestedPagination = () => {
    loadHistory();
  }

  const loadHistory = () => {
    if (expandedRowId) {
      setLoading(true)
      const cursor = userStatusHistory[userStatusHistory.length - 1]?.id;
      dispatch(fetchUserStatusLogs({ UID: convertPrefixStringToNumber(expandedRowId), take: 5, idCursor: cursor }))
        .then(unwrapResult)
        .then((result) => {
          setUserStatusHistory(prev => [...prev, ...result])
          setNestedPagination(!!result.length);
        })
        .finally(() => setLoading(false))
    }
  }

  const getNestedRow = (UID: string, userStatusHistory: UserStatusLog[]) => {
    const nestedHeaders: HeaderItem[] = [
      { key: 'resentStatus', content: t('userStatusManagement.labels.resent_status'), headerCellProps: { width: 3 } },
      { key: 'startedAt', content: t('userStatusManagement.labels.started_at'), headerCellProps: { width: 2 } },
      { key: 'startedBy', content: t('userStatusManagement.labels.started_by'), headerCellProps: { width: 3 } },
      { key: 'stoppedAr', content: t('userStatusManagement.labels.stopped_ar'), headerCellProps: { width: 2 } },
      { key: 'stoppedBy', content: t('userStatusManagement.labels.stopped_by'), headerCellProps: { width: 3 } },
      { key: 'duration', content: t('userStatusManagement.labels.duration'), headerCellProps: { width: 2 } },
    ];

    const nestedData = userStatusHistory.map((history) => {
      const UID = convertPrefixStringToNumber(history.UID);
      const original = userStatuses[history.originalStatus]
      const startedUser = users.find((user) => convertPrefixStringToNumber(user.UID) === history.startedBy);
      const stoppedUser = users.find((user) => convertPrefixStringToNumber(user.UID) === history.stoppedBy);

      return {
        key: `nested-${UID}`,
        cells: [
          {
            content: (
              <ReadinessLabel
                type={original.type === 'ready' ? 'active' : 'inactive'}
                text={history.description || original.text}
                onClick={() => openModal(<ChangeAvailabilityModal UID={UID} />, 'small')}
              />
            )
          },
          { content: (getPrettyDate(history.startedAt, { format: DATE_TIME_FORMAT })) },
          UserNameCell(startedUser!, { className: 'userProfileColumn' }),
          { content: (history.stoppedAt ? getPrettyDate(history.stoppedAt, { format: DATE_TIME_FORMAT }) : '-') },
          stoppedUser ? UserNameCell(stoppedUser!, { className: 'userProfileColumn' }) : { content: '-' },
          { content: (history.duration ? moment.utc(history.duration * 1000).format('HH:mm:ss') : '-') }
        ]
      };
    }) as RowItem[];

    const paginationRow = {
      cells: [
        {
          content: (
            <Button
              onClick={() => toggleNestedPagination()}
              disabled={loading || !nestedPagination}
              isLoading={loading}
            >
              {!nestedPagination ? t('GENERAL_NO_MORE_RECORDS') : t('GENERAL_LOAD_MORE')}
            </Button>
          )
        },
        { content: (<></>) }, { content: (<></>) }, { content: (<></>) }, { content: (<></>) }, { content: (<></>) }
      ]
    }

    const nestedTable = <Table headers={nestedHeaders} data={[...nestedData, paginationRow]} rootTableProps={{ celled: true }} />;

    return {
      key: `expanded-${UID}`,
      cells: [
        {
          content: nestedTable,
          cellProps: { colSpan: 16 }
        }
      ],
      rowProps: { className: 'accordionRow' }
    };
  };

  const renderUsers = useCallback(() => {
    const rows: RowItem[] = [];
    filteredUsers.slice((paginationPage - 1) * paginationSize, paginationPage * paginationSize).map((user) => {
      const UID = convertPrefixStringToNumber(user.UID);
      const currentUserStatus = currentUserStatuses[UID];
      const userStatus = userStatuses[currentUserStatus.originalStatus];
      const onlineStatus = onlineStatuses[UID];

      const mainRow = {
        cells: [
          {
            content: (
              <Button className="chevronButton" onClick={() => toggleNestedRow(user.UID)}>
                <FontAwesomeIcon icon={expandedRowId === user.UID ? faChevronDown : faChevronRight} />
              </Button>
            )
          },
          UserNameCell(user),
          {
            content: (
              <ReadinessLabel
                type={userStatus.type === 'ready' ? 'active' : 'inactive'}
                text={currentUserStatus.description || userStatus.text}
                onClick={() => openModal(<ChangeAvailabilityModal UID={UID} />, 'small')}
              />
            )
          },
          ChannelsAvailabilityCell(UID),
          { content: <>{getRelativeTimeLabel(currentUserStatus.updated)}</> },
          {
            content: <ReadinessLabel
              type={onlineStatus === 'online' ? 'active' : 'inactive'}
              text={onlineStatus}
              onClick={() => openModal(<ChangeAvailabilityModal UID={UID} />, 'small')} />
          }
        ]
      } as RowItem;
      rows.push(mainRow);
      if (expandedRowId === user.UID) {
        rows.push(getNestedRow(user.UID, userStatusHistory));
      }
    });
    return rows;
  }, [currentUserStatuses, userStatuses, users, onlineStatuses, filters, paginationPage, userStatusHistory]);

  useEffect(() => {
    if (expandedRowId) { loadHistory() }
    return () => {
      setUserStatusHistory([]);
      setNestedPagination(true);
    }
  }, [expandedRowId, currentUserStatuses]);

  return (
    <>
      <div className="availabilityFilters">
        <Dropdown
          selection
          search={true}
          multiple={true}
          clearable={true}
          placeholder={t('TICKET_TYPES')}
          onChange={(_, data) => setFilters((prevValue) => ({ ...prevValue, ticketTypes: data.value as number[] }))}
          options={ticketTypes.map((ticketType) => ({ text: ticketType.name, value: ticketType.id }))}
        />

        <Dropdown
          selection
          search={true}
          multiple={true}
          clearable={true}
          placeholder={t('SEARCH_FILTER_UNIT')}
          options={gerUserUnitsList().map((unit) => ({ text: unit, value: unit }))}
          onChange={(_, data) => setFilters((prevValue) => ({ ...prevValue, units: data.value as string[] }))}
        />

        <Input
          placeholder={t('GENERAL_NAME')}
          value={filters.name}
          onChange={(_, data) => setFilters((prevValue) => ({ ...prevValue, name: data.value }))}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', clear: 'both' }}>
        <Table
          headers={[
            { key: 'actions', content: t('GENERAL_ACTIONS'), headerCellProps: { width: 1 } },
            { key: 'name', content: t('GENERAL_NAME'), headerCellProps: { width: 2 } },
            { key: 'status', content: t('GENERAL_STATUS'), headerCellProps: { width: 2 } },
            { key: 'channels', content: t('SETTINGS_TITLE_ADMIN_MANAGE_CHANNELS'), headerCellProps: { width: 7 } },
            { key: 'duration', content: t('availability.labels.duration_of_status'), headerCellProps: { width: 2 } },
            { key: 'onlineStatus', content: t('availability.labels.online_status'), headerCellProps: { width: 2 } }
          ]}
          stickyHeader={true}
          data={renderUsers()}
          rootTableProps={{ celled: true, striped: true }}
        />

        <div style={{ margin: '10px auto 0' }}>
          <Pagination
            itemsLength={filteredUsers.length}
            activePage={paginationPage}
            onPageChange={(activePage) => setPaginationPage(activePage)}
          />
        </div>
      </div>
    </>
  );
};

export default PerUserTable;
