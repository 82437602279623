import { ContentNamingType, convertTicketId } from '@eeedo/utils';
import Fuse from 'fuse.js';
import { phone } from 'phone';
import { useMemo } from 'react';

import type { Moment } from 'moment';

import { getSearchCommandsOptions, searchTicketOptions } from './searchOptions';
import useCommands from './useCommands';
import { getExtendedSearchText, mapTicketToSearch, matchDate, removeParamsFromSearchText } from './utils';
import EnvSettings from 'src/api/EnvSettings';
import { useAppSelector } from 'src/store';
import { StaticTabs } from 'src/types/TicketList';
import { DATE_FORMAT } from 'src/Utilities/dates';
import { COUNTRY_ISO3 } from 'src/Utilities/normalizeNumber';
import { parseEmailFragment, parseTicketNumber } from 'src/Utilities/parseUtils';

import type { TicketSearch } from './types';
import type { CommandPrompt } from './useCommands';
import type { State } from 'src/types/initialState';

interface MatchedSearches {
  date: Moment | null;
  email: string | null;
  phoneNumber: string | null;
  text: string | null;
  ticketId: string | null;
}

interface SearchResults {
  matchedFromTicketList: TicketSearch[];
  matchedFromOpenTickets: TicketSearch[];
  matchedCommandPrompts: CommandPrompt[];
  matchedCreatedFromTicketList: TicketSearch[];
  matchedDueDateFromTicketList: TicketSearch[];
}

interface SearchParams {
  selectedTag?: string;
  selectedTicketType?: string;
}

type UseSearch = (searchText: string, params: SearchParams) => [MatchedSearches, SearchResults];

const useSearch: UseSearch = (searchText = '', params = {}) => {
  const { selectedTag, selectedTicketType } = params;
  const openTicketIds = useAppSelector((state: State) => state.ticketTabs.map((tab) => tab.id));
  const tickets = useAppSelector((state: State) =>
    state.ticketListTabs[StaticTabs.MAIN_VIEW].tickets.toReversed().map(mapTicketToSearch())
  );

  const filteredTickets = useMemo(
    () =>
      tickets.filter(
        (ticket) =>
          (!selectedTag || ticket.tags?.includes(selectedTag)) &&
          (!selectedTicketType || ticket.taskType === selectedTicketType)
      ),
    [tickets, selectedTag, selectedTicketType]
  );

  const [commandPrompts] = useCommands();
  const isCommand = searchText.startsWith('>');
  const openTickets = filteredTickets.filter(({ id }) => openTicketIds.includes(id));
  const fuseTickets = useMemo(() => new Fuse(filteredTickets, searchTicketOptions), [filteredTickets]);
  const fuseOpenTickets = useMemo(() => new Fuse(openTickets, searchTicketOptions), [openTickets]);
  const fuseCommands = useMemo(
    () => new Fuse(commandPrompts, getSearchCommandsOptions(isCommand)),
    [commandPrompts, isCommand]
  );

  const matchedSearches: MatchedSearches = {
    date: null,
    email: null,
    phoneNumber: null,
    text: null,
    ticketId: null
  };

  // Search by ticketId
  const parsedTicketNumber = parseTicketNumber(searchText);
  const matchedTicketId =
    parsedTicketNumber && convertTicketId<ContentNamingType.String>(parsedTicketNumber, ContentNamingType.String);
  if (matchedTicketId) {
    matchedSearches.ticketId = matchedTicketId;
  }

  // Search by Email
  const parsedEmail = parseEmailFragment(searchText);
  if (parsedEmail) {
    matchedSearches.email = parsedEmail;
  }

  // Search by Phone number
  const countryCode = EnvSettings.getSettings().LOCALIZATION.countryIso3 || COUNTRY_ISO3.FIN;
  const parsedPhoneNumber = phone(searchText, { country: countryCode });
  if (parsedPhoneNumber.isValid) {
    matchedSearches.phoneNumber = parsedPhoneNumber.phoneNumber;
  }

  // Search by text
  if (!isCommand) {
    matchedSearches.text = removeParamsFromSearchText(searchText, [matchedTicketId]);
  }

  // Filter tickets
  let matchedFromTicketList = filteredTickets;
  let matchedFromOpenTickets = openTickets;
  if (searchText && !isCommand) {
    const ticketExtendedSearchText = getExtendedSearchText(searchText, [matchedTicketId, parsedEmail]);
    matchedFromTicketList = fuseTickets.search(ticketExtendedSearchText).map(({ item }) => item);
    matchedFromOpenTickets = fuseOpenTickets.search(ticketExtendedSearchText).map(({ item }) => item);
  }

  // Search by date
  const matchedDate = matchDate(searchText);
  let matchedCreatedFromTicketList: TicketSearch[] = [];
  let matchedDueDateFromTicketList: TicketSearch[] = [];
  if (matchedDate) {
    matchedSearches.date = matchedDate;
    const parsedDateFormat = matchedDate.format(DATE_FORMAT);
    matchedCreatedFromTicketList = matchedFromTicketList.filter(({ created }) => created === parsedDateFormat);
    matchedDueDateFromTicketList = matchedFromTicketList.filter(({ dueDate }) => dueDate === parsedDateFormat);
    matchedFromTicketList = [];
    matchedFromOpenTickets = [];
  }

  // Filter commands
  const matchedCommandPrompts: CommandPrompt[] = fuseCommands.search(searchText.trim()).map(({ item }) => item);
  if (isCommand) {
    matchedFromTicketList = [];
    matchedFromOpenTickets = [];
  }

  return [
    matchedSearches,
    {
      matchedFromTicketList,
      matchedFromOpenTickets,
      matchedCommandPrompts,
      matchedCreatedFromTicketList,
      matchedDueDateFromTicketList
    }
  ];
};

export default useSearch;
