import React from 'react';
import { useDispatch } from 'react-redux';

import type { ComponentProps, FC } from 'react';

import TicketListAccordionTitle from '../TicketListAccordionTitle';
import SingleLineActionProgress from './SingleLineActionProgress';
import SingleLineActions from './SingleLineActions';
import SingleLineTable from './SingleLineTable';
import { SingleLineTabProvider } from './SingleLineTabProvider';
import { wrapper } from './SingleLineTicketViewContent.style';
import SingleLineTicketViewModal from './SingleLineTicketViewModal';
import TableTabManage from './TableTabManage';
import { setTicketlistAccordion } from 'src/actions/ticketListTabActionsRTK';
import Section from 'src/Components/generic/Section/Section';
import Tabs from 'src/Components/generic/Tabs/Tabs';
import { resetSelectedTickets } from 'src/reducers/singleLineViewReducer';
import { selectActiveTicketListTab, selectIsInfopage } from 'src/selectors/rootStateSelectors';
import { useAppSelector } from 'src/store';
import { StaticTabs } from 'src/types/TicketList';

import type Dropdown from 'src/Components/generic/Dropdown/Dropdown';
import type { ContentTypesFields, TicketListTicket } from 'src/types/Ticket';

import './SingleLineTicketView.css';
import { setInfoPageListAccordion } from 'src/actions/infoPagesActionsRTK';

export type AccordionTab = {
  index: number;
  title: string;
  status: 'draft' | 'inReview' | 'waitingToBePublished' | 'published' | 'waste' | 'todo' | 'doing' | 'done' | string;
  tickets: TicketListTicket[];
};

interface SingleLineTicketViewContentProps {
  accordionTabs: AccordionTab[];
  orderedTickets: TicketListTicket[];
  dropdownOptions: ComponentProps<typeof Dropdown>['options'];
  contentType: ContentTypesFields;
}

const SingleLineTicketViewContent: FC<SingleLineTicketViewContentProps> = ({
  accordionTabs,
  dropdownOptions,
  orderedTickets,
  contentType
}) => {
  const dispatch = useDispatch();
  const activeTicketListTab = useAppSelector(selectActiveTicketListTab);
  const isInfoPage = useAppSelector(selectIsInfopage);
  const activeTabIndex = activeTicketListTab?.accordionIndex ?? 0;
  const setActiveItem = (tabIndex: string) => {

    const setAccordion = isInfoPage ? setInfoPageListAccordion : setTicketlistAccordion;
    dispatch(
      setAccordion({
        id: activeTicketListTab?.id ?? StaticTabs.MAIN_VIEW,
        accordionIndex: parseInt(tabIndex)
      })
    );
    dispatch(resetSelectedTickets());
  };
  const tickets = accordionTabs.find((tab) => tab.index === activeTabIndex)?.tickets ?? [];

  return (
    <SingleLineTabProvider>
      <div style={wrapper}>
        <Tabs
          activeItem={activeTabIndex.toString()}
          setActiveItem={setActiveItem}
          fluid={false}
          widths={undefined}
          items={accordionTabs.map((tab) => ({
            key: tab.index.toString(),
            title: <TicketListAccordionTitle title={tab.title} count={tab.tickets.length} />
          }))}
        />

        <Section justify="flex-end" gap={12} style={{ paddingBottom: '14px' }}>
          <TableTabManage />
          <SingleLineActions options={dropdownOptions} />
          <SingleLineTicketViewModal orderedTickets={orderedTickets} contentType={contentType} />
          <SingleLineActionProgress />
        </Section>
      </div>

      <SingleLineTable tickets={tickets} contentType={contentType} />
    </SingleLineTabProvider>
  );
};

export default React.memo(SingleLineTicketViewContent);
