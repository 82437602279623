import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DropdownItem } from 'semantic-ui-react';

import type { Moment } from 'moment';
import type { FC } from 'react';

import SearchByEntityField from './SearchByEntityField';
import SearchOmnibarBox from './SearchOmnibarBox';
import SearchOmnibarFilters from './SearchOmnibarFilters';
import SearchOmnibarItem from './SearchOmnibarItem';
import useSearch from './useSearch';
import { fireTicketSearch } from 'src/actions/ticketsActions';
import { DATE_FORMAT } from 'src/Utilities/dates';
import { getSearchFieldTranslation } from 'src/Utilities/search';

import type { CommandPrompt } from './useCommands';
import type { SearchCriterion } from 'src/types/Search';

const TICKET_RESULTS = 5;

interface Props {
  onClearSearch: () => void;
  onClickCommand: (command: CommandPrompt) => (event: React.MouseEvent<HTMLDivElement>) => void;
  onClickTicket: (ticketId: string | number) => (event: React.MouseEvent<HTMLDivElement>) => void;
  searchQuery: string;
}

const SearchOmnibarResults: FC<Props> = ({ onClearSearch, onClickCommand, onClickTicket, searchQuery }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedTag, setSelectedTag] = useState<string | undefined>(undefined);
  const [selectedTicketType, setSelectedTicketType] = useState<string | undefined>(undefined);
  const [matchedSearches, searchResults] = useSearch(searchQuery, { selectedTag, selectedTicketType });
  const {
    matchedFromTicketList,
    matchedFromOpenTickets,
    matchedCommandPrompts,
    matchedCreatedFromTicketList,
    matchedDueDateFromTicketList
  } = searchResults;

  const onClickSearch = (value: string, param: SearchCriterion['param'] | SearchCriterion['param'][]) => () => {
    const params = Array.isArray(param) ? param : [param];

    onSearch(
      params.map((param) => {
        const fieldTranslation = getSearchFieldTranslation(param, t);
        return {
          datagroup: 'basic',
          name: fieldTranslation,
          param,
          value
        };
      })
    );
  };

  const onSearch = (searchCriterion: SearchCriterion | SearchCriterion[]) => {
    const searchCriteria = Array.isArray(searchCriterion) ? searchCriterion : [searchCriterion];
    dispatch(fireTicketSearch({ searchCriteria }));
    onClearSearch();
  };

  const onClickSearchByDate =
    (date: Moment, paramAfter: SearchCriterion['param'], paramBefore: SearchCriterion['param']) => () => {
      dispatch(
        fireTicketSearch({
          searchCriteria: [
            {
              datagroup: 'basic',
              param: paramAfter,
              value: date.startOf('day').unix(),
              text: date.startOf('day').toString(),
              name: getSearchFieldTranslation(paramAfter, t)
            },
            {
              datagroup: 'basic',
              param: paramBefore,
              value: date.endOf('day').unix(),
              text: date.endOf('day').toString(),
              name: getSearchFieldTranslation(paramBefore, t)
            }
          ]
        })
      );
      onClearSearch();
    };

  return (
    <>
      <SearchOmnibarFilters
        selectedTag={selectedTag}
        setSelectedTag={setSelectedTag}
        selectedTicketType={selectedTicketType}
        setSelectedTicketType={setSelectedTicketType}
      />

      {!!matchedSearches.date && (
        <>
          <SearchOmnibarItem
            label={t('search_and_command_palette.search_tickets_with_duedate_on')}
            searchText={matchedSearches.date.format(DATE_FORMAT)}
            onClick={onClickSearchByDate(matchedSearches.date!, 'duedateAfter', 'duedateBefore')}
          />
          <SearchOmnibarItem
            label={t('search_and_command_palette.search_tickets_with_created_on')}
            searchText={matchedSearches.date.format(DATE_FORMAT)}
            onClick={onClickSearchByDate(matchedSearches.date!, 'createdAfter', 'createdBefore')}
          />
        </>
      )}

      {!!matchedSearches.ticketId && (
        <SearchOmnibarItem
          icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} className="icon" />}
          onClick={onClickTicket(matchedSearches.ticketId!)}
          label={t('search_and_command_palette.open_ticket')}
          searchText={matchedSearches.ticketId}
        />
      )}

      {!!matchedSearches.email && (
        <>
          <SearchOmnibarItem
            label={t('search_and_command_palette.search_tickets_by_original_contact')}
            searchText={matchedSearches.email}
            onClick={onClickSearch(matchedSearches.email!, 'originalContactSearch')}
          />
          <SearchOmnibarItem
            label={t('search_and_command_palette.search_tickets_by_last_contact')}
            searchText={matchedSearches.email}
            onClick={onClickSearch(matchedSearches.email!, 'lastContactAddress')}
          />
          <SearchOmnibarItem
            label={t('search_and_command_palette.search_tickets_by_recipient')}
            searchText={matchedSearches.email}
            onClick={onClickSearch(matchedSearches.email!, ['emailTo', 'emailFrom', 'emailCc', 'emailBcc'])}
          />
        </>
      )}

      {!!matchedSearches.phoneNumber && (
        <>
          <SearchOmnibarItem
            label={t('search_and_command_palette.search_tickets_by_original_contact')}
            searchText={matchedSearches.phoneNumber}
            onClick={onClickSearch(matchedSearches.phoneNumber!, 'originalContactSearch')}
          />
          <SearchOmnibarItem
            label={t('search_and_command_palette.search_tickets_by_last_contact')}
            searchText={matchedSearches.phoneNumber}
            onClick={onClickSearch(matchedSearches.phoneNumber!, 'lastContactAddress')}
          />
        </>
      )}

      <SearchByEntityField
        selectedTicketType={selectedTicketType}
        email={matchedSearches.email}
        phoneNumber={matchedSearches.phoneNumber}
        text={matchedSearches.text}
        onSearch={onSearch}
      />

      {!!matchedSearches.text && !matchedSearches.date && (
        <>
          <SearchOmnibarItem
            label={t('search_and_command_palette.search_tickets_by_title')}
            searchText={matchedSearches.text}
            onClick={onClickSearch(matchedSearches.text!, 'titleSearch')}
          />
          <SearchOmnibarItem
            label={t('search_and_command_palette.search_tickets_by_content')}
            searchText={matchedSearches.text}
            onClick={onClickSearch(matchedSearches.text!, 'selectedTicketWords')}
          />
        </>
      )}

      <div className="command_palette_box_wrapper">
        {!!matchedCreatedFromTicketList.length && !!matchedSearches.date && (
          <SearchOmnibarBox
            title={`${t('search_and_command_palette.from_ticket_list_with_created_on')}: ${matchedSearches.date.format(
              DATE_FORMAT
            )}`}
            fluid={!matchedDueDateFromTicketList.length}
            results={matchedCreatedFromTicketList.length}
            tickets={matchedCreatedFromTicketList.slice(0, TICKET_RESULTS)}
            onClickTicket={onClickTicket}
          />
        )}

        {!!matchedDueDateFromTicketList.length && !!matchedSearches.date && (
          <SearchOmnibarBox
            title={`${t('search_and_command_palette.from_ticket_list_with_duedate_on')}: ${matchedSearches.date.format(
              DATE_FORMAT
            )}`}
            fluid={!matchedCreatedFromTicketList.length}
            results={matchedDueDateFromTicketList.length}
            tickets={matchedDueDateFromTicketList.slice(0, TICKET_RESULTS)}
            onClickTicket={onClickTicket}
          />
        )}

        {!!matchedFromTicketList.length && (
          <SearchOmnibarBox
            title={t('search_and_command_palette.from_ticket_list')}
            results={matchedFromTicketList.length}
            fluid={!matchedFromOpenTickets.length}
            tickets={matchedFromTicketList.slice(0, TICKET_RESULTS)}
            onClickTicket={onClickTicket}
          />
        )}

        {!!matchedFromOpenTickets.length && (
          <SearchOmnibarBox
            title={t('search_and_command_palette.from_opened_tickets')}
            results={matchedFromOpenTickets.length}
            tickets={matchedFromOpenTickets.slice(0, TICKET_RESULTS)}
            onClickTicket={onClickTicket}
          />
        )}

        {!!matchedCommandPrompts.length && (
          <SearchOmnibarBox
            title={t('Commands')}
            commands={matchedCommandPrompts}
            onClickCommand={onClickCommand}
            fluid
          />
        )}
      </div>

      {!matchedFromTicketList.length && !matchedFromOpenTickets.length && !matchedCommandPrompts.length && (
        <DropdownItem disabled>{t('PLACEHOLDER_SEARCH_NO_RESULTS')}</DropdownItem>
      )}
    </>
  );
};

export default SearchOmnibarResults;
