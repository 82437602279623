import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faClipboardUser, faHeadset, faTicket } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { Divider } from 'semantic-ui-react';

import PerChannelTable from './PerChannelTable';
import PerStatusTable from './PerStatusTable';
import PerTicketTypeTable from './PerTicketTypeTable';
import PerUserTable from './PerUserTable';
import Tabs from 'src/Components/generic/Tabs/Tabs';

import './AvailabilityPage.css';

const keyTabRelation = {
  user: <PerUserTable />,
  channel: <PerChannelTable />,
  status: <PerStatusTable />,
  ticketType: <PerTicketTypeTable />
};

const AvailabilityPage = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('user');

  const renderTable = useCallback(() => keyTabRelation[activeTab] || null, [activeTab]);

  return (
    <Scrollbars style={{ top: '12px', bottom: '12px' }}>
      <div className="page_wrapper availabilityPage">
        <div className="page_header">
          <div className="page_header--text">
            <h2>{t('availability.labels.dashboard_title')}</h2>
            <p>{t('availability.labels.dashboard_description')}</p>
          </div>
        </div>
        <Divider section />

        <Tabs
          fluid={false}
          activeItem={activeTab}
          setActiveItem={setActiveTab}
          className="eeedoTabs availabilityTabs"
          widths={undefined}
          items={[
            {
              key: 'user',
              title: (
                <>
                  <FontAwesomeIcon icon={faUser} /> <span>{t('availability.tabs.user')}</span>
                </>
              )
            },
            {
              key: 'channel',
              title: (
                <>
                  <FontAwesomeIcon icon={faHeadset} /> <span>{t('availability.tabs.channel')}</span>
                </>
              )
            },
            {
              key: 'status',
              title: (
                <>
                  <FontAwesomeIcon icon={faClipboardUser} /> <span>{t('availability.tabs.status')}</span>
                </>
              )
            },
            {
              key: 'ticketType',
              title: (
                <>
                  <FontAwesomeIcon icon={faTicket} /> <span>{t('availability.tabs.ticketType')}</span>
                </>
              )
            }
          ]}
        />

        {renderTable()}
      </div>
    </Scrollbars>
  );
};

export default AvailabilityPage;
