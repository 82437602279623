import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Icon, Menu } from 'semantic-ui-react';

import type { SyntheticEvent } from 'react';
import type { MenuItemProps } from 'semantic-ui-react';

import type { TicketTab } from 'src/reducers/tabsReducer';
import type { TicketListTab } from 'src/types/TicketList';

interface TabProps {
  tab: TicketTab | TicketListTab;
  cname: string;
  isMain?: boolean;
  refreshDisabled?: boolean;
  children?: React.ReactNode;

  onClick: MenuItemProps['onClick'];
  onRefreshClick?: React.EventHandler<SyntheticEvent>;
  onCloseClick?: React.EventHandler<SyntheticEvent>;
}

const TabBarTab: React.FC<TabProps> = ({
  tab,
  cname,
  isMain,
  refreshDisabled,
  onClick,
  onRefreshClick,
  onCloseClick,
  children
}) => (
  <Menu.Item onClick={onClick} className={cname} icon={true} active={tab.activeTab} id={tab.title}>
    {children}
    <span>
      {isMain && (
        <Icon
          className="TabBar-Refresh-Icon"
          name="sync alternate"
          onClick={onRefreshClick}
          style={refreshDisabled ? { color: 'grey' } : {}}
        />
      )}

      {!isMain && <FontAwesomeIcon className="TabBar-Delete-Icon" onClick={onCloseClick} icon={faXmark} />}
    </span>
  </Menu.Item>
);

export default TabBarTab;
