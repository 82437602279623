import classNames from 'classnames';
import React, { Component } from 'react';

import type { PersonalData } from '@eeedo/types';
import type { History } from 'history';

import MainView from './MainView';
import ApiConfig from 'src/api/ApiConfig';
import FeatureFlags from 'src/api/FeatureFlags';
import UserTimeLogs from 'src/api/UserTimeLog/UserTimeLogs';
import Layouts from 'src/Components/Layout/Layouts';
import ErrorBoundary from 'src/ErrorBoundary';
import RedirectRole from 'src/RedirectRole';

import '../../../../../node_modules/semantic-ui-css/semantic.min.css';
import './Main.css';

import { connect } from 'react-redux';

import type { ConnectedProps } from 'react-redux';

import { initUserStatuses } from 'src/reducers/userStatusReducer';

import type { State } from 'src/types/initialState';

interface MainProps extends ConnectedProps<typeof connector> {
  history: History;
  location: Location;
  mobileMode: boolean;
  leftTabOpen: boolean;
  userPersonalData: PersonalData;
}

const hideTicketListPaths = ['/settings', '/entities', '/infopage'];

class Main extends Component<MainProps> {
  constructor(props: MainProps) {
    super(props);
  }

  private leavePromptEnabled(): boolean {
    return (
      FeatureFlags.isFlagOn('ENABLE_USER_TIME_LOGGING') &&
      process.env.NODE_ENV !== 'development' &&
      !localStorage.getItem('__disableLeavePrompt')
    );
  }

  /**
   * Prompt message on leaging or refreshing page
   * sending time active log to database
   * @param e
   */
  private handleLeavePage(e: BeforeUnloadEvent) {
    UserTimeLogs.finishCurrentLogSession();
    // https://stackoverflow.com/questions/40570164/how-to-customize-the-message-changes-you-made-may-not-be-saved-for-window-onb
    const confirmationMessage = 'Are you sure ?';
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  }

  componentDidMount() {
    this.props.initUserStatuses();
    // Alert on closing or refreshing page
    if (this.leavePromptEnabled()) {
      window.addEventListener('beforeunload', this.handleLeavePage);
    }
  }

  componentWillUnmount() {
    // Alert on closing or refreshing page
    if (this.leavePromptEnabled()) {
      window.removeEventListener('beforeunload', this.handleLeavePage);
    }
  }

  render() {
    const { location, leftTabOpen, mobileMode, userPersonalData } = this.props;
    const hideTicketList = hideTicketListPaths.some((path) => location.pathname.includes(path));
    const mobileSideMenuClass = classNames({ hiddenContainer: !leftTabOpen || hideTicketList });
    const mobileContentClass = classNames({
      detailViewMobile: true,
      hiddenContainer: leftTabOpen && !hideTicketList
    });

    return (
      <ErrorBoundary>
        {ApiConfig.getConfig().REDIRECT_ROLE6 !== undefined && <RedirectRole />}

        <Layouts mobileMode={mobileMode}>
          <MainView
            mobileMode={mobileMode}
            hideTicketList={hideTicketList}
            mobileSideMenuClass={mobileSideMenuClass}
            mobileContentClass={mobileContentClass}
            userPersonalData={userPersonalData}
          />
        </Layouts>
      </ErrorBoundary>
    );
  }
}

const connector = connect(
  (state: State) => ({
    leftTabOpen: state.mobileReducer.leftTabOpen,
    rightTabOpen: state.mobileReducer.rightTabOpen,
    mobileMode: state.mobileReducer.mobileMode,
    userPersonalData: state.userData
  }),
  { initUserStatuses }
);

export default connector(Main);
