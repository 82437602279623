import { Direction, SortBy } from '@eeedo/types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AutoSizer, Column, Table as VirtualizedTable } from 'react-virtualized';
import { Checkbox, Icon, Label } from 'semantic-ui-react';

import type { MouseEvent } from 'react';
import type { ConnectedProps } from 'react-redux';
import type { TableCellProps } from 'react-virtualized';
import type { HeaderMouseEventHandlerParams } from 'react-virtualized/dist/es/Table';
import type { SemanticICONS } from 'semantic-ui-react';

import { setTicketlistSorting } from '../../../actions/ticketListTabActionsRTK';
import TicketTags from '../TicketListItem/TicketTags';
import * as styles from './SingleLineAccordionTab.style';
import { useSingleLineTabContext } from './SingleLineTabProvider';
import { openTicketTab } from 'src/actions/ticketsActions';
import WorkStatusImageContainer from 'src/containers/WorkStatusImageContainer';
import { setAllSelectedTickets, setSelectedTicket } from 'src/reducers/singleLineViewReducer';
import { selectActiveTicketListTab } from 'src/selectors/rootStateSelectors';
import { useAppSelector } from 'src/store';
import { filterNonDefaultTags } from 'src/types/Tag';
import { channelIconsMap } from 'src/Utilities/constants';
import { DATE_TIME_FORMAT, getPrettyDate } from 'src/Utilities/dates';
import { getTags } from 'src/Utilities/tags';
import { coloredTagsFirst } from 'src/Utilities/ticketList';

import type { State } from 'src/types/initialState';
import type { ContentTypesFields, TicketListTicket } from 'src/types/Ticket';

interface SingleLineAccordionTabProps extends ConnectedProps<typeof connector> {
  tickets: TicketListTicket[];
  contentType: ContentTypesFields;
}

const SingleLineAccordionTab: React.FC<SingleLineAccordionTabProps> = ({
  tickets,
  priorities,
  user,
  userData,
  selected,
  contentType
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { tabsData } = useSingleLineTabContext();
  const allTags = useAppSelector((state) => state.tags);
  const sortedTags = coloredTagsFirst(filterNonDefaultTags(allTags));
  const ticketIds = useMemo(() => tickets.map((ticket) => ticket.id), [tickets]);
  const activeTicketTab = useAppSelector(selectActiveTicketListTab);
  const { sorting, direction } = activeTicketTab ?? {};

  const onCheckboxChange = (id: string, checked: boolean) => {
    dispatch(setSelectedTicket({ id, checked }));
  };

  const onCheckboxHeaderChange = (checked: boolean) => {
    const ticketIds = tickets.map((ticket) => ticket.id);
    dispatch(setAllSelectedTickets({ ids: ticketIds, checked }));
  };

  const onClickTicketTitle = (ticket: TicketListTicket) => (event: MouseEvent) => {
    const { ctrlKey } = event;

    if (ctrlKey) {
      dispatch(openTicketTab({ contentId: ticket.id, contentType, shouldActivateTicket: !ctrlKey }));
    } else {
      history.push(contentType === 'infopages' ? `/infopage/${ticket.id}` : `/case/${ticket.id}`);
    }
  };

  const onHeaderClick = ({ dataKey }: HeaderMouseEventHandlerParams) => {
    if (Object.values(SortBy).includes(dataKey as SortBy)) {
      const newDirection = sorting === dataKey && direction === Direction.ASC ? Direction.DESC : Direction.ASC;
      dispatch(setTicketlistSorting(dataKey as SortBy, newDirection));
    }
  };

  const renderCheckboxHeader = () => {
    const checked = !!ticketIds.length && ticketIds.every((id) => selected[id]);
    return <Checkbox checked={checked} onChange={(_, data) => onCheckboxHeaderChange(!!data.checked)} />;
  };

  const renderCheckboxCell = ({ rowData }: TableCellProps) => {
    return (
      <Checkbox checked={!!selected[rowData.id]} onChange={(_, data) => onCheckboxChange(rowData.id, data.checked!)} />
    );
  };
  const commonColumnProps = () => ({
    headerStyle: styles.headerCell,
    style: styles.columnCell
  });

  // Config for rendering columns
  const columnConfig = {
    taskType: {
      label: t('GENERAL_TICKET_TYPE'),
      width: 300,
      flexGrow: 1,
      flexShrink: 1,
      cellRenderer: ({ cellData }: TableCellProps) => <Label>{cellData}</Label>
    },
    title: {
      label: t('GENERAL_TITLE'),
      width: 300,
      flexGrow: 2,
      flexShrink: 1,
      cellRenderer: ({ cellData, rowData }: TableCellProps) => (
        <span onClick={onClickTicketTitle(rowData)} style={{ cursor: 'pointer' }}>
          {cellData}
        </span>
      )
    },
    created: {
      label: t('GENERAL_CREATED'),
      width: 150,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ cellData }: TableCellProps) => (
        <div>{getPrettyDate(cellData, { format: DATE_TIME_FORMAT }) || '-'}</div>
      )
    },
    dueDate: {
      label: t('GENERAL_DUE_DATE'),
      width: 150,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ cellData }: TableCellProps) => (
        <div>{getPrettyDate(cellData, { format: DATE_TIME_FORMAT }) || '-'}</div>
      )
    },
    channel: {
      label: t('CASE_CHANNEL'),
      width: 100,
      flexGrow: 0,
      flexShrink: 0,
      cellRenderer: ({ cellData }: TableCellProps) => (
        <div style={styles.channelIconWrapper}>
          <Icon name={channelIconsMap[cellData]} />
        </div>
      )
    },
    priority: {
      label: t('CASE_PRIORITY'),
      width: 200,
      flexGrow: 1,
      flexShrink: 1,
      cellRenderer: ({ cellData }: TableCellProps) => {
        const priority = priorities.find((p) => p.value === cellData);
        const iconStyle = { color: priority?.color };
        return (
          priority && (
            <div style={styles.priorityIconWrapper}>
              <Icon name={priority.icon as SemanticICONS} style={iconStyle} />
              {t(priority.text)}
            </div>
          )
        );
      }
    },
    tags: {
      label: t('CASE_TITLE_TAGS'),
      width: 300,
      flexGrow: 1,
      flexShrink: 1,
      cellRenderer: ({ cellData: ticketTags }: TableCellProps) => <TicketTags tags={getTags(ticketTags, sortedTags)} />
    },
    actions: {
      label: t('GENERAL_ACTIONS'),
      width: 100,
      flexGrow: 0,
      flexShrink: 0,
      cellRenderer: ({ rowData }: TableCellProps) => (
        <WorkStatusImageContainer
          user={user}
          id={rowData.id}
          userData={userData}
          showStartWorkingOnButton={rowData.status === 'todo'}
        />
      )
    }
  };

  return (
    <div style={styles.tableWrapper}>
      <AutoSizer>
        {({ width, height }) => (
          <VirtualizedTable
            width={width}
            sortBy={sorting}
            sortDirection={direction}
            onHeaderClick={onHeaderClick}
            height={height}
            headerHeight={44}
            rowHeight={52}
            rowCount={tickets.length}
            rowGetter={({ index }) => tickets[index]}
          >
            <Column
              dataKey="checkbox"
              width={25}
              flexShrink={0}
              flexGrow={0}
              headerRenderer={renderCheckboxHeader}
              cellRenderer={renderCheckboxCell}
              {...commonColumnProps()}
            />
            {tabsData
              .filter((tab) => tab.show)
              .map((tab) => {
                const config = columnConfig[tab.dataKey];
                if (!config) return null;
                return (
                  <Column
                    key={tab.dataKey}
                    label={t(config?.label)}
                    dataKey={tab.dataKey}
                    width={config.width}
                    flexGrow={config.flexGrow}
                    flexShrink={config.flexShrink}
                    cellRenderer={config.cellRenderer}
                    headerStyle={styles.headerCell}
                    style={styles.columnCell}
                  />
                );
              })}
          </VirtualizedTable>
        )}
      </AutoSizer>
    </div>
  );
};

const connector = connect((state: State) => {
  const activeTab = selectActiveTicketListTab(state);

  return {
    activeIndex: activeTab?.accordionIndex,
    priorities: state.ticketPriorities,
    user: state.usersList.usersList.find((user) => user.UID === state.userData.UID)!,
    userData: state.userData,
    selected: state.singleLineView.selected
  };
});

export default connector(SingleLineAccordionTab);
