import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import type { FC } from 'react';
import type { MultiValueProps, Props, ValueType } from 'react-select';

import { defaultCreatableSelectStyles } from '../Utilities';
import { MultiDraggableDropdownContext } from './MultiDraggableDropdownProvider';

import type { IItem } from './types';

import './MultiDraggableDropdown.css';

import { useDroppable } from '@dnd-kit/core';

interface IProps extends Props<IItem, boolean> {
  name: string; // The key for itemsCollection
}

function DraggableMultiValue(props: MultiValueProps<IItem>) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props.data.id!
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: isDragging ? 0.6 : 1
  };

  const innerProps = { ...props.innerProps };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <components.MultiValue {...props} innerProps={innerProps} />
    </div>
  );
}

const MultiDraggableDropdown: FC<IProps> = ({ onCreateOption, name, options, ...props }) => {
  const context = useContext(MultiDraggableDropdownContext);
  const [inputValue, setInputValue] = useState('');

  const { setNodeRef } = useDroppable({ id: name });
  if (!context) {
    return null;
  }
  const { isDragging, itemsCollection, targetArea, setItemsCollection } = context;
  const handleChange = (value: ValueType<IItem, boolean>) => {
    setItemsCollection({
      ...itemsCollection,
      [name]: (value ?? []) as IItem[]
    });
    setInputValue('');
  };

  const className = classNames('multiDraggableDropdown', isDragging && 'dropzone', targetArea === name && 'active');
  return (
    <div ref={setNodeRef} className={className}>
      <SortableContext id={name} items={(itemsCollection[name] || []).map((i) => i.id!)}>
        <CreatableSelect
          isMulti
          options={options}
          value={itemsCollection[name]}
          inputValue={inputValue}
          onInputChange={(val, { action }) => {
            if (action === 'input-change') {
              setInputValue(val);
            }
          }}
          onChange={handleChange}
          closeMenuOnSelect={false}
          onCreateOption={onCreateOption}
          components={{ MultiValue: DraggableMultiValue }}
          styles={defaultCreatableSelectStyles}
          {...props}
        />
      </SortableContext>
    </div>
  );
};

export default MultiDraggableDropdown;
