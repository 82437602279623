import type { AiFunction } from '@eeedo/types';

import { apiClient } from 'src/Utilities/httpClients';

export default class ApiFunctionApi {
  static fetch = async () => {
    return apiClient.get<AiFunction[]>('/aifunctions').then((response) => response.data);
  };

  static fetchById = async (id: number) => {
    return apiClient.get<AiFunction>(`/aifunctions/${id}`).then((response) => response.data);
  };

  static patch = async (data: { id: number; payload: Partial<Omit<AiFunction, 'id'>> }) => {
    return apiClient.patch<AiFunction>(`/aifunctions/${data.id}`, data.payload).then((response) => response.data);
  };

  static create = async (data: Partial<Omit<AiFunction, 'id'>>) => {
    return apiClient.post<AiFunction>('/aifunctions', data).then((response) => response.data);
  };

  static delete = async (id: number) => {
    return apiClient.delete<{ success: boolean }>(`/aifunctions/${id}`).then((response) => response.data);
  };
}
