import classNames from 'classnames';
import { throttle } from 'lodash-es';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

import type { FC, PropsWithChildren, ReactNode } from 'react';

import styles from './Sticky.module.css';

interface StickyContextProps {
  currentWrapper: HTMLDivElement | null;
}

export const StickyContext = createContext<StickyContextProps>({
  currentWrapper: null
});

interface Props {
  header?: ReactNode;
}

const MIN_HEIGHT = 200;
const OFFSET = 0;

const Sticky: FC<PropsWithChildren<Props>> = ({ header, children }) => {
  const { currentWrapper } = useContext(StickyContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const [isSticky, setIsSticky] = useState(false);
  const [isStickyBottom, setIsStickyBottom] = useState(false);
  const [[top, width, height], setDimensions] = useState([0, 0, 0]);
  const containerWithScrollbars = currentWrapper?.querySelector(':scope > div > div') ?? currentWrapper;

  useEffect(() => {
    if (!containerWithScrollbars || !headerRef.current || !containerRef.current) {
      setIsSticky(false);
      setIsStickyBottom(false);
      return;
    }

    const updateDimensions = throttle(() => {
      const { height: containerHeight } = containerRef.current!.getBoundingClientRect();
      const { width: headerWidth, top: headerTop, height: headerHeight } = headerRef.current!.getBoundingClientRect();
      const { top } = containerWithScrollbars.getBoundingClientRect();
      const wrapperTop = top + OFFSET;

      if (containerHeight < MIN_HEIGHT) {
        return;
      }

      setIsSticky(headerTop < wrapperTop);
      setIsStickyBottom(headerTop < wrapperTop - containerHeight + headerHeight);
      setDimensions([wrapperTop, headerWidth, headerHeight]);
    }, 50);

    containerWithScrollbars.addEventListener('scroll', updateDimensions);
    window.addEventListener('resize', updateDimensions);
    return () => {
      containerWithScrollbars.removeEventListener('scroll', updateDimensions);
      window.removeEventListener('resize', updateDimensions);
    };
  }, [containerWithScrollbars]);

  return (
    <div ref={containerRef} className={styles.container}>
      <div ref={headerRef} style={height ? { height } : undefined}>
        <div
          className={classNames('sticky', isSticky && styles.sticky, isStickyBottom && styles.stickyBottom)}
          style={isSticky ? { top, width } : undefined}
        >
          {header}
        </div>
      </div>

      {children}
    </div>
  );
};

export default Sticky;
