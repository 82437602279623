import { convertPrefixStringToNumber } from '@eeedo/utils';
import { createSelector } from 'reselect';

import type { CurrentUserStatus, UserStatus } from '@eeedo/types';

import { userToNumericalId } from 'src/Utilities/users';

import type { State } from 'src/types/initialState';

export const selectCurrentUserStatus = createSelector(
  (state: State) => convertPrefixStringToNumber(state.userData.UID),
  (state: State) => state.userStatusesState,
  (UID, userStatusesState) => {
    const currentUserStatus = userStatusesState.currentUserStatuses[UID];
    return [currentUserStatus, userStatusesState.userStatuses[currentUserStatus.originalStatus]] as [
      CurrentUserStatus,
      UserStatus
    ];
  }
);

export const selectCurrentUserChannelsAvailability = createSelector(
  (state: State) => convertPrefixStringToNumber(state.userData.UID),
  (state: State) => state.channelsAvailability,
  (UID, channelsAvailability) => channelsAvailability[UID]
);

export const selectCurrentReadinessStatus = createSelector(
  (state: State, UID?: string) => convertPrefixStringToNumber(UID ?? state.userData.UID),
  (state: State) => state.userStatusesState,
  (UID, userStatusesState) => {
    const currentUserStatus = userStatusesState.currentUserStatuses[UID];
    const originalStatus = userStatusesState.userStatuses[currentUserStatus.originalStatus];
    return originalStatus.type;
  }
);

export const selectCurrentUserOriginalStatus = createSelector(
  (state: State) => userToNumericalId(state.userData.UID),
  (state: State) => state.userStatusesState.currentUserStatuses,
  (state: State) => state.userStatusesState.userStatuses,
  (UID, currentUserStatuses, userStatuses) => userStatuses[currentUserStatuses[UID]?.originalStatus]
);
