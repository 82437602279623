export interface UIDPreferences<T extends UIDPreferencesData[keyof UIDPreferencesData] = any> {
  // UserPreferences already exist
  id: number;
  UID: number;
  key: UIDPreferencesKeys;
  value: T; // TODO: check real type
  updatedAt: number;
  updatedBy: number;
}

export enum UIDPreferencesKeys {
  isSingleWidgetView = 'isSingleWidgetView',
  isRightSidebarOpen = 'isRightSidebarOpen'
}

export interface UIDPreferencesData {
  [UIDPreferencesKeys.isSingleWidgetView]: { enable: boolean };
  [UIDPreferencesKeys.isRightSidebarOpen]: { enable: boolean };
}
