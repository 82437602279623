import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import type { CSSProperties, FC } from 'react';

import Button from 'src/Components/generic/Button/Button';
import Modal from 'src/Components/generic/Modal/Modal';
import ProgressBar from 'src/Components/generic/ProgressBar/ProgressBar';
import { resetSingleLineViewState } from 'src/reducers/singleLineViewReducer';
import { useAppSelector } from 'src/store';

const styles: CSSProperties = { display: 'flex', justifyContent: 'center' };

const SingleLineActionProgress: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inProgress = Object.entries(useAppSelector((state) => state.singleLineView.inProgress));
  const action = useAppSelector((state) => state.singleLineView.action);

  const completedCount = inProgress.filter(([, v]) => !v).length;
  const progressPercent = Math.round((completedCount * 100) / inProgress.length);

  const onButtonClick = useCallback(() => {
    dispatch(resetSingleLineViewState());
  }, []);

  return (
    <Modal
      open={!!action}
      header={
        <div>
          {t('olt_actions.progress.handling_tickets_action')}: {action}
        </div>
      }
      footer={
        <div style={styles}>
          <Button type="primary" onClick={onButtonClick}>
            {t('OK')}
          </Button>
        </div>
      }
    >
      <ProgressBar percent={progressPercent} progress />

      <h4>
        {t(action!)} {t('olt_actions.progress.done_for')}: {inProgress.length} {t('olt_actions.progress.tickets')}
      </h4>
    </Modal>
  );
};

export default React.memo(SingleLineActionProgress);
