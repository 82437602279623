import { DefaultTicketsOrdering } from '@eeedo/types';
import { ContentNamingType, convertTicketId } from '@eeedo/utils';
import { createSelector } from '@reduxjs/toolkit';
import { orderBy } from 'lodash-es';
import sortBy from 'lodash/sortBy';

import type { Widget } from '@eeedo/types';

import { TemplateType } from 'src/types/Template';
import { StaticTabs } from 'src/types/TicketList';
import { Roles } from 'src/types/User';
import { SINGLE_LINE_VIEW_INFOPAGES_PATH, SINGLE_LINE_VIEW_TICKETS_PATH } from 'src/Utilities/constants';

import type { State } from 'src/types/initialState';
import type { Template, TemplateContentJson } from 'src/types/Template';
import type { Entity } from 'src/types/Ticket';

export const selectActiveTicket = createSelector(
  (state: State) => state.detailedTickets,
  (state: State) => state.activeTicketTab,
  (detailedTickets, acitveTicketTab) => detailedTickets.find((ticket) => ticket.id === acitveTicketTab)
);

export const selectActiveTicketsEntities = createSelector(selectActiveTicket, (ticket) => ticket?.entities);

export const selectSortedEntities = createSelector(selectActiveTicket, (ticket) =>
  sortBy(ticket?.entities, ['_type', '_id']).reduce(
    (entityTypesMap, entity) => ({
      ...entityTypesMap,
      [entity._type]: [...(entityTypesMap[entity._type] ?? []), entity]
    }),
    {} as Record<string, Entity[]>
  )
);

export const selectSortedTags = createSelector(
  (state: State) => state.tags,
  (tags) =>
    orderBy(
      tags.filter(({ id }) => id !== 'TAG1'),
      ['weight'],
      ['desc']
    )
);

export const selectCurrentUser = createSelector(
  (state: State) => state.userData,
  (state: State) => state.usersList,
  (userData, usersList) => usersList.usersList.find((user) => user.UID === userData.UID)
);

export const selectIsCurrentUserAdmin = createSelector(selectCurrentUser, (currentUser) =>
  currentUser?.role.id ? Roles.isAdmin(currentUser.role.id) : false
);

export const selectActiveTicketType = createSelector(
  (state: State) => state.detailedTickets,
  (state: State) => state.activeTicketTab,
  (state: State) => state.ticketTypes,
  (detailedTickets, activeTicketTab, ticketTypes) => {
    const activeTicket = detailedTickets.find((ticket) => activeTicketTab === ticket.id);
    const taskTypeName = activeTicket?.taskType;
    return ticketTypes.find((tt) => tt.name === taskTypeName);
  }
);

export const selectRescheduleWidgetOptions = createSelector(
  selectActiveTicketType,
  (ticketType) =>
    (ticketType?.widgetOrder.find((widget) => (widget as Widget).name === 'RescheduleCallback') as Widget)?.options
      ?.values
);

export const selectContentTemplates = createSelector(
  (state: State) => state.templates.templates,
  (allTemplates) =>
    allTemplates.filter((template) => template.type === TemplateType.CONTENT) as unknown as (Omit<
      Template,
      'template'
    > & { template: TemplateContentJson })[]
);

export const selectActiveContentTemplates = createSelector(selectContentTemplates, (allTemplates) =>
  allTemplates.filter((template) => template.active)
);

export const selectAllowedContentTemplates = createSelector(
  (state: State) => state.userData.ticketTypes,
  (state: State) => state.ticketTypes,
  selectActiveContentTemplates,
  (userTicketTypes, ticketTypes, activeContentTemplates) => {
    return activeContentTemplates.filter(({ template }) => {
      const templateTicketType = ticketTypes.find((ticketType) => ticketType.name === template.ticketType);

      // normally means ticket type not allowed
      if (!templateTicketType) {
        return false;
      }

      return userTicketTypes.find((ticketTypeId) => {
        return ticketTypeId === templateTicketType.id;
      });
    });
  }
);

export const selectActiveTicketWorkStatus = createSelector(
  (state: State) => state.workStatus,
  (state: State) => state.activeTicketTab,
  (workStatus, ticketId) =>
    workStatus.status.find(
      (status) =>
        ticketId &&
        convertTicketId<number>(status.ticketId, ContentNamingType.Number) ===
          convertTicketId<number>(ticketId, ContentNamingType.Number)
    )
);

export const selectSingleLineViewState = createSelector(
  (state: State) => state.singleLineView,
  (singleLineViewState) => singleLineViewState
);

export const selectSelectedTickets = createSelector(
  selectSingleLineViewState,
  (singleLineViewState) => singleLineViewState.selected
);

export const selectSelectedTicketsCount = createSelector(
  selectSelectedTickets,
  (selected) => Object.values(selected).filter((v) => v).length
);

export const selectIsInfopage = createSelector(
  (state: State) => state?.router?.location?.pathname,
  (pathname = '') => pathname.startsWith('/infopage') || pathname.startsWith(SINGLE_LINE_VIEW_INFOPAGES_PATH)
);

export const selectTicketListTabs = createSelector(
  (state: State) => state.ticketListTabs,
  (state: State) => state.infoPageListTabs,
  selectIsInfopage,
  (ticketListTabs, infoPageListTabs, isInfopage) => {
    return isInfopage ? [...infoPageListTabs.values()] : Object.values(ticketListTabs);
  }
);

export const selectActiveTicketListTab = createSelector(selectTicketListTabs, (ticketTabs) => {
  return ticketTabs.find((tab) => tab.activeTab) ?? ticketTabs.find((tab) => tab.id === StaticTabs.MAIN_VIEW);
});

export const selectIsSingleLineView = createSelector(
  (state: State) => state.router.location.pathname,
  (pathname) => [SINGLE_LINE_VIEW_INFOPAGES_PATH, SINGLE_LINE_VIEW_TICKETS_PATH].includes(pathname)
);

export const selectTicketListTabTools = createSelector(
  (state: State) => state.ticketListTabTools,
  (ticketListTabTools) => ticketListTabTools
);

export const selectIsFilterOn = createSelector(selectActiveTicketListTab, (activeTiketListTab) => {
  const { filters, sorting, direction } = activeTiketListTab ?? {};

  return !!(
    (filters && !Object.keys(filters).every((k) => !filters[k])) ||
    (sorting && sorting !== DefaultTicketsOrdering[1]) ||
    (direction && direction !== DefaultTicketsOrdering[0])
  );
});
