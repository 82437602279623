import { filter } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Widget } from '@eeedo/types';

import AccordionHeader from '../AccordionHeader';
import Delegates from '../Delegates';
import { addDelegateGroupToContent, addDelegateToContent, removeDelegateFromContent } from 'src/actions/ticketsActions';
import { useAppSelector, useAppThunkDispatch } from 'src/store';

import type { Ticket } from 'src/types/Ticket';

import './Delegation.css';

interface DelegationsProps {
  id: string;
  task: Ticket;
  widgetOpen: boolean;
  widget: Widget;
}

const Delegations = ({ id, task, widget, widgetOpen }: DelegationsProps) => {
  const { t } = useTranslation();
  const dispatch = useAppThunkDispatch();
  const ticketTypes = useAppSelector((state) => state.ticketTypes);

  const taskTicketType = ticketTypes.find((x) => x.name === task.taskType)!;
  const delegates = filter(task.delegatedTo, (delegate) => delegate !== 'USR1');

  const handleDelegateChange = async (usr: string, method: string) => {
    if (typeof task.id !== 'undefined') {
      switch (method) {
        case 'DELETE':
          await dispatch(removeDelegateFromContent({ ticketId: task.id, usr }));
          break;
        case 'POST':
        default:
          await dispatch(addDelegateToContent({ ticketId: task.id, usr }));
          break;
      }
    }
  };

  const handleGroupDelegateChange = async (usrGroup: string[]) => {
    if (typeof task.id !== 'undefined') {
      await dispatch(addDelegateGroupToContent({ ticketId: task.id, usrGroup }));
    }
  };

  return (
    <AccordionHeader
      id={id}
      as="h4"
      active={widgetOpen}
      title={`${t('CASE_TITLE_DELEGATIONS')}`}
      label={delegates.length}
      icon="add user"
      isWidget={true}
    >
      <Delegates
        delegates={delegates}
        onAdd={(usr) => handleDelegateChange(usr, 'POST')}
        onRemove={(usr) => handleDelegateChange(usr, 'DELETE')}
        onGroupAdd={handleGroupDelegateChange}
        ticketType={taskTicketType}
        showOnlyRoles={widget.showOnlyRoles}
      />
    </AccordionHeader>
  );
};

export default Delegations;
