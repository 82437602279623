import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import type { Tag } from '@eeedo/types';
import type { FC } from 'react';

import * as styles from './TicketListItem.style';
import TicketTags from './TicketTags';
import Button from 'src/Components/generic/Button/Button';
import Label from 'src/Components/generic/Label/Label';
import WorkStatusImageContainer from 'src/containers/WorkStatusImageContainer';

import type { TicketItemProps } from './TicketListItem';

interface TicketMetaProps
  extends Pick<TicketItemProps, 'id' | 'taskType' | 'to' | 'from' | 'currentUserData' | 'type'> {
  sortedTags: Tag[];

  removeTicketLink(e: React.MouseEvent<HTMLButtonElement>): void;
}

const TicketMeta: FC<TicketMetaProps> = ({
  id,
  sortedTags,
  taskType,
  type,
  to,
  from,
  currentUserData,
  removeTicketLink
}) => (
  <div className="ticket-meta">
    <div className="ticket-tags">
      <Label className={'ticket-list-item-ticket-type-label ticketType-' + taskType}>{taskType}</Label>
      <TicketTags tags={sortedTags} />
    </div>
    <div style={styles.metaButtonWrapper}>
      {to && from && (
        <Button type="error" size="sm" icon={<FontAwesomeIcon icon={faClose} />} onClick={removeTicketLink} />
      )}

      {type !== 'infopage' && (
        <WorkStatusImageContainer userData={currentUserData} id={id} showStartWorkingOnButton={true} />
      )}
    </div>
  </div>
);

export default React.memo(TicketMeta);
