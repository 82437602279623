import iziToast from 'izitoast';
import fileDownload from 'js-file-download';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import type { PersonalData } from '@eeedo/types';
import type { FC } from 'react';

import Popup from '../generic/Popup/Popup';
import Section from '../generic/Section/Section';
import AttachmentMenu from './AttachmentMenu/AttachmentMenu';
import ApiConfig from 'src/api/ApiConfig';
import Dot from 'src/assets/icons/Dot';
import FileEmpty from 'src/assets/icons/FileEmpty';
import { openAttachmentsPreview } from 'src/reducers/attachmentsReducer';
import { DATE_TIME_SECONDS_FORMAT, getPrettyDate } from 'src/Utilities/dates';
import { getAttachmentSize } from 'src/Utilities/getAttachmentSize';
import { apiClient } from 'src/Utilities/httpClients';
import { hasImageExtension } from 'src/Utilities/images';
import { isLightboxType } from 'src/Utilities/lightbox';

import type { Attachment } from 'src/types/Ticket';

import './AttachmentItem.css';

import type { AttachmentMenuItem } from 'src/types/Attachments';

interface AttachmentProps
  extends Pick<
    Attachment,
    'id' | 'uri' | 'size' | 'uploaded' | 'fileName' | 'deprecated' | 'previewUri' | 'isQuarantined'
  > {
  attachments: Attachment[];
  personalData: PersonalData;

  onEdit?(id: string, body: AttachmentEdit): void;
  onDeprecate?(id: string): void;
  onUnDeprecate?(id: string): void;
  openFileDialog?(event: React.MouseEvent): void;
}

export interface AttachmentEdit {
  isQuarantined: boolean;
}

const AttachmentItem: FC<AttachmentProps> = ({
  id,
  uri,
  size,
  uploaded,
  fileName,
  deprecated,
  previewUri,
  isQuarantined,

  attachments,
  personalData,

  onEdit,
  onDeprecate,
  onUnDeprecate,
  openFileDialog
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const editAttachment = () => {
    onEdit?.(id, { isQuarantined: !isQuarantined });
  };

  const fileSize = getAttachmentSize(size);

  const deprecateAttachment = () => {
    onDeprecate?.(id);
  };

  const unDeprecateAttachment = () => {
    onUnDeprecate?.(id);
  };

  const getAttachmentLink = () => ApiConfig.getConfig().API_URL + '/file/' + uri;

  const getPreviewLink = () => ApiConfig.getConfig().API_URL + '/file/' + (previewUri ?? uri);

  const allowPreview = useMemo(() => !isQuarantined && hasImageExtension(previewUri ?? uri), [previewUri]);

  const downloadAttachment = () => {
    apiClient.get(getAttachmentLink(), { responseType: 'blob', timeout: 0 }).then((res) => {
      fileDownload(res.data, fileName);
    });
  };

  const openInNewWindow = () => {
    window.open(getAttachmentLink(), '_blank')?.focus();
  };

  const proceedAttachment = () => {
    isLightboxType({ isQuarantined, uri })
      ? dispatch(openAttachmentsPreview({ firstAttachmentId: id, attachments }))
      : openInNewWindow();
  };

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (isQuarantined && personalData.permissions.includes('getQuarantinedAttachments')) {
      iziToast.question({
        timeout: 20000,
        close: false,
        overlay: true,
        id: 'question',
        zindex: 999,
        backgroundColor: 'salmon',
        overlayClose: true,
        title: t('OPEN_ATTACHMENT_PROMPT_TITLE'),
        message: t('OPEN_ATTACHMENT_PROMPT_MESSAGE'),
        position: 'center',
        buttons: [
          [
            `<button><b>${t('YES')}</b></button>`,
            (instance, toast) => {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
              proceedAttachment();
            },
            true
          ],
          [
            `<button>${t('GENERAL_CANCEL')}</button>`,
            function (instance, toast) {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            },
            false
          ]
        ]
      });
    } else if (isQuarantined && !personalData.permissions.includes('accessQuarantineFiles')) {
      return iziToast.error({
        message: t('OPEN_ATTACHMENT_PROMPT_NO_ACCESS')
      });
    } else if (!isQuarantined) {
      proceedAttachment();
    }
  };

  const getImageExtension = (): string => {
    const ext = fileName.split('.').pop();
    return ext ? ext.toLocaleUpperCase() : '';
  };

  const attachmentStatusIcon = deprecated ? 'add' : 'delete';
  const attachmentStatusLabelText = deprecated ? t('ATTACHMENT_ACTIVATE') : t('ATTACHMENT_DEPRECATE');
  const attachmentActiveOnClick = deprecated ? unDeprecateAttachment : deprecateAttachment;

  const attachmentQuarantineIcon = isQuarantined ? 'check circle' : 'dont';
  const attachmentQuarantineTextKey = isQuarantined ? 'ATTACHMENT_RELEASE_QUARANTINE' : 'ATTACHMENT_SET_QUARANTINE';
  const dropdownOptions = useMemo(() => {
    const options: AttachmentMenuItem[] = [
      { disabled: true, text: t('ATTACHMENT_EDIT') },
      { disabled: true, icon: 'upload', text: t('ATTACHMENT_UPLOAD_NEW'), onClick: openFileDialog },
      { icon: 'download', text: 'Download', onClick: downloadAttachment }
    ];

    if (isLightboxType({ uri, isQuarantined })) {
      options.push({ icon: 'window restore', text: t('ATTACHMENT_OPEN_IN_NEW_TAB'), onClick: openInNewWindow });
    }

    if (typeof onDeprecate === 'function' && typeof onUnDeprecate === 'function') {
      options.push({ icon: attachmentStatusIcon, text: attachmentStatusLabelText, onClick: attachmentActiveOnClick });
    }

    if (typeof onEdit === 'function') {
      options.push({
        icon: attachmentQuarantineIcon,
        disabled: !personalData.permissions.includes('modifyQuarantineAttachments'),
        text: t(attachmentQuarantineTextKey),
        onClick: editAttachment
      });
    }

    return options;
  }, [
    uri,
    isQuarantined,
    personalData,
    openFileDialog,
    downloadAttachment,
    openInNewWindow,
    onDeprecate,
    onUnDeprecate,
    onEdit
  ]);

  return (
    <div key={id} className={['attachmentItem', isQuarantined ? 'quarantined' : ''].join(' ')}>
      <div className="attachmentItem_grid">
        <div className="attachmentItem_icon_wrap">
          <Popup
            on="hover"
            position="left center"
            delay={500}
            disabled={!allowPreview}
            noPadding
            trigger={
              <div className="attachmentItem_icon">
                <FileEmpty />
                <div className="attachmentItem_icon-name">{getImageExtension()}</div>
              </div>
            }
            content={<img className={'attachmentItem_preview'} src={getPreviewLink()} />}
          />
        </div>

        <div className="attachmentItem_description">
          <a
            style={{ color: isQuarantined ? 'red' : '' }}
            rel="noopener noreferrer"
            target="_blank"
            onClick={(e) => handleLinkClick(e)}
            href="#"
            className="attachmentItem_filename"
          >
            <Popup
              noPadding
              content={
                <Section direction="column" gap={4}>
                  <span>
                    <b>{t('FILE_NAME')}:</b> {fileName}
                  </span>
                  <span>
                    <b>{t('FILE_TYPE')}:</b> {getImageExtension()}
                  </span>
                </Section>
              }
              trigger={<span>{fileName}</span>}
            />
          </a>
        </div>

        <div className="attachmentItem_fileInfo">
          <span>{getPrettyDate(uploaded, { format: DATE_TIME_SECONDS_FORMAT })}</span>
          {fileSize ? <Dot /> : ''}
          <span>{fileSize}</span>
        </div>

        <div className="attachmentItem_dropdown">
          <AttachmentMenu options={dropdownOptions} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(AttachmentItem);
