import type { Tag } from '@eeedo/types';
import type { AxiosError } from 'axios';
import type { Action, ActionCreator, Dispatch } from 'redux';
import type { ThunkAction } from 'redux-thunk';

import TagsApi from '../api/TagsApi';
import TicketsApi from '../api/TicketsApi';
import { startAjaxCall } from './ajaxStatusActions';
import {
  CREATE_TAG_SUCCESS,
  DELETE_TAG_SUCCESS,
  FETCH_TAGS_FAILURE,
  FETCH_TAGS_SUCCESS,
  PATCH_TAGS_SUCCESS
} from './index';
import { initialRequestFailure, initialRequestInit, initialRequestSuccess } from './initActions';

export const loadTagsSuccess = (tags: Tag[]) => {
  return { type: FETCH_TAGS_SUCCESS, tags };
};
export const loadTagsFailure = (tags: Tag[]) => {
  return { type: FETCH_TAGS_FAILURE, tags };
};

export const patchTagSuccess = (tag: Tag) => {
  return { type: PATCH_TAGS_SUCCESS, tag };
};

export const createTagSuccess = (tag: Tag) => {
  return { type: CREATE_TAG_SUCCESS, tag };
};

export const deleteTagSuccess = (id: string) => {
  return { type: DELETE_TAG_SUCCESS, id };
};

export const fetchTags: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = () => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'FETCH_TAGS' }));
    dispatch(initialRequestInit('tags'));
    return TicketsApi.getTags()
      .then((tag) => {
        dispatch(loadTagsSuccess(tag));
        dispatch(initialRequestSuccess('tags'));
      })
      .catch((error) => {
        console.error('Failed to load tags', error);
        dispatch(loadTagsFailure(error));
        dispatch(initialRequestFailure('tags', error));
      });
  };
};

export const patchTag: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (data: {
  id: string;
  data: Partial<Tag>;
}) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'PATCH_TAGS' }));
    return TagsApi.patchTag(data)
      .then((tag) => {
        dispatch(patchTagSuccess(tag));
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  };
};

export const createTag: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'CREATE_TAG' }));
    return TagsApi.createTag(data)
      .then((response) => {
        dispatch(createTagSuccess(response));
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  };
};
