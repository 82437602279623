import React, { useState } from 'react';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Dropdown, Icon } from 'semantic-ui-react';

import type { FC } from 'react';

import EnvSettings from './api/EnvSettings';
import FeatureFlags from './api/FeatureFlags';
import ModalLoaderComponent from './Components/Loader/ModalLoader';
import { useAppSelector } from './store';

import './MenuDropdown.css';

const ExportDataContainer = React.lazy(() => import('src/containers/ExportDataContainer'));

const MenuDropdown: FC = () => {
  const [showExportDialog, setShowExportDialog] = useState(false);
  const tabs = useAppSelector((state) => state.ticketTabs);
  const activeTicketsTab = tabs.find((tab) => tab.type === 'tickets' && tab.activeTab);
  const ticketsPath = activeTicketsTab?.id ? `/case/${activeTicketsTab.id}` : '/';
  const activeInfoTab = tabs.find((tab) => tab.type === 'infopages' && tab.activeTab);
  const infopagesPath = FeatureFlags.isFlagOn('ENABLE_KNOWLEDGE_BASE')
    ? `/infopage${activeInfoTab?.id ? `/${activeInfoTab.id}` : ''}`
    : '/feature';

  return (
    <Translation ns="translations">
      {(t) => (
        <React.Fragment>
          {showExportDialog && (
            <React.Suspense fallback={<ModalLoaderComponent />}>
              <ExportDataContainer handleClose={() => setShowExportDialog(false)} isOpen={showExportDialog} />
            </React.Suspense>
          )}
          <Dropdown
            selectOnBlur={false}
            trigger={<Icon size="big" name="bars" style={{ color: 'white' }} />}
            simple={true}
            icon={null}
            style={{ margin: 0, padding: 0, zIndex: 199 }}
          >
            <Dropdown.Menu>
              <Dropdown.Header>
                <Link to={ticketsPath} className="dropdown-link" onClick={() => {}}>
                  <Icon name="tasks" /> {t('MENU_DROPDOWN_MAINVIEW')}
                </Link>
              </Dropdown.Header>

              <Dropdown.Header>
                <Link to={infopagesPath} className="dropdown-link">
                  <Icon name="file alternate outline" /> {t('MENU_DROPDOWN_INFOPAGES')}
                </Link>
              </Dropdown.Header>

              <Dropdown.Header>
                <Link to="/settings" className="dropdown-link">
                  <Icon name="settings" /> {t('MENU_DROPDOWN_SETTINGS')}
                </Link>
              </Dropdown.Header>

              {EnvSettings.getSettings().REPORTING_PAGE_URL !== '' && (
                <Dropdown.Header>
                  <Link to="/reporting" className="dropdown-link">
                    <Icon name="chart bar" /> {t('MENU_DROPDOWN_REPORTING')}
                  </Link>
                </Dropdown.Header>
              )}

              {FeatureFlags.isFlagOn('EXPORT_DATA') && (
                <Dropdown.Header onClick={() => setShowExportDialog(true)}>
                  <a href="/export" onClick={(e) => e.preventDefault()} className="dropdown-link">
                    <Icon name="external share" /> {t('export.menu.title')}
                  </a>
                </Dropdown.Header>
              )}

              {EnvSettings.getSettings().REPORTING_PAGE_URL === '' && (
                <Dropdown.Header>
                  <Icon name="help" /> {t('TOPLEFT_LINK_DROPDOWN_NO_PAGES')}
                </Dropdown.Header>
              )}

              {FeatureFlags.isFlagOn('ENABLE_ENTITY_VIEWER') && (
                <Dropdown.Header>
                  <Link to="/entities/" className="dropdown-link">
                    <Icon name="users" /> {t('entityViewer.entity_view')}
                  </Link>
                </Dropdown.Header>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </React.Fragment>
      )}
    </Translation>
  );
};

export default React.memo(MenuDropdown);
