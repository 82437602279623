import type { UIDPreferences, UIDPreferencesData, UIDPreferencesKeys } from '@eeedo/types';

import { apiClient } from 'src/Utilities/httpClients';

// TODO add types to apiClient
export default class UserPreferencesApi {
  static setPreference = async ({
    key,
    value
  }: {
    key: UIDPreferencesKeys;
    value: UIDPreferencesData[keyof UIDPreferencesData];
  }) => {
    return apiClient.patch('/user-preferences', { key, value }).then((response) => response.data);
  };

  static getByUser = async (key?: string) => {
    return apiClient
      .get<UIDPreferences[] | UIDPreferences>('/user-preferences/user', { data: { key } })
      .then((response) => response.data);
  };

  static getByUIDAdmin = async (UID: string) => {
    return apiClient
      .get<UIDPreferences[] | UIDPreferences>('/user-preferences/admin/' + UID)
      .then((response) => response.data);
  };

  static setPreferenceByAdmin = async ({
    key,
    value,
    UID
  }: {
    key: UIDPreferencesKeys;
    value: UIDPreferencesData[keyof UIDPreferencesData];
    UID: string;
  }) => {
    return apiClient.patch('/user-preferences/admin', { key, value, UID }).then((response) => response.data);
  };
}
