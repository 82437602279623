import { faPencil, faPlus, faTimer } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import type { FC } from 'react';

import FeatureFlags from 'src/api/FeatureFlags';
import Section from 'src/Components/generic/Section/Section';
import { DATE_TIME_FORMAT_SHORT, getPrettyDate } from 'src/Utilities/dates';

import type { TicketItemProps } from './TicketListItem';

type TicketListDateRowProps = Pick<TicketItemProps, 'created' | 'touched' | 'dueDate'>;

const formatDate = (date: number) => getPrettyDate(date, { format: DATE_TIME_FORMAT_SHORT });

const TicketListDateRow: FC<TicketListDateRowProps> = ({ created, touched, dueDate }) => (
  <Section wrap="nowrap" justify="flex-start" gap={8} className="ticketListDateRow">
    {FeatureFlags.isFlagOn('ENABLE_TICKETLIST_CREATED_TIMESTAMP') && (
      <Section gap={4}>
        <FontAwesomeIcon icon={faPlus} />
        <span>{formatDate(created)}</span>
      </Section>
    )}
    {FeatureFlags.isFlagOn('ENABLE_TICKETLIST_TOUCHED_TIMESTAMP') && (
      <Section gap={4}>
        <FontAwesomeIcon icon={faPencil} />
        <span>{formatDate(touched)}</span>
      </Section>
    )}
    {FeatureFlags.isFlagOn('ENABLE_TICKETLIST_DUEDATE_TIMESTAMP') && dueDate && (
      <Section gap={4}>
        <FontAwesomeIcon icon={faTimer} />
        <span>{formatDate(dueDate)}</span>
      </Section>
    )}
    {!FeatureFlags.isFlagOn('ENABLE_TICKETLIST_CREATED_TIMESTAMP') &&
      !FeatureFlags.isFlagOn('ENABLE_TICKETLIST_TOUCHED_TIMESTAMP') &&
      !FeatureFlags.isFlagOn('ENABLE_TICKETLIST_DUEDATE_TIMESTAMP') && (
        <Section gap={4}>
          <FontAwesomeIcon icon={faPlus} />
          <span>{formatDate(created)}</span>
        </Section>
      )}
  </Section>
);

export default React.memo(TicketListDateRow);
