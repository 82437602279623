import { ContentNamingType, convertTicketId } from '@eeedo/utils';
import { faMagnifyingGlass, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DropdownMenu } from 'semantic-ui-react';

import type { ComponentProps } from 'react';

import SearchOmnibarResults from './SearchOmnibarResults';
import { activateTab } from 'src/actions/tabActionsRTK';
import { activateTicket } from 'src/actions/ticketsActions';
import Dropdown from 'src/Components/generic/Dropdown/Dropdown';

import type { CommandPrompt } from './useCommands';

import './SearchOmnibar.css';

const SearchOmnibar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onClickTicket = useCallback(
    (value: number | string) => () => {
      const ticketId = convertTicketId<string>(value, ContentNamingType.String);
      dispatch(activateTicket(ticketId));
      dispatch(activateTab(ticketId));
      onClearSearch();
    },
    []
  );

  const onClickCommand = useCallback(
    (command: CommandPrompt) => () => {
      command.action?.();
      onClearSearch();
    },
    []
  );

  const onClearSearch = useCallback(() => {
    setSearchQuery('');
    setIsOpen(false);
  }, []);

  const debouncedSetSearchQuery = useMemo(
    () =>
      debounce((query: string) => {
        setSearchQuery(query);
      }, 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSetSearchQuery.cancel();
    };
  }, [debouncedSetSearchQuery]);

  const onSearchChange = useCallback<NonNullable<ComponentProps<typeof Dropdown>['onSearchChange']>>(
    (_, data) => {
      debouncedSetSearchQuery(data.searchQuery);
    },
    [debouncedSetSearchQuery]
  );

  const onOpen = useCallback(() => setIsOpen(true), []);
  const onClose = useCallback(() => setIsOpen(false), []);

  return (
    <Dropdown
      className="command_palette"
      fluid
      noResultsMessage={t('PLACEHOLDER_SEARCH_NO_RESULTS')}
      onClose={onClose}
      onOpen={onOpen}
      onSearchChange={onSearchChange}
      open={isOpen}
      placeholder={t('SEARCH')}
      search
      searchInput={{ size: 'huge' }}
      selectOnBlur={false}
      selectOnNavigation={false}
      selection
      size="large"
      value=""
      icon={
        <>
          <FontAwesomeIcon icon={faMagnifyingGlass} className="icon icon-left" key="search" />
          {!!searchQuery && (
            <FontAwesomeIcon icon={faXmark} className="icon icon-right" onClick={onClearSearch} key="clear" />
          )}
        </>
      }
    >
      <DropdownMenu>
        {isOpen && (
          <SearchOmnibarResults
            searchQuery={searchQuery}
            onClickTicket={onClickTicket}
            onClickCommand={onClickCommand}
            onClearSearch={onClearSearch}
          />
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default React.memo(SearchOmnibar);
