import { faArrowsRotate, faSearch, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iziToast from 'izitoast';
import { get, pick } from 'lodash-es';
import React from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';

import type { Field, FieldSet, PersonalData, Tag, TicketType } from '@eeedo/types';
import type { ConnectedProps } from 'react-redux';

import AccordionHeader from '../Case/AccordionHeader';
import Info from '../Case/Info/Info';
import Button from '../generic/Button/Button';
import Section from '../generic/Section/Section';
import formatTitleWithValues from '../Utilities/formatTitleWithValues';
import EntityStatus from './EntityStatus';
import EntityTags from './EntityTags';
import FeatureFlags from 'src/api/FeatureFlags';
import ErrorBoundary from 'src/ErrorBoundary';
import { selectActiveTicketsEntities } from 'src/selectors/rootStateSelectors';
import isEntityReadOnlyByTicketType from 'src/Utilities/isEntityReadOnly';

import type { UpdateEntityDetail } from 'src/api/TicketsApi';
import type { State } from 'src/types/initialState';
import type { Entity, EntityResponse, Ticket } from 'src/types/Ticket';

import './CustomerContainer.css';

type CustomerContainerStateProps = ConnectedProps<typeof connector>;

const mapStateToProps = (state: State) => ({
  ticketTypes: state.ticketTypes,
  entityTags: pick(state.entityTags, selectActiveTicketsEntities(state)?.map((entity) => entity._id) ?? [])
});

interface CustomerContainerOwnProps {
  fields: Field[];
  additionalCustomerFieldSets: any;
  values: Entity[];
  ticketTypes: TicketType[];
  entityType: string;
  task: Ticket;
  personalData?: PersonalData;
  generalDisable?: boolean;
  collapsed?: boolean;

  onSave(args: UpdateEntityDetail): void;
  onDetachEntity(args: { _id: string; _type: string }): void;
  onSearchPreviousTickets(id: string, entityType: string): void;
  getEntityById?(ticketId: string, ticketType: string, entityId: string, entityType: string): void;
  fireSearch?(value: any, type: string): void;
}

interface GetValueByDisplayFieldArgs {
  fieldSet?: FieldSet;
  entityData?: object;
  fieldName: 'name1' | 'name2' | 'name3';
}

type CustomerContainerProps = CustomerContainerOwnProps & CustomerContainerStateProps;

class CustomerContainer extends React.Component<CustomerContainerProps> {
  private detachCustomer = (_id: string, _type: string) => {
    this.props.onDetachEntity({ _id, _type });
  };

  private getEntityIcon = (entity: EntityResponse) => {
    if (!entity.data) {
      return undefined;
    }

    return entity.data.mongoCustomer ? 'eeedoCustomer' : 'integrationCustomer';
  };

  private getValueByDisplayField = (params: GetValueByDisplayFieldArgs): string => {
    if (!params.fieldSet || !params.entityData) {
      return '';
    }

    const field = params.fieldSet.customerInfo?.find((field) => field.displayField === params.fieldName);
    const value = field ? get(params.entityData, field.value.toString(), '') || '' : '';

    return value.toString();
  };

  private getDisplayedComponent = () => {
    const entities = this.props.values as EntityResponse[];
    const selectedTicketType = this.props.ticketTypes.find((x) => x.name === this.props.task.taskType);
    const matchTicketType = this.props.values[0]._type;

    const defaultFieldSet = selectedTicketType?.fieldSets.find(({ id }) => id === 'customerInfo');
    const fieldSet = selectedTicketType?.fieldSets.find(
      ({ entityTypes }) => Array.isArray(entityTypes) && entityTypes.includes(matchTicketType)
    );
    const correctFieldSet = fieldSet ?? defaultFieldSet;

    return entities.map((entity, index) => {
      const name1Value = this.getValueByDisplayField({
        fieldName: 'name1',
        fieldSet: correctFieldSet,
        entityData: entity.data
      });

      const name2Value = this.getValueByDisplayField({
        fieldName: 'name2',
        fieldSet: correctFieldSet,
        entityData: entity.data
      });

      const name3Value = this.getValueByDisplayField({
        fieldName: 'name3',
        fieldSet: correctFieldSet,
        entityData: entity.data
      });

      return (
        <Translation ns="translations" key={`customer-container-${index}`}>
          {(t) => (
            <div className="ui fluid card customer-container">
              <AccordionHeader
                id={`customer-container-accordion-${index}`}
                key={`customer-container-accordion-${index}`}
                as="h4"
                active={!this.props.collapsed}
                title={`${name1Value} ${name2Value} ${name3Value}`}
                icon="user circle"
                customIconClassname={this.getEntityIcon(entity)}
                divider={false}
              >
                <div className="customer-container__form">
                  {FeatureFlags.isFlagOn('ENABLE_ENTITY_TAGS') && (
                    <EntityTags
                      tagIds={this.props.entityTags[entity._id]}
                      ticketType={selectedTicketType}
                      entityId={entity._id}
                      entityType={entity._type}
                    />
                  )}

                  <EntityStatus
                    entity={entity}
                    getEntityById={(_id: string, _type: string) => {
                      this.props.getEntityById?.(this.props.task.id, this.props.task.taskType, _id, _type);
                    }}
                  >
                    <React.Fragment>
                      <Info
                        entity={entity}
                        generalDisable={this.props.generalDisable}
                        editDisable={isEntityReadOnlyByTicketType(entity, selectedTicketType)}
                        fireSearch={this.props.fireSearch}
                        onSave={this.onSaveData}
                        taskId={this.props.task.id}
                        fields={correctFieldSet ? correctFieldSet[correctFieldSet.id] : this.props.fields}
                        values={entity.data}
                        taskType={this.props.task.taskType}
                        disableInputIfNotMongoCustomer
                        searchableFields={false}
                      />
                      {this.getAdditionalCustomerFields(entity)}
                    </React.Fragment>
                  </EntityStatus>

                  {(entity.hasAccess !== false || entity.hasAccess === undefined) && (
                    <React.Fragment>
                      <Section direction="column" gap={8} display="grid">
                        <Button
                          disabled={this.props.generalDisable}
                          iconLeft={<FontAwesomeIcon icon={faSearch} />}
                          onClick={() => this.props.onSearchPreviousTickets(String(entity._id), this.props.entityType)}
                        >
                          {t('ADD_CUSTOMER_SHOW_CUSTOMER_TICKETS')}
                        </Button>

                        <Button
                          content={t('ENTITY_INFORMATION_REFRESH')}
                          disabled={this.props.generalDisable}
                          iconLeft={<FontAwesomeIcon icon={faArrowsRotate} />}
                          onClick={() => {
                            if (this.props.getEntityById) {
                              this.props.getEntityById(
                                this.props.task.id,
                                this.props.task.taskType,
                                entity._id,
                                entity._type
                              );
                            }
                          }}
                        />

                        <Button
                          content={t('ENTITY_INFORMATION_DETACH')}
                          disabled={this.props.generalDisable}
                          type="error"
                          iconLeft={<FontAwesomeIcon icon={faXmark} />}
                          onClick={() => {
                            iziToast.warning({
                              title: t('ADD_CUSTOMER_CHANGE_NOTIFICATION_TITLE'),
                              message: t('ADD_CUSTOMER_CHANGE_NOTIFICATION_DESCRIPTION'),
                              timeout: 20000,
                              close: true,
                              overlay: true,
                              closeOnEscape: true,
                              zindex: 999,
                              position: 'center',
                              buttons: [
                                [
                                  '<button>' + t('YES') + '</button>',
                                  (instance, toast) => {
                                    this.detachCustomer(entity._id, this.props.entityType);
                                    instance.hide(
                                      {
                                        message: '',
                                        transitionOut: 'fadeOut'
                                      },
                                      toast,
                                      'button'
                                    );
                                  },
                                  true
                                ],
                                [
                                  '<button>' + t('NO') + '</button>',
                                  (instance, toast) => {
                                    instance.hide(
                                      {
                                        message: '',
                                        transitionOut: 'fadeOut'
                                      },
                                      toast,
                                      'button'
                                    );
                                  },
                                  true
                                ]
                              ]
                            });
                          }}
                        />
                      </Section>
                    </React.Fragment>
                  )}
                </div>
              </AccordionHeader>
            </div>
          )}
        </Translation>
      );
    });
  };

  private getAdditionalCustomerFields = (entity: Entity) => {
    if (this.props.additionalCustomerFieldSets !== undefined && entity !== undefined && entity['data'] !== undefined) {
      return this.props.additionalCustomerFieldSets.map((fieldSet: FieldSet, index: number) => {
        const fielsetNameClassNameArray = fieldSet.displayName.split(' ');
        let fielsetNameClassName = '';
        fielsetNameClassNameArray.forEach((word: string, i: number) => {
          word = word.toLowerCase();
          if (i + 1 <= fielsetNameClassNameArray.length) {
            word += '-';
          }
          fielsetNameClassName += word;
        });
        fielsetNameClassName += 'color';
        const subFieldset =
          entity['data'][fieldSet.propertyName ?? fieldSet.displayName] ||
          entity['data'][fieldSet.id] ||
          (entity['data'].subEntities &&
            entity['data'].subEntities[
              Object.keys(entity['data'].subEntities).find((subEntityKey) => {
                if (
                  entity['data'].subEntities[subEntityKey].data &&
                  Object.keys(entity['data'].subEntities[subEntityKey].data).find((subEntityVariableKey) => {
                    return subEntityVariableKey === fieldSet.displayName || subEntityVariableKey === fieldSet.id;
                  })
                ) {
                  return true;
                } else {
                  return false;
                }
              }) || 'noMatch'
            ]?.data[fieldSet.displayName || fieldSet.id]);

        if (subFieldset === undefined) {
          return <div />;
        }

        if (subFieldset.type === 'collection') {
          const fieldValueArray = subFieldset.data;
          let additionalAccordionOpen = true;
          if (fieldSet.openByDefault === false) {
            additionalAccordionOpen = false;
          }
          return (
            <div className={fielsetNameClassName} key={`customer-container-additionsl-field-${index}`}>
              <AccordionHeader
                key={`customer-container-additional-field-accordion-${index * 10}`}
                as="h4"
                active={additionalAccordionOpen}
                title={fieldSet.displayName}
                icon="info circle"
              >
                {fieldValueArray.map((dataSet: any, i: number) => {
                  if (entity['data'].mongoCustomer) {
                    dataSet.mongoCustomer = entity['data'].mongoCustomer;
                  }
                  const parsedAccordionTitle = subFieldset.subHeaderValue;
                  const accordionTitle = formatTitleWithValues(parsedAccordionTitle, dataSet);

                  const childrenOpenByDefault =
                    fieldSet.childrenOpenByDefault !== undefined ? fieldSet.childrenOpenByDefault : true;
                  return (
                    <div className={fielsetNameClassName} key={`customer-container-info-wrapper-${index + i}`}>
                      <AccordionHeader
                        key={`customer-container-info-wrapper-accordion-${index + 10}`}
                        as="h4"
                        active={childrenOpenByDefault}
                        title={accordionTitle}
                        icon="info circle"
                      >
                        <Info
                          fields={fieldSet[fieldSet.id] || []}
                          values={dataSet || {}}
                          disableInputIfNotMongoCustomer={true}
                          onSave={this.onSaveData}
                        />
                      </AccordionHeader>
                    </div>
                  );
                })}
              </AccordionHeader>
            </div>
          );
        } else {
          if (entity['data'].mongoCustomer) {
            entity['data'][fieldSet.displayName].mongoCustomer = entity['data'].mongoCustomer;
          }
          return (
            <div className={fielsetNameClassName} key={`customer-container-info-wrapper-${index}`}>
              <AccordionHeader
                key={`customer-container-info-wrapper-accordion-${index * 10 * 20}`}
                active
                as="h4"
                icon="info circle"
                title={fieldSet.displayName}
              >
                <Info
                  fields={fieldSet[fieldSet.id] || []}
                  values={subFieldset || {}}
                  generalDisable={this.props.generalDisable}
                  disableInputIfNotMongoCustomer={true}
                />
              </AccordionHeader>
            </div>
          );
        }
      });
    }
  };

  private onSaveData = (
    fieldName: string,
    valueToSave: string | boolean | Tag[] | null | undefined,
    object: any,
    partial: boolean,
    field: string,
    optionText: string,
    group?: string,
    entityType?: string,
    entityId?: string
  ) => {
    if (typeof entityId !== 'undefined' && typeof entityType !== 'undefined' && entityType !== 'undefined') {
      this.props.onSave({
        entityId,
        entityType,
        fieldName,
        valueToSave,
        object,
        partial
      });
    }
  };

  render() {
    return <ErrorBoundary>{this.getDisplayedComponent()}</ErrorBoundary>;
  }
}

const connector = connect(mapStateToProps);

export default connector(CustomerContainer);
