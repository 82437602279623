import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

export interface TicketListActiveTabs {
  isFilterOpen: boolean;
  isSearchOpen: boolean;
}

const initialState: TicketListActiveTabs = {
  isFilterOpen: false,
  isSearchOpen: false
};

const ticketListToolsSlice = createSlice({
  name: 'ticketListActiveTabs',
  initialState,
  reducers: {
    setTicketListFilterOpen(state, { payload }: PayloadAction<boolean>) {
      state.isFilterOpen = payload;
      if (payload) {
        state.isSearchOpen = false;
      }
    },
    setTicketListSearchOpen(state, { payload }: PayloadAction<boolean>) {
      state.isSearchOpen = payload;
      if (payload) {
        state.isFilterOpen = false;
      }
    }
  }
});

export const { setTicketListFilterOpen, setTicketListSearchOpen } = ticketListToolsSlice.actions;

export default ticketListToolsSlice.reducer;
