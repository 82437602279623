import React from 'react';

import ChannelType from '../CommentIconContent/ChannelType';
import { useFocusMode } from '../FocusMode/FocusModeContext';
import PhoneIntegrations from '../PhoneIntegrations';
import TicketListHeader from '../ticketList/TicketListHeader/TicketListHeader';
import TicketListTab from '../ticketList/TicketListTabs/TicketListTabs';
import ErrorBoundary from 'src/ErrorBoundary';
import { selectIsInfopage } from 'src/selectors/rootStateSelectors';
import { useAppSelector } from 'src/store';
import { getUrlSearchParam } from 'src/Utilities/helper';

const TicketsSidebar = () => {
  const { isFocusMode } = useFocusMode();
  const { permissions, channels } = useAppSelector((state) => state.userData);
  const isInfopage = useAppSelector(selectIsInfopage);

  const divStyle = {
    maxWidth: isFocusMode ? '0px' : '100%',
    overflow: 'hidden',
    transition: 'max-width 0.5s ease'
  };

  const hideTicketListPaths = ['/settings', '/entities'];
  const onSettingsPage = hideTicketListPaths.some((path) => location.pathname.startsWith(path));

  if (getUrlSearchParam('hideNavigation')) {
    return null;
  }

  return (
    <div
      className="sideBar"
      style={{
        display: onSettingsPage ? 'none' : 'flex',
        ...divStyle,
        backgroundColor: 'white',
        borderRight: '0'
      }}
    >
      <ErrorBoundary>
        <TicketListHeader />
      </ErrorBoundary>

      <div className="flexSideView">
        <ErrorBoundary>
          <TicketListTab contentType={isInfopage ? 'infopages' : 'tickets'} />
        </ErrorBoundary>

        {permissions.includes('allowPhoneIntegrations') && channels.includes(ChannelType.Phone) && (
          <ErrorBoundary>
            <PhoneIntegrations />
          </ErrorBoundary>
        )}
      </div>
      <div id="date-picker-portal"></div>
    </div>
  );
};

export default TicketsSidebar;
