import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { FC } from 'react';

import Button from '../generic/Button/Button';

interface TimerRefreshProps {
  refreshData: () => void;
}

const refreshIntervalSec = 15;

const TimerRefresh: FC<TimerRefreshProps> = ({ refreshData }) => {
  const { t } = useTranslation();
  const [timeLeft, setTimeLeft] = useState<number>(refreshIntervalSec);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          refreshData();
          return refreshIntervalSec;
        }
        return prev - 1;
      });
    }, 1000);
    return () => clearInterval(timer);
  }, [refreshData]);

  return (
    <div className="refreshSection">
      <Button
        type="positive"
        iconRight={<FontAwesomeIcon icon={faArrowsRotate} />}
        onClick={() => {
          refreshData();
          setTimeLeft(refreshIntervalSec);
        }}
      >
        {t('availability.table.refresh')}
      </Button>

      <span>
        {t('availability.table.next_update_in')}:{' '}
        <strong>
          {t('time.secondsCount', { count: timeLeft })}
        </strong>
      </span>
    </div>
  );
};

export default TimerRefresh;
