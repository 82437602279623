export enum UserStatusType {
  READY = 'ready',
  NOTREADY = 'notready'
}

export interface UserStatus {
  id: number;
  type: UserStatusType;
  text: string;
  active: boolean;
  created: number;
  createdBy: number;
  updated?: number;
  updatedBy?: number;
}

export interface CurrentUserStatus {
  id: number;
  UID: number;
  originalStatus: number;
  description: string | null;
  updated: string;
  updatedBy: number;
}

export interface UserStatusLog {
  id: number;
  UID: number;
  originalStatus: number;
  description: string | null;
  startedAt: number;
  startedBy: number;
  stoppedAt: number | null;
  stoppedBy: number | null;
  duration: number | null;
}

export type OnlineStatusType = 'online' | 'offline';

export interface OnlineStatusChangePayload {
  UID: string;
  status: OnlineStatusType;
}
