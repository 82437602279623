import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuHeader } from 'semantic-ui-react';

import type { FC, PropsWithChildren } from 'react';

import SearchOmnibarItem from './SearchOmnibarItem';
import Button from 'src/Components/generic/Button/Button';

import type { TicketSearch } from './types';
import type { CommandPrompt } from './useCommands';

interface Props {
  commands?: CommandPrompt[];
  fluid?: boolean;
  onClickShowMore?: () => void;
  results?: number;
  tickets?: TicketSearch[];
  title?: React.ReactNode;
  onClickTicket?: (ticketId: string | number) => (event: React.MouseEvent<HTMLDivElement>) => void;
  onClickCommand?: (command: CommandPrompt) => (event: React.MouseEvent<HTMLDivElement>) => void;
}

const SearchOmnibarBox: FC<PropsWithChildren<Props>> = ({
  children,
  commands,
  fluid,
  onClickCommand,
  onClickTicket,
  onClickShowMore,
  results,
  tickets,
  title
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('menu visible command_palette_box', { fluid })}>
      {title && (
        <MenuHeader>
          {title}
          {!!results && (
            <small style={{ float: 'right' }}>
              {results} {t('GENERAL_SEARCH_RESULTS')}
            </small>
          )}
        </MenuHeader>
      )}

      {onClickTicket &&
        tickets?.map((ticket) => (
          <SearchOmnibarItem ticket={ticket} key={ticket.id} onClick={onClickTicket(ticket.id)} />
        ))}

      {onClickCommand &&
        commands?.map((command) => (
          <SearchOmnibarItem promptOption={command} key={command.value} onClick={onClickCommand(command)} />
        ))}

      {children}

      {onClickShowMore && (
        <MenuHeader>
          <Button content={t('GENERAL_SHOW_MORE')} onClick={onClickShowMore} size="xs" type="normal" />
        </MenuHeader>
      )}
    </div>
  );
};

export default SearchOmnibarBox;
