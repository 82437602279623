import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { t } from 'i18next';
import iziToast from 'izitoast';

import type { Webhook } from '@eeedo/types';

import { FETCH_WEBHOOKS_SUCCESS } from '.';
import { startAjaxCall } from './ajaxStatusActions';
import { initialRequestInit, initialRequestSuccess } from './initActions';
import WebhooksApi from 'src/api/WebhooksApi';

export const fetchWebhooksSuccess = createAction<{ webhooks: Webhook[] }>(FETCH_WEBHOOKS_SUCCESS);

export const fetchWebhooks = createAsyncThunk<{ status: string }, void, { rejectValue: Error }>(
  'fetchWebhooks',
  async (_, { dispatch }) => {
    dispatch(startAjaxCall({ name: 'FETCH_WEBHOOKS' }));
    dispatch(initialRequestInit('webhooks'));
    try {
      const result = await WebhooksApi.getWebhooks();
      dispatch(fetchWebhooksSuccess({ webhooks: result }));
      dispatch(initialRequestSuccess('webhooks'));
      return { status: 'success' };
    } catch (error) {
      iziToast.error({
        title: t('ERROR_HAPPENED'),
        message: t('management.webhooks_management.toasts.fetch_failed'),
        position: 'bottomRight',
        timeout: 2500,
        icon: 'delete'
      });
      return { status: 'failed' };
    }
  }
);

export const createWebhook = createAsyncThunk<void, { webhook: Omit<Webhook, 'id'> }, { rejectValue: Error }>(
  'createWebhook',
  async ({ webhook }, { dispatch }) => {
    dispatch(startAjaxCall({ name: 'CREATE_WEBHOOK' }));
    await WebhooksApi.createWebhook(webhook);
  }
);

export const updateWebhook = createAsyncThunk<
  void,
  { id: number; webhook: Omit<Webhook, 'id'> },
  { rejectValue: Error }
>('updateWebhook', async ({ id, webhook }, { dispatch }) => {
  dispatch(startAjaxCall({ name: 'UPDATE_WEBHOOK' }));
  await WebhooksApi.updateWebhook(id, webhook);
});

export const deleteWebhook = createAsyncThunk<void, { id: number }, { rejectValue: Error }>(
  'deleteWebhook',
  async ({ id }, { dispatch }) => {
    dispatch(startAjaxCall({ name: 'DELETE_WEBHOOK' }));
    await WebhooksApi.deleteWebhook(id);
  }
);
