import fileDownload from 'js-file-download';
import React, { useRef, useState } from 'react';
import { Translation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Card, DropdownItem, DropdownMenu } from 'semantic-ui-react';

import Dropdown from '../generic/Dropdown/Dropdown';
import ApiConfig from 'src/api/ApiConfig';
import { openAttachmentsPreview } from 'src/reducers/attachmentsReducer';
import { apiClient } from 'src/Utilities/httpClients';
import { isLightboxType } from 'src/Utilities/lightbox';

import type { Attachment } from 'src/types/Ticket';

import './AttachmentItem.css';

export const MAX_FILENAME_LENGTH = 28;

interface AttachmentCardProps {
  attachment: Attachment;
  attachments: Attachment[];
}

const AttachmentCard: React.FC<AttachmentCardProps> = ({ attachment, attachments }) => {
  const dispatch = useDispatch();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [direction, setDirection] = useState<'left' | 'right'>('left');
  const downloadAttachment = () => {
    apiClient.get(getAttachmentLink(), { responseType: 'blob', timeout: 0 }).then((res) => {
      fileDownload(res.data, attachment.fileName);
    });
  };

  const openInNewWindow = () => {
    window.open(getAttachmentLink(), '_blank')?.focus();
  };

  const getAttachmentLink = () => ApiConfig.getConfig().API_URL + '/file/' + attachment.uri;

  const formattedFilename = () => {
    const { fileName } = attachment;
    if (fileName.length > MAX_FILENAME_LENGTH) {
      const truncedFilename = fileName
        .substring(0, fileName.lastIndexOf('.'))
        .substring(0, MAX_FILENAME_LENGTH)
        .concat('..');

      return truncedFilename.concat(fileName.substring(fileName.lastIndexOf('.')));
    }

    return fileName;
  };

  const handlePreviewAttachment = () =>
    isLightboxType(attachment)
      ? dispatch(openAttachmentsPreview({ firstAttachmentId: attachment.id, attachments }))
      : openInNewWindow();

  const getDropdownDirection = () => {
    const { left } = dropdownRef.current?.getBoundingClientRect() ?? { left: 0 };
    const { right } = document.querySelector('.sideBar .flexSideView')?.getBoundingClientRect() ?? { right: 0 };

    return left - right < 250 ? 'right' : 'left';
  };

  return (
    <Translation ns="translations">
      {(tr) => (
        <Card
          style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', width: 'auto', borderRadius: '10px' }}
        >
          <Card.Description
            style={{ padding: '8px 0px 8px 12px', color: '#000', cursor: 'pointer' }}
            onClick={() => handlePreviewAttachment()}
          >
            {formattedFilename()}
          </Card.Description>

          <div ref={dropdownRef} style={{ marginLeft: 'auto' }}>
            <Dropdown
              icon="chevron down"
              button
              floating
              selectOnBlur={false}
              className={'icon attachmentItemDropdown'}
              onClick={() => {
                setDirection(getDropdownDirection());
              }}
            >
              <DropdownMenu direction={direction}>
                <DropdownItem icon="window restore" text={tr('ATTACHMENT_OPEN_IN_NEW_TAB')} onClick={openInNewWindow} />
                <DropdownItem icon="download" text={tr('GENERAL_DOWNLOAD')} onClick={downloadAttachment} />
              </DropdownMenu>
            </Dropdown>
          </div>
        </Card>
      )}
    </Translation>
  );
};

export default React.memo(AttachmentCard);
