import { faClock } from '@fortawesome/pro-regular-svg-icons';
import React, { useCallback, useState } from 'react';

import type { FC } from 'react';

import TopBarDateItem from './TopBarDateItem';
import DueDatePickerModal from 'src/Components/generic/DueDatePickerModal';
import { useAppSelector } from 'src/store';

import type { ComponentProp } from 'src/types/generic';

interface TopBarDueDateProps {
  dateDue: number;

  dueDateChange(value: number): void;
}

const TopBarDueDate: FC<TopBarDueDateProps> = ({ dateDue, dueDateChange }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const userData = useAppSelector((state) => state.userData);

  const onItemClick = useCallback<ComponentProp<typeof TopBarDateItem, 'onClick'>>(
    (e) => {
      e.preventDefault();

      setModalOpen(true);
    },
    [setModalOpen]
  );
  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  return (
    <>
      <TopBarDateItem value={dateDue} translationKey={'TOOLTIP_DUEDATE_SHORT'} icon={faClock} onClick={onItemClick} />
      {userData.permissions.includes('updateContent') && (
        <DueDatePickerModal
          dueDate={dateDue}
          changeDueDate={dueDateChange}
          isOpen={isModalOpen}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default React.memo(TopBarDueDate);
