import { t } from 'i18next';
import iziToast from 'izitoast';

import type { AxiosError } from 'axios';

import { defaultInfoPage } from '../api/InfoPage';
import { defaultTask } from '../api/Task';

import type { Template, TemplateContentJson } from '../types/Template';
import type { ContentTypesFields } from '../types/Ticket';

export const handleAssignOperationRejection = (err: unknown) => {
  if (
    (err as AxiosError)?.isAxiosError &&
    ((err as AxiosError)?.response?.data as Record<string, any>)?.msg === 'failed to lock the workstatus operation'
  ) {
    iziToast.error({
      title: t('ERROR_SOMEONE_ELSE_STARTED_WORKING_ON'),
      position: 'center',
      timeout: 5000
    });
    return;
  }

  throw err;
};

export const getNewContentFromTemplate = (
  contentType: ContentTypesFields,
  defaultTicketTypeName: string,
  template?: Template<TemplateContentJson>
) => {
  let newContent =
    contentType === 'tickets' ? defaultTask(defaultTicketTypeName) : defaultInfoPage(defaultTicketTypeName);
  newContent.direction = null as unknown as string;

  if (template?.template) {
    const { title, content, ticketType, priority, channel, tags } = template.template;

    newContent = { ...newContent, title, content, tags };
    newContent.taskType = ticketType;
    if (typeof channel === 'number' && !isNaN(channel)) {
      newContent.channel = channel;
    }
    if (typeof priority === 'number' && !isNaN(priority)) {
      newContent['priority'] = priority;
    }
  }

  return newContent;
};
