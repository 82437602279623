import type { Action, ActionCreator, Dispatch } from 'redux';
import type { ThunkAction } from 'redux-thunk';

import TicketsApi from '../api/TicketsApi';
import { startAjaxCall } from './ajaxStatusActions';
import { FETCH_CASE_IMPORT_TEMPLATES_FAILURE } from './index';
import { initialRequestFailure, initialRequestInit, initialRequestSuccess } from './initActions';
import { fetchCampaignTemplatesSuccess } from 'src/reducers/campaignReducer';

import type { ImportTemplate } from 'src/types/Campaign';

export const loadImportTemplatesFailure = (templates: any[]) => {
  return { type: FETCH_CASE_IMPORT_TEMPLATES_FAILURE, templates };
};

export const fetchCaseImportTemplates: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = () => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'FETCH_CASE_IMPORT_TEMPLATES' }));
    dispatch(initialRequestInit('caseImportTemplates'));
    return TicketsApi.getCampaignTemplates()
      .then((templates: ImportTemplate[]) => {
        dispatch(fetchCampaignTemplatesSuccess(templates));
        dispatch(initialRequestSuccess('caseImportTemplates'));
      })
      .catch((error: any) => {
        console.error('Failed to load tags', error);
        dispatch(loadImportTemplatesFailure(error));
        dispatch(initialRequestFailure('tags', error));
      });
  };
};
