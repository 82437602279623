import { DefaultTicketsOrdering, FilterPresetView } from '@eeedo/types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import type { FC } from 'react';

import Search from '../Search/Search';
import TicketLists from '../TicketLists';
import { fetchFilterPresets, resetFilterPresets, setActiveFilterPreset } from 'src/actions/filterPresetsActions';
import { setInfopagelistFilter } from 'src/actions/infoPagesActionsRTK';
import { setTicketlistFilter, setTicketlistSorting } from 'src/actions/ticketListTabActionsRTK';
import FeatureFlags from 'src/api/FeatureFlags';
import TicketListFilter from 'src/containers/TicketListFilterContainer';
import ErrorBoundary from 'src/ErrorBoundary';
import {
  selectActiveTicketListTab,
  selectIsInfopage,
  selectIsSingleLineView,
  selectTicketListTabTools
} from 'src/selectors/rootStateSelectors';
import { useAppSelector } from 'src/store';
import { StaticTabs } from 'src/types/TicketList';

import type { IFilterItem } from 'src/types/Filter';
import type { ContentTypesFields } from 'src/types/Ticket';

import './TicketListTabs.css';

const [defaultDirection, defaultSorting] = DefaultTicketsOrdering;

interface Props {
  contentType: ContentTypesFields;
  noDefaultDates?: boolean;
}

const TicketListTabs: FC<Props> = ({ contentType }) => {
  const dispatch = useDispatch();
  const activeTicketListTab = useAppSelector(selectActiveTicketListTab);
  const defaultFilterPreset = useAppSelector((state) => state.userData.userPreferences.defaultFilterPreset);
  const filterPresets = useAppSelector((state) => state.filterPresets.filterPresets);
  const isInfopage = useAppSelector(selectIsInfopage);
  const isSingleLineView = useAppSelector(selectIsSingleLineView);
  const { isFilterOpen, isSearchOpen } = useAppSelector(selectTicketListTabTools);
  const { filters = {}, sorting, direction } = activeTicketListTab ?? {};

  const setFilter = (item: IFilterItem) => {
    dispatch((contentType === 'infopages' ? setInfopagelistFilter : setTicketlistFilter)(item));
  };

  const setSorting = (...args: Parameters<typeof setTicketlistSorting>) => {
    if (contentType === 'tickets') {
      dispatch(setTicketlistSorting(...args));
    }
  };

  // Check undefined values in filters objects
  const isFilterOn =
    !Object.keys(filters).every((k) => !filters[k]) ||
    (sorting && sorting !== defaultSorting) ||
    (direction && direction !== defaultDirection);

  useEffect(() => {
    // this component never seems to unmount so need to follow some state change
    dispatch(fetchFilterPresets({ view: isInfopage ? FilterPresetView.infopage : FilterPresetView.main }));
  }, [isInfopage]);

  useEffect(() => {
    return () => {
      dispatch(resetFilterPresets());
    };
  }, []);

  useEffect(() => {
    if (isFilterOn || activeTicketListTab?.id !== StaticTabs.MAIN_VIEW) {
      return;
    }

    const defaultFilter = filterPresets.find((p) => p.id === defaultFilterPreset);
    if (defaultFilter) {
      if (!activeTicketListTab?.id) {
        return;
      }

      dispatch(setActiveFilterPreset({ filterPresetId: defaultFilter.id }));

      Object.keys(defaultFilter?.filters).forEach((filterKey) => {
        setFilter({
          id: activeTicketListTab.id,
          value: defaultFilter?.filters[filterKey],
          parameter: filterKey as any
        });
      });

      setSorting(defaultFilter.sorting as any, defaultFilter.direction);
    }
  }, [filterPresets, defaultFilterPreset]);

  return (
    <ErrorBoundary>
      {isSearchOpen && <Search contentType={contentType} isSingleLineView={isSingleLineView} />}

      {isFilterOpen && FeatureFlags.isFlagOn('ENABLE_TICKET_LIST_FILTERING') && (
        <TicketListFilter contentType={contentType} />
      )}

      {!isFilterOpen &&
        !isSearchOpen &&
        (!isSingleLineView ? (
          <TicketLists contentType={contentType} />
        ) : (
          <div style={{ display: 'flex', flexGrow: 1 }} />
        ))}
    </ErrorBoundary>
  );
};

export default React.memo(TicketListTabs);
