import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';

import type { Priority } from '@eeedo/types';

import PriorityApi from '../api/PriorityApi';
import { startAjaxCall } from './ajaxStatusActions';
import {
  ADD_PRIORITIES_SUCCESS,
  FETCH_PRIORITIES_FAILURE,
  FETCH_PRIORITIES_SUCCESS,
  PATCH_PRIORITIES_SUCCESS
} from './index';
import { initialRequestFailure, initialRequestInit, initialRequestSuccess } from './initActions';

export const addPrioritiesSuccess = (priority: Priority) => {
  return { type: ADD_PRIORITIES_SUCCESS, priority };
};

export const patchPrioritySuccess = (priority: Priority) => {
  return { type: PATCH_PRIORITIES_SUCCESS, priority };
};

export const loadPrioritiesSuccess = (priorities: Priority[]) => {
  return { type: FETCH_PRIORITIES_SUCCESS, payload: { priorities } };
};

export const loadPrioritiesFailure = (error: Error) => {
  return { type: FETCH_PRIORITIES_FAILURE, payload: { type: 'priorities', error: error } };
};

export const fetchPriorities = createAsyncThunk<void, void, { rejectValue: Error }>(
  'fetchPriorities',
  async (_, { dispatch }) => {
    dispatch(startAjaxCall({ name: 'FETCH_PRIORITIES' }));
    dispatch(initialRequestInit('priorities'));
    try {
      const priorities = await PriorityApi.getPriorities();
      dispatch(loadPrioritiesSuccess(priorities));
      dispatch(initialRequestSuccess('priorities'));
    } catch (error) {
      console.error('Failed to load channel types', error);
      if (isAxiosError(error)) {
        dispatch(loadPrioritiesFailure(error));
      }
      dispatch(initialRequestFailure('priorities', error));
    }
  }
);

export const addPriority = createAsyncThunk<void, { priority: Omit<Priority, 'id'> }, { rejectValue: Error }>(
  'addPriority',
  async ({ priority }, { dispatch }) => {
    dispatch(startAjaxCall({ name: 'ADD_PRIORITY' }));
    await PriorityApi.addPriority(priority);
  }
);

export const patchPriority = createAsyncThunk<
  void,
  { payload: { id: number; data: Priority } },
  { rejectValue: Error }
>('patchPriority', async ({ payload }, { dispatch }) => {
  dispatch(startAjaxCall({ name: 'PATCH_PRIORITY' }));
  await PriorityApi.patchPriority(payload);
});
