import { createAsyncThunk } from '@reduxjs/toolkit';
import { t } from 'i18next';
import iziToast from 'izitoast';
import { isArray } from 'lodash-es';

import type { Middleware } from '@reduxjs/toolkit';

import UserPreferencesApi from 'src/api/UserPreferencesApi';
import { setPreferences } from 'src/reducers/userPreferencesReducer';

export const fetchUserPreferences = createAsyncThunk<{ status: string }, undefined, { rejectValue: Error }>(
  'fetchUserPreferences',
  async (_, { dispatch }) => {
    try {
      const preferences = await UserPreferencesApi.getByUser();
      if (isArray(preferences)) {
        // If the response is an array, it means the user has preferences
        dispatch(setPreferences(preferences));
        return { status: 'success' };
      }
      return { status: 'failed' };
    } catch (error) {
      return { status: 'failed' };
    }
  }
);

/**
 * Middleware that updates the backend with new user preference values.
 *
 * It does nothing for any action except for the "userPreferences/setPreference" action.
 * For that action, it takes the key and value from the action payload and sends a PATCH request
 * to the backend to update the preference.
 */
const sideEffectSetUserPreferencesMiddleware: Middleware = () => (next) => (action) => {
  const result = next(action);

  if (action.type === 'userPreferences/setPreference') {
    const { key, value } = action.payload;
    UserPreferencesApi.setPreference({
      key,
      value
    }).catch((error) => {
      console.error('Network error while updating preference:', error);
      iziToast.error({
        message: t('settings.userPreferences.errorUpdate'),
        timeout: 3000
      });
    });
  }

  return result;
};

export default sideEffectSetUserPreferencesMiddleware;
