import { createSlice } from '@reduxjs/toolkit';

import type { CurrentUserStatus, UserStatus } from '@eeedo/types';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface UserStatusState {
  userStatuses: { [key: number]: UserStatus };
  currentUserStatuses: { [key: number]: CurrentUserStatus };
}

const initialState: UserStatusState = {
  userStatuses: {},
  currentUserStatuses: {}
};

const userStatusSlice = createSlice({
  name: 'userStatus',
  initialState,
  reducers: {
    fetchUserStatusesSuccess(state, action: PayloadAction<UserStatus[]>) {
      state.userStatuses = action.payload.reduce((acc, userStatus) => {
        acc[userStatus.id] = userStatus;
        return acc;
      }, {} as { [key: number]: UserStatus });
    },
    fetchCurrentUserStatusesSuccess(state, action: PayloadAction<CurrentUserStatus[]>) {
      state.currentUserStatuses = action.payload.reduce((acc, currentUserStatus) => {
        acc[currentUserStatus.UID] = currentUserStatus;
        return acc;
      }, {} as { [key: number]: CurrentUserStatus });
    },
    changeCurrentUserStatusSuccess(state, action: PayloadAction<CurrentUserStatus>) {
      state.currentUserStatuses[action.payload.UID] = action.payload;
    },
    updateUserStatusSuccess(state, action: PayloadAction<UserStatus>) {
      state.userStatuses[action.payload.id] = action.payload;
    },
    initUserStatuses() {}
  }
});

export const {
  fetchUserStatusesSuccess,
  fetchCurrentUserStatusesSuccess,
  changeCurrentUserStatusSuccess,
  updateUserStatusSuccess,
  initUserStatuses
} = userStatusSlice.actions;

export default userStatusSlice.reducer;
