import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Popup } from 'semantic-ui-react';

import type { Tag } from '@eeedo/types';
import type { FC } from 'react';

import AllTags from './AllTags';
import * as styles from './TicketListItem.style';
import Button from 'src/Components/generic/Button/Button';
import Label from 'src/Components/generic/Label/Label';

interface Props {
  tags: Tag[];
}

const lengthsMap = { 1: 'TagMaxLength', 2: 'TagSplitLength' };
const getTagElementClassName = (tagsLength: number) => lengthsMap[tagsLength] ?? 'TagMoreThanTwoLength';

const TicketTags: FC<Props> = ({ tags }) => {
  return (
    <>
      {tags.slice(0, 1).map((tag) => {
        const style = styles.metaLabel({
          bgColor: tag.styles?.bgColor ? (tag.styles.bgColor as string) : '#e8e8e8',
          fontColor: tag.styles?.fontColor ? (tag.styles.fontColor as string) : 'rgba(0,0,0,.6)'
        });

        return (
          <Popup
            content={<AllTags sortedTags={tags} />}
            hoverable={true}
            key={tag.id}
            on={'hover'}
            position="bottom right"
            trigger={
              <Label
                className={`ticketListTag ${getTagElementClassName(tags.length)}`}
                data-test={tag.name}
                style={style}
              >
                {tag.name}
              </Label>
            }
          />
        );
      })}
      {tags.length > 2 && (
        <Popup
          on={'hover'}
          hoverable={true}
          position="bottom right"
          content={<AllTags sortedTags={tags} />}
          trigger={
            <Button style={{ marginLeft: '2px' }} size="xs" icon={<FontAwesomeIcon icon={faEllipsis} />}>
              {/* <Icon fitted color="grey" name="ellipsis horizontal" /> */}
            </Button>
          }
        />
      )}
    </>
  );
};
export default TicketTags;
