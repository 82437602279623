import { orderBy } from 'lodash-es';
import React, { useMemo } from 'react';
import { Translation } from 'react-i18next';
import { Dropdown, Icon, Label } from 'semantic-ui-react';

import type { TicketType } from '@eeedo/types';
import type { FC } from 'react';
import type { DropdownItemProps } from 'semantic-ui-react';

import UserAvatar from '../User/UserAvatar';
import UserLabel from '../User/UserLabel';
import { useAppSelector } from 'src/store';
import { Roles } from 'src/types/User';

interface DelegatesProps {
  delegates: string[];
  ticketType: TicketType;
  showOnlyRoles?: number[];

  onAdd: (user: string) => void;
  onRemove: (user: string) => void;
  onGroupAdd: (group: string[]) => void;
}

const Delegates: FC<DelegatesProps> = ({ delegates = [], ticketType, showOnlyRoles, onAdd, onRemove, onGroupAdd }) => {
  const userData = useAppSelector((state) => state.userData);
  const usersList = useAppSelector((state) => state.usersList.usersList);
  const autosuggestions = useAppSelector((state) => state.suggestions.filter((s) => s.type === 'DelegationGroup'));
  const user = useAppSelector((state) => state.usersList.usersList.find((user) => user.UID === state.userData.UID));

  const handleAddDelegate = (usr: string) => onAdd(usr);
  const handleDeleteDelegate = (usr: string) => onRemove(usr);
  const handleGroupAddDelegate = (usrGroup: string[]) => onGroupAdd(usrGroup);

  const possibleDelegates = useMemo(() => {
    // Helper to check if a delegation group is already used
    const isDelegationGroupUsed = (valueAsString: string, delegates: string[]) => {
      if (delegates.length > 0) {
        const groupOfUsers = valueAsString.split(',');
        return groupOfUsers.every((usr) => delegates.includes(usr));
      }
      return false;
    };

    // Filter users based on the criteria
    const filteredUsers = usersList.filter((user) => {
      return (
        !delegates.includes(user.UID) &&
        user.profile.hideFromDelegation === 0 &&
        user.role.id !== 'ROL3' &&
        user.ticketTypes.includes(ticketType.id) &&
        (showOnlyRoles === undefined || showOnlyRoles.includes(Roles.idToNumber(user.role.id)))
      );
    });

    // Sort users to push the current user to the end (if needed)
    const sortedUsers = [...filteredUsers].sort((a) => (a.UID === user?.UID ? 1 : 0));

    const userOptions: DropdownItemProps = sortedUsers.map((user) => {
      return {
        text: `${user.profile.firstName} ${user.profile.lastName}`,
        value: user.UID,
        icon: <UserAvatar round size="24" UID={user.UID} showUserStatusType={true} />
      };
    });

    autosuggestions?.forEach((suggestion) => {
      if (
        suggestion.type === 'DelegationGroup' &&
        !isDelegationGroupUsed(suggestion.value, delegates) &&
        (suggestion.ticketTypes.includes(ticketType.id) || suggestion.ticketTypes.length === 0)
      ) {
        userOptions.push({
          icon: 'users',
          text: suggestion.name,
          value: `${suggestion.type}_${suggestion.name}`
        });
      }
    });

    return orderBy(userOptions, ['text', 'value']);
  }, [delegates, ticketType, showOnlyRoles, usersList, autosuggestions, user]);

  return (
    <Translation ns="translations">
      {(t) => (
        <React.Fragment>
          <Label.Group className="delegates">
            {delegates.map((usr, index) => (
              <UserLabel size="22" UID={usr} key={`delegate-user-label-${index}`}>
                <Icon name="delete" onClick={() => handleDeleteDelegate(usr)} size="small" link />
              </UserLabel>
            ))}
          </Label.Group>

          <Dropdown
            id="delegateDropdown"
            selection
            search
            fluid
            selectOnNavigation={false}
            selectOnBlur={false}
            disabled={!userData.permissions.includes('updateContent')}
            className="delegateDropdown"
            onChange={(_, data) => {
              if (data.value) {
                if (typeof data.value === 'string' && data.value.startsWith('DelegationGroup')) {
                  const splittedValue = data.value.split('_');
                  const userGroupName = splittedValue[1];
                  const userGroup = autosuggestions?.find((suggestion) => suggestion.name === userGroupName);
                  if (userGroup) {
                    const delArray = userGroup.value.split(',').map((value) => value);
                    handleGroupAddDelegate(delArray);
                  }
                } else {
                  handleAddDelegate(data.value.toString());
                }
              }
            }}
            placeholder={t('PLACEHOLDER_WRITE_TO_SEARCH')}
            options={orderBy(possibleDelegates, ['text', 'value'])}
          />
        </React.Fragment>
      )}
    </Translation>
  );
};

export default Delegates;
