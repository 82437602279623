import { convertPrefixStringToNumber } from '@eeedo/utils';
import { faChevronDown, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { difference } from 'lodash-es';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Channel, UserWithProfile } from '@eeedo/types';

import Button from '../generic/Button/Button';
import Dropdown from '../generic/Dropdown/Dropdown';
import ReadinessLabel from '../Management/UsersStatusManagement/ReadinessLabel/ReadinessLabel';
import UserNameCell from './Cells/UserNameCell';
import TimerRefresh from './TimerRefresh';
import Pagination from 'src/Components/generic/Pagination/Pagination';
import Table from 'src/Components/generic/Table/Table';
import { useAutoRefreshSelector } from 'src/hooks/useAutoRefreshSelector';
import { StaticTabs } from 'src/types/TicketList';
import { Roles } from 'src/types/User';

import type { HeaderItem, RowItem } from 'src/Components/generic/Table/Table';
import type { TicketListTicket } from 'src/types/Ticket';
import { useModal } from '../Modal/ModalContext';
import ChangeAvailabilityModal from '../UserStatus/ChangeAvailabilityModal';

const paginationSize = 10;

const PerChannelTable = () => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const [paginationPage, setPaginationPage] = useState<number>(1);
  const [filters, setFilters] = useState<{ ticketTypes?: number[] }>({ ticketTypes: undefined });
  const [channels, refreshChannels] = useAutoRefreshSelector((state) =>
    state.channels.filter((channel) => channel.active)
  );
  const [onlineStatuses, refreshOnlineStatuses] = useAutoRefreshSelector((state) => state.onlineStatus);
  const [tickets, refreshTickets] = useAutoRefreshSelector(
    (state) => state.ticketListTabs[StaticTabs.MAIN_VIEW].tickets
  );
  const [workStatuses, refreshWorkStatuses] = useAutoRefreshSelector((state) => state.workStatus.status);
  const [users, refreshUsers] = useAutoRefreshSelector((state) =>
    state.usersList.usersList.filter((user) => !Roles.isBlocked(user.role.id))
  );
  const [ticketTypes, refreshTicketTypes] = useAutoRefreshSelector((state) => state.ticketTypes);
  const [userStatuses, refreShuserStatuses] = useAutoRefreshSelector((state) => state.userStatusesState.userStatuses);
  const [currentUserStatuses, refreshCurrentUserStatuses] = useAutoRefreshSelector(
    (state) => state.userStatusesState.currentUserStatuses
  );
  const [channelsAvailability, refreshChannelsAvailability] = useAutoRefreshSelector(
    (state) => state.channelsAvailability
  );
  const [expandedRowId, setExpandedRowId] = useState<number | null>(null);

  const refreshData = useCallback(() => {
    refreshTickets();
    refreshChannels();
    refreshWorkStatuses();
    refreshOnlineStatuses();
    refreshTicketTypes();
    refreshUsers();
    refreShuserStatuses();
    refreshCurrentUserStatuses();
    refreshChannelsAvailability();
  }, []);

  const toggleRow = (id: number) => {
    setExpandedRowId(expandedRowId === id ? null : id);
  };

  const availableUsersByChannel = useMemo(() => {
    return channels.reduce((acc, channel) => {
      const filteredUsers = users
        .filter((user) => !!channelsAvailability[convertPrefixStringToNumber(user.UID)]?.[channel.id])
        .filter((user) => {
          if (filters.ticketTypes?.length && difference(filters.ticketTypes, user.ticketTypes).length > 0) {
            return false;
          }
          return true;
        });
      acc[channel.id] = filteredUsers.map((user) => {
        const UID = convertPrefixStringToNumber(user.UID);
        const isStatusReady = userStatuses[currentUserStatuses[UID].originalStatus].type === 'ready';
        const isConnected = onlineStatuses[UID] === 'online';
        const isOnline = isStatusReady && isConnected;

        return { user, isOnline };
      });
      return acc;
    }, {} as Record<number, { user: UserWithProfile; isOnline: boolean }[]>);
  }, [channels, users, channelsAvailability, filters]);

  const ticketsByChannel = useMemo(() => {
    return tickets.reduce((acc, ticket) => {
      const currentTickets = acc[ticket.channel] || [];
      return {
        ...acc,
        [ticket.channel]: [...currentTickets, ticket]
      };
    }, {} as Record<number, TicketListTicket[]>);
  }, [tickets]);

  const workingOnTicketsByChannel = useMemo(() => {
    return tickets.reduce((acc, ticket) => {
      const currentTickets = acc[ticket.channel] || [];
      if (workStatuses.find((workStatus) => parseInt(workStatus.ticketId) === convertPrefixStringToNumber(ticket.id))) {
        return {
          ...acc,
          [ticket.channel]: [...currentTickets, ticket]
        };
      }

      return acc;
    }, {} as Record<number, TicketListTicket[]>);
  }, [tickets, workStatuses]);

  const getNestedRow = (channel: Channel, availableUsers: { user: UserWithProfile; isOnline: boolean }[]) => {
    const nestedHeaders: HeaderItem[] = [
      { key: 'user', content: t('USER'), headerCellProps: { width: 3 } },
      { key: 'status', content: t('userStatusManagement.labels.user_status'), headerCellProps: { width: 13 } }
    ];

    const nestedData = availableUsers.map(({ user }) => {
      const UID = convertPrefixStringToNumber(user.UID);
      const currentUserStatus = currentUserStatuses[UID];
      const userStatus = userStatuses[currentUserStatus.originalStatus];

      return {
        key: `nested-${UID}`,
        cells: [
          UserNameCell(user),
          {
            content: (
              <ReadinessLabel
                type={userStatus.type === 'ready' ? 'active' : 'inactive'}
                text={currentUserStatus.description || userStatus.text}
                onClick={() => openModal(<ChangeAvailabilityModal UID={UID} />, 'small')}
              />
            )
          }
        ]
      };
    }) as RowItem[];

    const nestedTable = <Table headers={nestedHeaders} data={nestedData} rootTableProps={{ celled: true }} />;

    return {
      key: `expanded-${channel.id}`,
      cells: [
        {
          content: nestedTable,
          cellProps: { colSpan: 16 }
        }
      ],
      rowProps: { className: 'accordionRow' }
    };
  };

  const renderChannels = useCallback(() => {
    const rows: RowItem[] = [];

    channels.slice((paginationPage - 1) * paginationSize, paginationPage * paginationSize).forEach((channel) => {
      const availableUsers = availableUsersByChannel[channel.id] || [];
      const onlineUsers = availableUsers.filter((user) => user.isOnline);
      const todoTicketsAmount = ticketsByChannel[channel.id]?.filter((ticket) => ticket.status === 'todo').length || 0;
      const workingOnTicketsAmount = workingOnTicketsByChannel[channel.id]?.length || 0;

      const mainRow: RowItem = {
        key: `row-${channel.id}`,
        cells: [
          {
            content: availableUsers.length ? (
              <Button className="chevronButton" onClick={() => toggleRow(channel.id)}>
                <FontAwesomeIcon icon={expandedRowId === channel.id ? faChevronDown : faChevronRight} />
              </Button>
            ) : null
          },
          {
            content: <>{channel.channel}</>
          },
          {
            content: <>{availableUsers.length}</>
          },
          {
            content: <>{onlineUsers.length}</>
          },
          {
            content: <>{todoTicketsAmount}</>
          },
          {
            content: <>{workingOnTicketsAmount}</>
          }
        ]
      };

      rows.push(mainRow);

      if (expandedRowId === channel.id) {
        rows.push(getNestedRow(channel, availableUsers));
      }
    });

    return rows;
  }, [
    channels,
    users,
    userStatuses,
    currentUserStatuses,
    channelsAvailability,
    expandedRowId,
    filters,
    paginationPage
  ]);

  return (
    <>
      <div className="availabilityFilters">
        <Dropdown
          selection
          search={true}
          multiple={true}
          clearable={true}
          placeholder={t('TICKET_TYPES')}
          onChange={(_, data) => setFilters((prevValue) => ({ ...prevValue, ticketTypes: data.value as number[] }))}
          options={ticketTypes.map((ticketType) => ({ text: ticketType.name, value: ticketType.id }))}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', clear: 'both' }}>
        <TimerRefresh refreshData={refreshData} />

        <Table
          headers={[
            { key: 'actions', content: t('GENERAL_ACTIONS'), headerCellProps: { width: 1 } },
            { key: 'channel', content: t('CASE_CHANNEL'), headerCellProps: { width: 7 } },
            { key: 'users', content: t('availability.table.users_amount'), headerCellProps: { width: 2 } },
            { key: 'usersOnline', content: t('availability.labels.users_online'), headerCellProps: { width: 2 } },
            { key: 'todo', content: t('availability.labels.todo_tickets'), headerCellProps: { width: 2 } },
            { key: 'doing', content: t('availability.labels.currently_working_on'), headerCellProps: { width: 2 } }
          ]}
          stickyHeader={true}
          data={renderChannels()}
          rootTableProps={{ celled: true, striped: true }}
        />

        <div style={{ margin: '10px auto 0' }}>
          <Pagination
            itemsLength={channels.length}
            activePage={paginationPage}
            onPageChange={(activePage) => setPaginationPage(activePage)}
          />
        </div>
      </div>
    </>
  );
};

export default PerChannelTable;
