import { DefaultCommentsSorting, DefaultTicketsOrdering } from '@eeedo/types';

import type { LOCATION_CHANGE } from 'connected-react-router';
import type { Dispatch, Middleware, MiddlewareAPI } from 'redux';

import { ACTIVATE_TAB } from 'src/actions';
import { activateTicketlistTab } from 'src/actions/CaseListActions';
import { clearInfopagelistFilter, setInfopagelistFilter } from 'src/actions/infoPagesActionsRTK';
import { clearTicketlistFilter, setTicketlistFilter, setTicketlistSorting } from 'src/actions/ticketListTabActionsRTK';
import { setCommentsSorting } from 'src/reducers/commentsMenuReducer';
import { selectActiveTicketListTab } from 'src/selectors/rootStateSelectors';
import { navigateToContent } from 'src/Utilities/history';
import { addParamsToURL } from 'src/Utilities/url';

import type { State } from 'src/types/initialState';
import type { UrlParamsMap } from 'src/types/Url';

const [defaultDirection, defaultSorting] = DefaultTicketsOrdering;
const paramSpecificActions = [
  clearTicketlistFilter.type,
  setTicketlistFilter.type,
  setTicketlistSorting.type,
  setInfopagelistFilter.type,
  clearInfopagelistFilter.type,
  setCommentsSorting.type,
  activateTicketlistTab.type,
  '@@router/LOCATION_CHANGE' as typeof LOCATION_CHANGE
];

export const urlSyncMiddleware: Middleware = (api: MiddlewareAPI<Dispatch, State>) => (next) => (action) => {
  const returnValue = next(action);

  try {
    if (ACTIVATE_TAB === action.type) {
      // TODO: works but is hacky, the correct way is to use Link component from react router in TicketlistItem and Tabs
      navigateToContent(action.payload as string);
    }

    if (paramSpecificActions.includes(action.type)) {
      const state = api.getState();
      const { commentsMenu } = state;
      const activeTab = selectActiveTicketListTab(state);

      if (activeTab) {
        const params: UrlParamsMap = { ...activeTab.filters };

        const commentsSorting = commentsMenu.sorting;
        if (commentsSorting !== DefaultCommentsSorting) {
          params.comments = commentsSorting;
        }
        if (activeTab.sorting !== defaultSorting) {
          params.sorting = activeTab.sorting;
        }
        if (activeTab.direction !== defaultDirection) {
          params.direction = activeTab.direction;
        }

        addParamsToURL(params, true);
      }
    }
  } catch (error) {
    // not sure if worth to log something
  }

  return returnValue;
};
