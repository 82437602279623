import { createAsyncThunk } from '@reduxjs/toolkit';

import type { BatchContentUpdate } from '@eeedo/types';

import { openTicketTab } from './ticketsActions';
import TicketsApi from 'src/api/TicketsApi';
import { resetSelectedTickets, setInProgress } from 'src/reducers/singleLineViewReducer';

import type { State } from 'src/types/initialState';
import type { ContentTypesFields, Ticket } from 'src/types/Ticket';

export const updateSelectedTasks = createAsyncThunk<void, Partial<Ticket>, { state: State }>(
  'singleLineView/updateSelectedTasks',
  async (update, { dispatch, getState }) => {
    const selected = getState().singleLineView.selected;

    const updates: BatchContentUpdate[] = [];
    try {
      for (const id in selected) {
        if (selected[id]) {
          updates.push({ ...update, id });
          dispatch(setInProgress({ id, inProgress: true }));
        }
      }

      if (update.tags) {
        await TicketsApi.attachTicketsToContents(update.tags, Object.keys(selected));
      } else if (update.delegatedTo) {
        await TicketsApi.addDelegationsToContents(update.delegatedTo, Object.keys(selected));
      } else {
        await TicketsApi.batchTicketsUpdate(updates);
      }

      for (const item of updates) {
        dispatch(setInProgress({ id: item.id, inProgress: false }));
      }

      dispatch(resetSelectedTickets());
    } catch (error) {
      console.error('Failed to batch update tickets', updates);
    }
  }
);

export const openSelectedTasks = createAsyncThunk<void, { contentType: ContentTypesFields }, { state: State }>(
  'singleLineView/openSelectedTasks',
  async ({ contentType }, { dispatch, getState }) => {
    const { selected } = getState().singleLineView;
    for (const id in selected) {
      dispatch(openTicketTab({ contentId: id, contentType, shouldActivateTicket: false }));
    }
    dispatch(resetSelectedTickets());
  }
);
