import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { fetchInfoPages } from 'src/actions/infoPagesActions';
import { fetchTickets } from 'src/actions/ticketsActions';
import { selectActiveTicketListTab, selectIsInfopage } from 'src/selectors/rootStateSelectors';
import { useAppSelector } from 'src/store';
import { formatSearch } from 'src/Utilities/search';

type UseRefreshTicketList = () => [boolean, boolean, () => void];

const useRefreshTicketList: UseRefreshTicketList = () => {
  const dispatch = useDispatch();
  const [refreshLock, setRefreshLock] = useState(false);
  const [refreshTimeoutId, setRefreshTimeoutId] = useState<NodeJS.Timeout | undefined>();
  const ticketTab = useAppSelector(selectActiveTicketListTab);
  const isInfopage = useAppSelector(selectIsInfopage);
  const userData = useAppSelector((state) => state.userData);
  const loading = useAppSelector((state) =>
    state.ajaxCallsInProgress.some((ajaxCall) => ['FETCH_TICKETS'].includes(ajaxCall.name))
  );
  const { searchCriteria } = ticketTab ?? {};
  const searchParams = searchCriteria ? formatSearch(searchCriteria, userData.UID) : undefined;

  const onRefresh = () => {
    if (refreshLock) return;
    setRefreshLock(true);

    const id = setTimeout(() => setRefreshLock(false), 5000);
    setRefreshTimeoutId(id);

    if (ticketTab) {
      const throughSearchTab = false;
      isInfopage
        ? dispatch(fetchInfoPages({ searchParams, id: ticketTab.id, throughSearchTab }))
        : dispatch(fetchTickets(searchParams, ticketTab.id, throughSearchTab));
    }
  };

  useEffect(() => () => clearTimeout(refreshTimeoutId), []);

  return [refreshLock, loading, onRefresh];
};

export default useRefreshTicketList;
