import { UserStatusType } from '@eeedo/types';

import UserTimeLogsApi from './UserTimeLogsApi';
import { consoleLogUserTimeLogs, getCurrentSeconds } from './utils';

import type { UserTimeLogsRow } from './types';

const AUTOSAVE_INTERVAL = 1000 * 60 * 5; // 5 minutes
const MAX_TRACKABLE_DURATION = 1000 * 60 * 15; // 15 minutes

class UserTimeLogs {
  private startedAt: number | null = null;
  private activeTicketId: string | null = null;
  public unSentLogs: UserTimeLogsRow[] = [];
  private autoSaveInterval: NodeJS.Timeout | null = null;
  private userStatusType: UserStatusType = UserStatusType.NOTREADY;

  init() {
    this.initializeAutoSave();
  }

  public getActiveTicketId() {
    return this.activeTicketId;
  }

  public setActiveTicketId(ticketId: string): void {
    if (ticketId !== this.activeTicketId) {
      this.finishCurrentLogSession();
      this.activeTicketId = ticketId;
      this.startedAt = getCurrentSeconds();
    }
  }

  public getUserStatusType() {
    return this.userStatusType;
  }

  public setUserStatusType(userStatusType: UserStatusType) {
    if (this.userStatusType === 'ready' && userStatusType === 'notready') {
      if (this.activeTicketId && this.startedAt) {
        const duration = this.getDuration();
        this.unSentLogs.push({ id: this.activeTicketId!, duration });
      }
      this.sendLogs();
      this.startedAt = null;

      if (this.autoSaveInterval) {
        clearInterval(this.autoSaveInterval);
      }
    }

    if (this.userStatusType === 'notready' && userStatusType === 'ready') {
      this.initializeAutoSave();
    }

    this.userStatusType = userStatusType;
  }

  public finishCurrentLogSession(): void {
    if (this.isAlreadyCounting()) {
      const duration = this.getDuration();
      this.unSentLogs.push({ id: this.activeTicketId!, duration });
      this.sendLogs();
    }
    // Reset session
    this.startedAt = null;
    this.activeTicketId = null;
  }

  public async sendLogs(): Promise<void> {
    if (this.unSentLogs.length > 0) {
      consoleLogUserTimeLogs('[userTimeLogs]: Sending unsent logs.', this.unSentLogs);
      try {
        await UserTimeLogsApi.sendUserTimeLogs(this.unSentLogs.filter(Boolean));
        this.unSentLogs = [];
      } catch (error) {
        consoleLogUserTimeLogs('[userTimeLogs]: Error sending logs', error);
      }
    }
  }

  private initializeAutoSave(): void {
    this.autoSaveInterval = setInterval(() => {
      this.autoSaveLogs();
    }, AUTOSAVE_INTERVAL);
  }

  private autoSaveLogs(): void {
    if (this.isAlreadyCounting()) {
      // Add current session to unsent logs but do not reset session
      const duration = this.getDuration();
      this.unSentLogs.push({ id: this.activeTicketId!, duration });
      // Attempt to send all logs
      this.sendLogs();
      // Reset the timer without changing the activeTicketId
      this.startedAt = getCurrentSeconds();
    }
  }

  private getDuration() {
    return Math.min(getCurrentSeconds() - this.startedAt!, MAX_TRACKABLE_DURATION);
  }

  private isUserReady() {
    return this.userStatusType === 'ready';
  }

  private isAlreadyCounting() {
    return this.activeTicketId && this.startedAt && this.isUserReady();
  }

  public dispose(): void {
    if (this.autoSaveInterval) {
      clearInterval(this.autoSaveInterval);
    }
    // Ensure current session is logged before disposing
    this.finishCurrentLogSession();
  }
}

export default new UserTimeLogs();
