// low level client for version api
import { consoleLogUserTimeLogs } from './utils';
import { apiClient } from 'src/Utilities/httpClients';

import type { UserTimeLogsRow } from './types';

class UserTimeLogsApi {
  public sendUserTimeLogs = async (entries: UserTimeLogsRow[]) => {
    const url = '/recordActiveUserTime';
    const data = entries.map((row) => ({
      id: parseInt(row.id.substring(3)),
      duration: row.duration
    }));
    const res = await apiClient.post(url, data);
    consoleLogUserTimeLogs('[userTimeLogs]: Report sent:', res.status, res.statusText);
  };
}

export default new UserTimeLogsApi();
