import { UserStatusType } from '@eeedo/types';
import classNames from 'classnames';
import React from 'react';

import type { UserStatus } from '@eeedo/types';
import type { FC } from 'react';

import './UserStatusListItem.css';

interface UserStatusListItemProps {
  statusType: UserStatus['type'];
  text: UserStatus['text'];
  active: boolean;

  onClick: () => void;
}

const UserStatusListItem: FC<UserStatusListItemProps> = ({ statusType, text, active, onClick }) => {
  const statusTypeClass = classNames('statusType', {
    ready: statusType === UserStatusType.READY,
    notReady: statusType === UserStatusType.NOTREADY
  });

  const wrapperClass = classNames('userStatusListItem', { active: !!active });

  return (
    <div className={wrapperClass} onClick={onClick}>
      <span className={statusTypeClass} />
      <p>{text}</p>
    </div>
  );
};

export default UserStatusListItem;
