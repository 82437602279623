export type AiFunction = {
  id: number;
  name: string;
  prompt: string;
  type: AiFunctionTypes;
  subtype?: string;
  actions: AiFunctionAction[];
  settings?: any; // TODO: type
  createdAt?: Date;
  createdBy: number;
  updatedAt?: Date;
  updatedBy?: number;
  ticketTypes: number[];
};

export interface AiFunctionAction<T = unknown> {
  type: AiFunctionActionTypes;
  data: T;
}

// where should show functions
export enum AiFunctionTypes {
  AiMenu = 'AiMenu',
  AiEditor = 'AiEditor'
}

export const aiFunctionActionTypeValues = {
  'Update details with result': 'CONTENT_DETAIL_UPSERT'
} as const;

export type AiFunctionActionTypes = (typeof aiFunctionActionTypeValues)[keyof typeof aiFunctionActionTypeValues];

export type AiFunctionBasic = Omit<AiFunction, 'createdAt' | 'createdBy' | 'updatedAt' | 'updatedBy'>;
