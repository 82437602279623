import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from 'semantic-ui-react';

import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import type { FC, SyntheticEvent } from 'react';
import type { PopupProps } from 'semantic-ui-react';

import styles from './TopBarLabelList.module.css';
import { DATE_TIME_FORMAT, getPrettyDate } from 'src/Utilities/dates';

const popupDefaultProps: PopupProps = {
  flowing: true,
  hoverable: true,
  position: 'bottom center',
  wide: true
};

interface TopBarDateItemProps {
  value: number;
  translationKey: string;
  icon: IconProp;

  onClick?: (e: SyntheticEvent) => void;
}

const getFormattedDate = (date: number | string) => getPrettyDate(date, { format: DATE_TIME_FORMAT });

const TopBarDateItem: FC<TopBarDateItemProps> = ({ value: rawValue, translationKey, icon, onClick }) => {
  const { t } = useTranslation();
  const value = getFormattedDate(rawValue);

  return (
    <div className={styles.topBarDatesItem}>
      <Popup
        {...popupDefaultProps}
        content={
          <div>
            <b>{value}</b>
            <br />
            {t(translationKey)}
          </div>
        }
        trigger={
          <div className={styles.topBarDatesTrigger} onClick={onClick}>
            <FontAwesomeIcon className={styles.topBarDatesItemIcon} icon={icon} />
            <span>{value}</span>
          </div>
        }
      />
    </div>
  );
};

export default React.memo(TopBarDateItem);
