import { FilterPresetView } from '@eeedo/types';
import { orderBy } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import type { FilterPresetExtended } from '@eeedo/types';

import { activateTicketlistTab } from 'src/actions/CaseListActions';
import { setActiveFilterPreset } from 'src/actions/filterPresetsActions';
import { clearInfopagelistFilter, setInfopagelistFilter } from 'src/actions/infoPagesActionsRTK';
import { clearTicketlistFilter, setTicketlistFilter, setTicketlistSorting } from 'src/actions/ticketListTabActionsRTK';
import { selectActiveTicketListTab, selectIsInfopage } from 'src/selectors/rootStateSelectors';
import { useAppSelector } from 'src/store';
import { StaticTabs } from 'src/types/TicketList';
import { filterTicketList } from 'src/Utilities/ticketList';

type UseFilterPresets = (
  clearFilters?: () => void
) => [FilterPresetExtended[], number | null | undefined, (id: number) => void];

const useFilterPresets: UseFilterPresets = (clearFilters) => {
  const dispatch = useDispatch();
  const activeTicketListTab = useAppSelector(selectActiveTicketListTab);
  const categories = useAppSelector((state) => state.categories);
  const isInfopage = useAppSelector(selectIsInfopage);
  const userData = useAppSelector((state) => state.userData);
  const usersList = useAppSelector((state) => state.usersList.usersList);
  const { filterPresets, activeId } = useAppSelector((state) => state.filterPresets);
  const { defaultFilterPreset } = userData.userPreferences;

  const contents = useAppSelector((state) =>
    isInfopage
      ? state.infoPageListTabs[StaticTabs.MAIN_VIEW]?.tickets
      : state.ticketListTabs[StaticTabs.MAIN_VIEW]?.tickets
  );

  const currentView = isInfopage ? FilterPresetView.infopage : FilterPresetView.main;
  const filterPresetsExtended: FilterPresetExtended[] = useMemo(() => {
    return filterPresets
      .filter(
        (filterPreset) =>
          filterPreset.ticketTypes.length === 0 ||
          filterPreset.ticketTypes.some((filterSetTicketType) => userData.ticketTypes.includes(filterSetTicketType))
      )
      .map((filterPreset) => {
        const counts = filterTicketList({
          categories,
          tickets: contents ?? [],
          personalData: userData,
          filters: filterPreset.filters,
          usersList
        }).reduce<FilterPresetExtended['counts']>((acc, { status }) => {
          if (status) {
            acc[status] = (acc[status] ?? 0) + 1;
          }
          return acc;
        }, {});

        return {
          ...filterPreset,
          isDefault: filterPreset.id === defaultFilterPreset,
          isSelected: filterPreset.id === activeId && activeTicketListTab?.id === StaticTabs.MAIN_VIEW,
          counts
        };
      });
  }, [categories, contents, userData, filterPresets, usersList, defaultFilterPreset, activeId, activeTicketListTab]);

  const sortedFilterPresets = useMemo(
    () => orderBy(filterPresetsExtended, ['isDefault', 'isFavorite', 'name'], ['desc', 'desc', 'asc']),
    [filterPresetsExtended]
  );

  const applyFilter = useCallback(
    (id: number) => {
      const filter = sortedFilterPresets.find((f) => f.id === id);
      if (!filter) {
        throw new Error('Something went wrong');
      }

      const setFilter = currentView === FilterPresetView.infopage ? setInfopagelistFilter : setTicketlistFilter;
      const setSorting = currentView === FilterPresetView.infopage ? null : setTicketlistSorting;

      dispatch(setActiveFilterPreset({ filterPresetId: filter.id }));

      if (clearFilters) {
        clearFilters();
      } else {
        dispatch(
          (currentView === FilterPresetView.infopage ? clearInfopagelistFilter : clearTicketlistFilter)(
            StaticTabs.MAIN_VIEW
          )
        );
      }

      if (filter.isSelected) {
        dispatch(setActiveFilterPreset({ filterPresetId: null }));
        return;
      }

      dispatch(setActiveFilterPreset({ filterPresetId: filter.id }));

      Object.keys(filter?.filters).forEach((filterKey) => {
        dispatch(
          setFilter({
            id: StaticTabs.MAIN_VIEW,
            value: filter?.filters[filterKey],
            parameter: filterKey as any
          })
        );
      });

      if (setSorting) {
        dispatch(setSorting(filter.sorting, filter.direction));
      }
      dispatch(activateTicketlistTab(StaticTabs.MAIN_VIEW));
    },
    [dispatch, sortedFilterPresets, StaticTabs.MAIN_VIEW, clearFilters]
  );

  return [sortedFilterPresets, defaultFilterPreset, applyFilter];
};

export default useFilterPresets;
